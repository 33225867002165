import { isOutOfBoundsInt, isTooLongStringInput, isValidUrl } from '../../../../../../common/validations';
import isEmptyString from '../../../../../../common/is-empty-string';
import { DetailsFormProblem } from '../../SubmitGame/submission-errors';
import { buildEmptyGameCovers, GAME_COVERS_RESOLUTIONS, GameCoversWithUploadedFile } from '../../../../../../common/graphql/games/game';

export interface DetailsFormData {
  category: string | null;
  tags: string[];
  description: string | null;
  controls: string | null;
  playUrl: string | null;
  appstoreUrl: string | null;
  steamUrl: string | null;
  playStoreDownloads: string | null; // will be converted to number before sending to server
  appStoreDownloads: string | null; // will be converted to number before sending to server
  steamDownloads: string | null; // will be converted to number before sending to server
  gameCovers: GameCoversWithUploadedFile;
}

export const MAX_DESCRIPTION_LENGTH = 6000;
export const MAX_CONTROLS_LENGTH = 3500;

export function buildDefaultDetailsFormData(): DetailsFormData {
  return {
    category: null,
    tags: [],
    description: null,
    controls: null,
    playUrl: null,
    appstoreUrl: null,
    steamUrl: null,
    playStoreDownloads: null,
    appStoreDownloads: null,
    steamDownloads: null,
    gameCovers: buildEmptyGameCovers(),
  };
}

export function validateDetailsForm(form: DetailsFormData): DetailsFormProblem[] {
  const problems: DetailsFormProblem[] = [
    ...validateCategory(form.category),
    ...validateTags(form.tags),
    ...validateDescription(form.description),
    ...validateControls(form.controls),
    ...validatePlayUrl(form.playUrl),
    ...validateSteamUrl(form.steamUrl),
    ...validateAppstoreUrl(form.appstoreUrl),
    ...validatePlayStoreDownloads(form.playStoreDownloads),
    ...validateAppStoreDownloads(form.appStoreDownloads),
    ...validateSteamDownloads(form.steamDownloads),
    ...validateCoverImages(form.gameCovers),
  ];
  return problems;
}

export function validateCategory(category: string | null): DetailsFormProblem[] {
  if (isEmptyString(category)) {
    return ['CATEGORY_MISSING'];
  }
  return [];
}

export function validateTags(tags: string[]): DetailsFormProblem[] {
  if (!tags || tags.length < 1) {
    return ['TAG_MISSING'];
  }
  return [];
}

export function validateDescription(description: string | null): DetailsFormProblem[] {
  if (isEmptyString(description)) {
    return ['DESCRIPTION_MISSING'];
  }
  if (description!.length > MAX_DESCRIPTION_LENGTH) {
    return ['DESCRIPTION_TOO_LONG'];
  }
  return [];
}

export function validateControls(controls: string | null): DetailsFormProblem[] {
  if (isEmptyString(controls)) {
    return ['CONTROLS_MISSING'];
  }
  if (controls!.length > MAX_CONTROLS_LENGTH) {
    return ['CONTROLS_TOO_LONG'];
  }
  return [];
}

export function validatePlayUrl(playUrl: string | null): DetailsFormProblem[] {
  if (!isEmptyString(playUrl) && (isTooLongStringInput(playUrl!) || !isValidUrl(playUrl as string))) {
    return ['PLAY_URL_INVALID'];
  }
  return [];
}

export function validateAppstoreUrl(appstoreUrl: string | null): DetailsFormProblem[] {
  if (!isEmptyString(appstoreUrl) && (isTooLongStringInput(appstoreUrl!) || !isValidUrl(appstoreUrl as string))) {
    return ['APPSTORE_URL_INVALID'];
  }
  return [];
}

export function validateAppStoreDownloads(downloads: string | null): DetailsFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['APP_STORE_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validatePlayStoreDownloads(downloads: string | null): DetailsFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['PLAY_STORE_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validateSteamDownloads(downloads: string | null): DetailsFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['STEAM_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validateSteamUrl(steamUrl: string | null): DetailsFormProblem[] {
  if (!isEmptyString(steamUrl) && (isTooLongStringInput(steamUrl!) || !isValidUrl(steamUrl as string))) {
    return ['STEAM_URL_INVALID'];
  }
  return [];
}

function validateCoverImages(coverImages: GameCoversWithUploadedFile | null): DetailsFormProblem[] {
  const problems: DetailsFormProblem[] = [];
  if (coverImages === null) {
    problems.push('COVER_IMAGES_MISSING');
  } else {
    for (const coverId in coverImages) {
      if (!coverImages[coverId as GAME_COVERS_RESOLUTIONS]) {
        problems.push(`COVER_IMAGE_MISSING${coverId}` as DetailsFormProblem);
      }
    }
  }
  return problems;
}

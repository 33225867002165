import React from 'react';
import { SDKFeatures, SDKYesNoFeatures } from './components/Requirements/SDKRequirements';
import { SDKInfo } from './QAEventListener';
import { RequirementState } from './components/Requirements/Requirement';
import { YesNoRequirementState } from './components/Requirements/YesNoRequirement';

interface QAToolProviderData {
  sdkRequirements: Record<SDKFeatures, RequirementState>;
  sdkYesNoRequirements: Record<SDKYesNoFeatures, YesNoRequirementState>;
  loading: RequirementState;
  displaysCorrectly: YesNoRequirementState;
  SDKInfo?: SDKInfo;
  updateNote: string;
  adScenario: string;
}

type ExtendSetState = <K extends keyof QAToolState>(
  state: ((prevState: Readonly<QAToolState>) => Pick<QAToolState, K>) | Pick<QAToolState, K>,
) => void;

export interface QAToolContextData extends QAToolProviderData {
  setData: ExtendSetState;
  gameIframe: Window | undefined | null;
}

const defaultStateValues: QAToolContextData = {
  sdkRequirements: { sdkInit: 'pending' },
  sdkYesNoRequirements: {
    happytime: 'pending',
    gameplayStart: 'pending',
    gameplayStop: 'pending',
    sdkGameLoadingStart: 'pending',
    sdkGameLoadingStop: 'pending',
    rewardedAd: 'pending',
    midgameAd: 'pending',
    inviteLink: 'pending',
    banner: 'pending',
    useDataModule: 'pending',
    requestUserToken: 'pending',
    requestXsollaUserToken: 'pending',
    useUserModule: 'pending',
  },
  loading: 'pending',
  displaysCorrectly: 'triggered',
  updateNote: '',
  adScenario: '',
  gameIframe: undefined,
  setData: () => {},
};

export const QAToolContext = React.createContext<QAToolContextData>(defaultStateValues);

interface QAToolProviderProps {
  children?: React.ReactNode;
}

interface QAToolState extends QAToolContextData {}

class QAToolProvider extends React.Component<QAToolProviderProps, QAToolState> {
  constructor(props: QAToolProviderProps) {
    super(props);
    this.state = {
      ...defaultStateValues,
      setData: this.setData,
    };
  }

  render() {
    return <QAToolContext.Provider value={this.state}>{this.props.children}</QAToolContext.Provider>;
  }

  private setData: ExtendSetState = (data) => {
    this.setState(data);
  };
}

export default QAToolProvider;

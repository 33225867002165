import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';
import { GET_MY_SUBMISSION_QUERY } from '../submission-graphql';

const RELEASE_SUBMISSION = gql`
  mutation releaseSubmission($input: DeveloperSubmissionReleaseInput!) {
    developerSubmissionRelease(input: $input) {
      id
    }
  }
`;

export interface ReleaseSubmissionMutation {
  developerSubmissionRelease: ReleaseSubmissionResult;
}

export interface ReleaseSubmissionResult {}

export interface DeveloperSubmissionReleaseInput {
  id: string;
}

export function releaseSubmission(input: DeveloperSubmissionReleaseInput) {
  return mutate<DeveloperSubmissionReleaseInput, ReleaseSubmissionMutation>(RELEASE_SUBMISSION, input, {
    refetchQueries: [{ query: GET_MY_SUBMISSION_QUERY, variables: { id: input.id } }],
  });
}

import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../../../../../common/Styleguide/Common/colors';

export const StyledFakeUserSwitch = styled(Switch)(() => ({
  '.Mui-disabled .MuiSwitch-thumb': {
    opacity: 0.3,
    backgroundColor: COLORS.brand[100],
  },
  '.Mui-checked .MuiSwitch-thumb': {
    backgroundColor: COLORS.brand[100],
  },
  '.Mui-checked + .MuiSwitch-track': {
    opacity: '0.7 !important',
    backgroundColor: `${COLORS.brand[100]} !important`,
  },
}));

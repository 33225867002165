import {
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
} from '@mui/material';
import React from 'react';
import { StyledButton } from '../../../../../../../common/Styleguide/Common/Button';
import { COLORS } from '../../../../../../../common/Styleguide/Common/colors';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { DOCS_URL } from '../../../../../SideMenu/Menu';
import { PreviewSubmission } from '../../../../Preview/preview-graphql';

interface SDKNotImplementedPopupProps {
  submission: PreviewSubmission;
  next: () => void;
  back: () => void;
}

const SDKNotImplementedPopup: React.FC<SDKNotImplementedPopupProps> = ({ back, next }) => {
  const [checked, setChecked] = React.useState(false);
  return (
    <Dialog open onClose={back}>
      <DialogTitle id="alert-dialog-slide-title">SDK Features</DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2, mt: 0 }}>
          No SDK feature has been detected (
          <a href={DOCS_URL} target="_blank" rel="noopener noreferrer">
            more info here
          </a>
          ). Do you confirm that you did not implement the SDK?
        </StyledBodyText>
      </DialogContent>

      <DialogActions sx={{ flexDirection: 'column', pt: 2, pb: 3 }}>
        <FormControl fullWidth sx={{ alignItems: 'center', mb: 2 }}>
          <FormControlLabel
            sx={{ [`& .${formControlLabelClasses.label}`]: { color: COLORS.white[60] } }}
            control={<Checkbox checked={checked} onChange={(_evt: {}, checked: boolean) => setChecked(checked)} />}
            label="I did not implement the SDK"
          />
        </FormControl>

        <div style={{ display: 'flex' }}>
          <StyledButton onClick={back} variant="contained" color="purple" height={50} sx={{ minWidth: 208, mr: 2 }}>
            Back to preview
          </StyledButton>
          <StyledButton onClick={next} variant="contained" color="white" height={50} style={{ minWidth: 208 }} disabled={!checked}>
            Continue anyway
          </StyledButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SDKNotImplementedPopup;

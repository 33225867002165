import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const SDK: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.825 12.8801C54.9958 13.6119 55.3518 15.1542 54.62 16.3251L46.0731 30.0001L54.62 43.6751C55.3518 44.8459 54.9958 46.3883 53.825 47.1201C52.6542 47.8519 51.1118 47.4959 50.38 46.3251L45.625 38.7171V45.0001C45.625 46.3808 44.5057 47.5001 43.125 47.5001C41.7443 47.5001 40.625 46.3808 40.625 45.0001V15.0001C40.625 13.6194 41.7443 12.5001 43.125 12.5001C44.5057 12.5001 45.625 13.6194 45.625 15.0001V21.2831L50.38 13.6751C51.1118 12.5043 52.6542 12.1483 53.825 12.8801ZM5 20.0001C5 15.858 8.35786 12.5001 12.5 12.5001H16.875C18.2557 12.5001 19.375 13.6194 19.375 15.0001C19.375 16.3808 18.2557 17.5001 16.875 17.5001H12.5C11.1193 17.5001 10 18.6194 10 20.0001V25.3126C10 26.5207 10.9794 27.5001 12.1875 27.5001C16.157 27.5001 19.375 30.718 19.375 34.6876V40.0001C19.375 44.1422 16.0171 47.5001 11.875 47.5001H7.5C6.11929 47.5001 5 46.3808 5 45.0001C5 43.6194 6.11929 42.5001 7.5 42.5001H11.875C13.2557 42.5001 14.375 41.3808 14.375 40.0001V34.6876C14.375 33.4795 13.3956 32.5001 12.1875 32.5001C8.21795 32.5001 5 29.2821 5 25.3126V20.0001ZM21.875 15.0001C21.875 13.6194 22.9943 12.5001 24.375 12.5001H28.125C33.6478 12.5001 38.125 16.9772 38.125 22.5001V37.5001C38.125 43.0229 33.6478 47.5001 28.125 47.5001H24.375C22.9943 47.5001 21.875 46.3808 21.875 45.0001V15.0001ZM26.875 17.5001V42.5001H28.125C30.8864 42.5001 33.125 40.2615 33.125 37.5001V22.5001C33.125 19.7387 30.8864 17.5001 28.125 17.5001H26.875Z"
      />
    </SvgIcon>
  );
});

export default SDK;

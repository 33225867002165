import React from 'react';
import qrcode from 'qrcode-generator';

export interface QRCodeProps {
  value: string;
}

class QRCode extends React.Component<QRCodeProps> {
  private qrcodeRef: React.RefObject<HTMLDivElement>;

  constructor(props: QRCodeProps) {
    super(props);
    this.qrcodeRef = React.createRef();
  }

  componentDidMount() {
    this.createQR();
  }

  componentDidUpdate(prevProps: QRCodeProps) {
    if (prevProps.value !== this.props.value) {
      this.createQR();
    }
  }

  render() {
    return <div ref={this.qrcodeRef} />;
  }

  private createQR() {
    if (!this.qrcodeRef.current) {
      return;
    }
    const typeNumber: TypeNumber = 0; // auto detect
    const errorCorrectionLevel: ErrorCorrectionLevel = 'M';
    const qr = qrcode(typeNumber, errorCorrectionLevel);
    qr.addData(this.props.value);
    qr.make();
    this.qrcodeRef.current.innerHTML = qr.createImgTag(4);
  }
}

export default QRCode;

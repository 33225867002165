export type ROLE_V2 =
  | 'SUPER_ADMIN'
  | 'ADMIN'
  | 'COPYWRITER'
  | 'ADS_ADMIN'
  | 'FINANCE_ADMIN'
  | 'BUSINESS_ADMIN'
  | 'BUSINESS_READER'
  | 'BLOG_ADMIN'
  | 'DEV_MANAGER_ADMIN'
  | 'DP_ADMIN'
  | 'GAME_ADMIN'
  | 'GAME_READER'
  | 'GAME_BUSINESS_ADMIN'
  | 'GAME_SEO_ADMIN'
  | 'PORTAL_COPYWRITER'
  | 'ENGINEER';

const adminRoles: ROLE_V2[] = [
  'ADS_ADMIN',
  'FINANCE_ADMIN',
  'BUSINESS_ADMIN',
  'BUSINESS_READER',
  'BLOG_ADMIN',
  'DEV_MANAGER_ADMIN',
  'DP_ADMIN',
  'GAME_ADMIN',
  'GAME_READER',
  'GAME_SEO_ADMIN',
  'ADMIN',
  'SUPER_ADMIN',
  'ENGINEER',
];

const submissionRoles: ROLE_V2[] = ['GAME_BUSINESS_ADMIN'];

const copyWriterRoles = ['PORTAL_COPYWRITER', 'COPYWRITER', 'SUPER_ADMIN'];

export function hasAdminAccess(roles: ROLE_V2[]): boolean {
  for (const role of roles) {
    if (adminRoles.includes(role)) {
      return true;
    }
  }
  return false;
}

export function hasSubmissionAccess(roles: ROLE_V2[]): boolean {
  for (const role of roles) {
    if (submissionRoles.includes(role)) {
      return true;
    }
  }
  return false;
}

export function hasCopywriterAccess(roles: ROLE_V2[]): boolean {
  for (const role of roles) {
    if (copyWriterRoles.includes(role)) {
      return true;
    }
  }
  return false;
}

import React from 'react';
import QRCode from './QRCode';
import { Grid } from '@mui/material';
import { StyledButton } from '../Styleguide/Common/Button';
import { StyledInput } from '../Styleguide/Common/Input';

export interface QRFormProps {
  initialValue?: string;
}

export interface QRFormState {
  renderedValue: string;
  userValue: string;
}

class QRForm extends React.Component<QRFormProps, QRFormState> {
  constructor(props: QRFormProps) {
    super(props);
    this.state = {
      renderedValue: this.props.initialValue || '',
      userValue: this.props.initialValue || '',
    };
  }

  render() {
    return (
      <Grid container spacing={1.5} alignItems="center" justifyContent="center" direction={'column'}>
        <Grid item>{this.renderCode()}</Grid>
        <Grid item width={'100%'}>
          {this.renderInput()}
        </Grid>
        <Grid item>{this.renderGenerate()}</Grid>
      </Grid>
    );
  }

  renderCode() {
    const { renderedValue } = this.state;
    return <QRCode value={renderedValue} />;
  }

  renderInput() {
    const { initialValue } = this.props;
    return <StyledInput defaultValue={initialValue} onChange={this.changeInput} />;
  }

  renderGenerate() {
    const { renderedValue, userValue } = this.state;
    const disabled = renderedValue === userValue;
    return (
      <StyledButton disabled={disabled} onClick={this.generate} variant="contained">
        Generate
      </StyledButton>
    );
  }

  changeInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const val = ev.target.value;
    this.setState({
      userValue: val,
    });
  };

  generate = () => {
    this.setState({ renderedValue: this.state.userValue });
  };
}

export default QRForm;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Day1: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H7V3C7 2.44772 7.44772 2 8 2ZM7 6H5C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V7C20 6.44772 19.5523 6 19 6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM13.3827 9.07612C13.7564 9.2309 14 9.59554 14 10V16C14 16.5523 13.5523 17 13 17C12.4477 17 12 16.5523 12 16V12.4142L11.7071 12.7071C11.3166 13.0976 10.6834 13.0976 10.2929 12.7071C9.90237 12.3166 9.90237 11.6834 10.2929 11.2929L12.2929 9.29289C12.5789 9.0069 13.009 8.92134 13.3827 9.07612Z"
      />
    </SvgIcon>
  );
});

export default Day1;

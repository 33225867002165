import React from 'react';
import { getUnfulfilledMainRequirements } from '../Requirements/RequirementsUtils';
import { QAToolContext } from '../../QAToolProvider';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
  DialogActions,
} from '@mui/material';
import { StyledBodyText, StyledWarningText } from '../../../../../../../common/Styleguide/Common/Text';
import { COLORS } from '../../../../../../../common/Styleguide/Common/colors';
import { StyledButton } from '../../../../../../../common/Styleguide/Common/Button';
import { PreviewSubmission } from '../../../../Preview/preview-graphql';

const SUPPORT_PAGE = 'https://developer.crazygames.com/support';

interface MainRequirementNotFulfilledPopupProps {
  submission: PreviewSubmission;
  next: () => void;
  back: () => void;
}

const MainRequirementNotFulfilledPopup: React.FC<MainRequirementNotFulfilledPopupProps> = ({ back, next }) => {
  const { loading, displaysCorrectly } = React.useContext(QAToolContext);
  const unfulfilledMainRequirements = getUnfulfilledMainRequirements(loading, displaysCorrectly);
  const [checked, setChecked] = React.useState(false);

  return (
    <Dialog open onClose={back}>
      <DialogTitle id="alert-dialog-slide-title">Warning</DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2, mt: 0 }}>
          The following main requirements have not been fulfilled:
        </StyledBodyText>
        {unfulfilledMainRequirements.map((value) => {
          return (
            <StyledWarningText key={value.key} style={{}}>
              {value.name}
            </StyledWarningText>
          );
        })}
        <StyledBodyText color="white60" sx={{ textAlign: 'center' }}>
          If you submit your game for review in its current state there is a high risk that the validation team will not be able to approve
          it. If you have some issues please visit our{' '}
          <a href={SUPPORT_PAGE} target="_blank" rel="noopener noreferrer">
            support page
          </a>
          .
        </StyledBodyText>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', pt: 2, pb: 3 }}>
        <FormControl fullWidth sx={{ alignItems: 'center', mb: 2 }}>
          <FormControlLabel
            sx={{ [`& .${formControlLabelClasses.label}`]: { color: COLORS.white[60] } }}
            control={<Checkbox checked={checked} onChange={(_evt: {}, checked: boolean) => setChecked(checked)} />}
            label="I understand that my submission will likely be rejected"
          />
        </FormControl>

        <div style={{ display: 'flex' }}>
          <StyledButton onClick={back} variant="contained" color="purple" height={50} sx={{ minWidth: 208, mr: 2 }}>
            Back to preview
          </StyledButton>
          <StyledButton onClick={next} variant="contained" color="white" height={50} style={{ minWidth: 208 }} disabled={!checked}>
            Continue anyway
          </StyledButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MainRequirementNotFulfilledPopup;

import React from 'react';
import { GET_MY_GAME_QUERY, GetMyGameQuery } from './submission-graphql';
import { StyledBodyText } from '../../../../../common/Styleguide/Common/Text';
import { useQuery } from '@apollo/client';
import { graphParseDateTime } from '../../../../../common/datetime';

interface Props {
  slug: string;
}

const PublishedDate: React.FC<Props> = ({ slug }) => {
  const { data: gameData } = useQuery<GetMyGameQuery>(GET_MY_GAME_QUERY, {
    variables: { slug: slug },
  });

  let publishedDate = '';
  if (gameData?.gameBySlug?.addedOn) {
    publishedDate = graphParseDateTime(gameData.gameBySlug.addedOn).format('DD.MM.YYYY');
    return (
      <StyledBodyText variant="bodyLower" color="white20" sx={{ marginBlock: 0 }}>
        Published on {publishedDate}
      </StyledBodyText>
    );
  }

  return null;
};

export default PublishedDate;

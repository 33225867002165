import { SvgIcon } from '@mui/material';
import * as React from 'react';

const AssetsIcons: React.FunctionComponent = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 14">
      <path d="M16 10.963V1.48148C15.9981 1.08917 15.8413 0.713478 15.5639 0.436067C15.2865 0.158656 14.9108 0.001947 14.5185 0H1.48148C1.08917 0.001947 0.713478 0.158656 0.436067 0.436067C0.158656 0.713478 0.001947 1.08917 0 1.48148V12.1481C0.001947 12.5405 0.158656 12.9162 0.436067 13.1936C0.713478 13.471 1.08917 13.6277 1.48148 13.6296H14.5185C14.9108 13.6277 15.2865 13.471 15.5639 13.1936C15.8413 12.9162 15.9981 12.5405 16 12.1481V10.963ZM14.2222 1.77778V8.81481L12.6 7.2C12.4635 7.06159 12.3009 6.95168 12.1216 6.87667C11.9423 6.80165 11.7499 6.76302 11.5556 6.76302C11.3612 6.76302 11.1688 6.80165 10.9895 6.87667C10.8102 6.95168 10.6476 7.06159 10.5111 7.2L9.18519 8.51852L6.08148 5.42222C5.94452 5.28471 5.78176 5.17559 5.60252 5.10114C5.42329 5.02668 5.23112 4.98835 5.03704 4.98835C4.84295 4.98835 4.65078 5.02668 4.47155 5.10114C4.29232 5.17559 4.12955 5.28471 3.99259 5.42222L1.77778 7.62963V1.77778H14.2222ZM1.77778 11.8519V10.1481L5.03704 6.88889L8.14074 9.98519C8.27719 10.1236 8.4398 10.2335 8.6191 10.3085C8.7984 10.3835 8.99082 10.4222 9.18519 10.4222C9.37955 10.4222 9.57197 10.3835 9.75127 10.3085C9.93057 10.2335 10.0932 10.1236 10.2296 9.98519L11.5556 8.66667L14.2222 11.3333V11.8519H1.77778ZM9.23704 5.57778C9.12659 5.46817 9.03896 5.33777 8.9792 5.1941C8.91945 5.05043 8.88875 4.89634 8.88889 4.74074C8.88889 4.42641 9.01376 4.12495 9.23602 3.90269C9.45829 3.68042 9.75974 3.55556 10.0741 3.55556C10.3884 3.55556 10.6899 3.68042 10.9121 3.90269C11.1344 4.12495 11.2593 4.42641 11.2593 4.74074C11.2593 5.05507 11.1344 5.35653 10.9121 5.57879C10.6899 5.80106 10.3884 5.92593 10.0741 5.92593C9.91847 5.92606 9.76439 5.89537 9.62072 5.83561C9.47705 5.77586 9.34664 5.68822 9.23704 5.57778Z" />
    </SvgIcon>
  );
});

export default AssetsIcons;

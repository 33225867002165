import * as Sentry from '@sentry/browser';
import { config, appVersion } from './config';
import { ApolloError } from '@apollo/client';
import { SessionContextData } from './Session/SessionContext';
import { onAuthChange } from './Session/firebase';

const { dsn, disabled } = config.sentry;

if (!disabled) {
  Sentry.init({
    dsn: dsn,
  });
}

// keep track of the user to populate react's user
let session: SessionContextData | null = null;
onAuthChange((newSession) => (session = newSession));

const configureBaseScope = (scope: Sentry.Scope) => {
  if (session && session.isLoggedIn()) {
    scope.setUser({
      id: session.getUser().firebaseUser.uid,
      email: session.getUser().email || undefined,
    });
  }
  scope.setTag('appVersion', appVersion);
};

export function captureReactError(error: {}, _errorInfo: React.ErrorInfo): void {
  if (disabled) {
    return;
  }
  Sentry.withScope((scope) => {
    configureBaseScope(scope);
    Sentry.captureException(error);
  });
}

export function showReportDialog() {
  if (disabled) {
    alert('Sentry is disabled');
    return;
  }
  return Sentry.showReportDialog();
}

export function captureApolloError(error: ApolloError): void {
  if (disabled) {
    return;
  }
  Sentry.withScope((scope) => {
    configureBaseScope(scope);
    if (error.extraInfo) {
      scope.setExtra('gql-extraInfo', JSON.stringify(error.extraInfo));
    }
    if (error.networkError) {
      scope.setExtra('gql-networkError', error.networkError.message);
    }
    if (error.stack) {
      scope.setExtra('gql-stack', error.stack);
    }
    Sentry.captureException(error);
  });
}

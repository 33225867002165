export const THUMB_RATIO = {
  width: 180,
  height: 111,
};

export const COVER_RATIO = {
  width: 712,
  height: 400,
};

export const COVER_MIN_DIMENSIONS = {
  width: 712,
  height: 400,
};

export const BLOG_THUMB_RATIO = {
  width: 180,
  height: 135,
};

export const COVER_LANDSCAPE_RATIO = {
  width: 1920,
  height: 1080,
};

export const COVER_PORTRAIT_RATIO = {
  width: 800,
  height: 1200,
};

export const COVER_SQUARE_RATIO = {
  width: 800,
  height: 800,
};

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { config } from '../../../../../../common/config';
export interface UploadInstructionsProps {
  open: boolean;
  onClose: () => void;
}

export const UploadInstructions: React.FC<UploadInstructionsProps> = ({ open, onClose }) => {
  const { filesDomain } = config.aws;
  const instructionsUrl = `http://${filesDomain}/devportal/upload-instructions.mp4`;
  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="xl">
      <DialogTitle>Upload instructions</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <video autoPlay controls style={{ width: 1100 }}>
          <source src={instructionsUrl} type="video/mp4" />
          Drag and drop files from your files browser in the upload zone.
        </video>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const Check: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.423 10.4446C52.5582 11.2305 52.8414 12.7879 52.0555 13.9231L29.5555 46.4231C29.1335 47.0326 28.4628 47.4234 27.7245 47.49C26.9862 47.5566 26.2564 47.2921 25.7322 46.7679L10.7322 31.7679C9.75592 30.7916 9.75592 29.2086 10.7322 28.2323C11.7085 27.256 13.2915 27.256 14.2678 28.2323L27.1496 41.1142L47.9445 11.0771C48.7304 9.94187 50.2878 9.65871 51.423 10.4446Z"
        />
      </svg>
    </SvgIcon>
  );
});

export default Check;

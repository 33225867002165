import { styled } from '@mui/material/styles';
import { COLORS } from '../../../common/Styleguide/Common/colors';

export const StyledMenuHeader = styled('div')(({ theme }) => ({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '12px',
  lineHeight: '135%',
  color: COLORS.white[20],
  paddingLeft: theme.spacing(2.5),
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(1.5),
}));

interface MenuItemProps {
  active?: boolean;
}
export const StyledMenuItem = styled('div', { shouldForwardProp: (prop) => prop !== 'active' })<MenuItemProps>(({ theme, active }) => ({
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  borderRadius: 10,
  height: 35,
  backgroundColor: active ? COLORS.black[50] : 'transparent',
  ':hover': {
    backgroundColor: COLORS.black[50],
  },
  svg: {
    marginRight: theme.spacing(1.5),
    width: 16,
  },
  marginTop: theme.spacing(0.25),
}));

export const StyledMenuItemText = styled('div')({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: COLORS.white[100],
});

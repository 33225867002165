import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, FormControlLabel, Checkbox } from '@mui/material';
import QRForm from '../../../../../../common/components/QRForm';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';
import { GameSize, getGameSizeTitle } from './Preview/GamePreviewSizes';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { PreviewSubmission } from '../../../Preview/preview-graphql';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import { getOrientationIcon } from '../../../../../../common/CzyForm/OrientationSelector';
import { REALISTIC_CONDITIONS_PARAM_NAME } from '../QATool';

interface Props {
  submission?: PreviewSubmission;
  realisticConditions: boolean;
}

const MobileSizeButton: React.FC<Props> = ({ submission, realisticConditions }) => {
  const [showMobilePopup, setShowMobilePopup] = useState<boolean>(false);
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(REALISTIC_CONDITIONS_PARAM_NAME, `${realisticConditions}`);

  const toggleMobilePopup = () => setShowMobilePopup(!showMobilePopup);

  const renderMobileInfo = () => {
    if (!submission) {
      return null;
    }
    const { isIOSFriendly, isAndroidFriendly, orientation } = submission;
    return (
      <>
        <FormControlLabel
          control={<Checkbox checked={isIOSFriendly} />}
          label="The game works well on iOS devices' mobile web browsers"
          disabled={true}
          sx={{ color: COLORS.black[10] }}
        />
        <FormControlLabel
          control={<Checkbox checked={isAndroidFriendly} />}
          label="The game works well on Android devices' mobile web browsers"
          disabled={true}
          sx={{ color: COLORS.black[10] }}
        />
        {orientation && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
            {getOrientationIcon(orientation)}
            {orientation}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <StyledButton
        variant="contained"
        color="darkGrey"
        shape="square"
        onClick={() => {
          setShowMobilePopup(!showMobilePopup);
        }}
      >
        {getGameSizeTitle(GameSize.MOBILE)}
      </StyledButton>
      {showMobilePopup && (
        <Dialog open onClose={toggleMobilePopup}>
          <DialogTitle>Mobile view</DialogTitle>
          <DialogContent sx={{ p: 4 }}>
            <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2, mt: 0 }}>
              Scan the QR code below with your mobile device or turn on your browser mobile emulation
            </StyledBodyText>
            <QRForm initialValue={currentUrl.toString()} />
            {renderMobileInfo()}
          </DialogContent>
          <DialogActions sx={{ flexDirection: 'column', pt: 2, pb: 3 }}>
            <StyledButton onClick={toggleMobilePopup} variant="contained" color="purple" height={50} sx={{ minWidth: 208, mr: 2 }}>
              Ok
            </StyledButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MobileSizeButton;

export function loadScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script');
    tag.onload = () => resolve();
    tag.onerror = (err) => reject(err);
    tag.src = src;
    tag.async = true;
    document.head.appendChild(tag);
  });
}

export function loadScriptContent(content: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script');
    tag.onload = () => resolve();
    tag.onerror = (err) => reject(err);
    tag.text = content;
    tag.async = true;
    document.head.appendChild(tag);
  });
}

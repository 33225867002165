import { styled } from '@mui/system';

export const PieChartLegendDot = styled('div')(({ theme: { spacing } }) => ({
  width: 12,
  height: 12,
  borderRadius: 45,
  marginRight: spacing(),
}));

export const PieChartLegendItem = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  marginTop: spacing(2),
}));

import { GameLoaderType } from './domain/game';

export type GAME_TECHNOLOGY = 'UNITY' | 'FLASH' | 'IFRAME' | 'LINK' | 'SWFVIEWER' | 'RUFFLE';

export function loaderTypeToGameTechnology(gameLoaderType: GameLoaderType): GAME_TECHNOLOGY {
  switch (gameLoaderType) {
    case '5.4.x':
    case '5.6.x':
    case 'unity2017':
    case 'unity2018':
    case 'unity2019':
    case 'unity2020':
    case 'unity2021':
    case 'unity2022':
    case 'unity2023':
    case 'unity6':
      return 'UNITY';
    case 'iframe':
    case 'html5':
      return 'IFRAME';
    case 'flash':
      return 'FLASH';
    case 'external':
      return 'LINK';
    case 'swfviewer':
      return 'SWFVIEWER';
    case 'ruffle':
      return 'RUFFLE';
    default:
      return 'IFRAME';
  }
}

export type IframeLoaderConfig = {
  url: string | null;
};

export interface SWFViewerLoaderConfig {
  swfLocation: string;
}

export interface RuffleLoaderConfig {
  swfLocation: string;
  loaderLocation?: string;
}

export type GameLoaderConfig =
  | IframeLoaderConfig
  | FlashLoaderConfig
  | UnityLoaderConfig
  | SWFViewerLoaderConfig
  | RuffleLoaderConfig
  | LinkLoaderConfig;

export type UnityLoaderConfig = Unity_5_4_X_LoaderConfig | Unity_5_6_X_LoaderConfig | Unity_2020_LoaderConfig;

export interface Unity_2020_LoaderConfigOptions {
  dataUrl: string;
  frameworkUrl: string;
  codeUrl: string;
  memoryUrl?: string;
  symbolsUrl?: string;
  streamingAssetsUrl?: string;
  devicePixelRatio?: number;
}

export interface Unity_2020_LoaderConfig {
  showProgress?: boolean;
  unityLoaderUrl: string;
  unityConfigOptions: Unity_2020_LoaderConfigOptions;
  unitySaveFileNames?: string[];
}

export interface Unity_5_6_X_LoaderConfig {
  moduleJsonUrl: string;
  unityLoaderUrl: string;
  unitySaveFileNames?: string[];
}

export interface Unity_5_4_X_LoaderConfig {
  module: {
    asmUrl?: string;
    memUrl: string;
    codeUrl: string;
    dataUrl: string;
  };
  unityLoaderUrl: string;
}

export interface FlashLoaderConfig {
  flashVars: {};
  swfParams: SwfParamsLoaderConfig;
  swfLocation: string;
}

export interface SwfParamsLoaderConfig {
  bgcolor: string;
  quality: string;
  allowNetworking: string;
  allowScriptAccess: string;
}

export interface LinkLoaderConfig {
  url: string;
  isDownloadable?: boolean;
}

export function getDefaultFlashConfig(): FlashLoaderConfig {
  return {
    flashVars: {},
    swfParams: {
      bgcolor: '#fff',
      quality: 'high',
      allowNetworking: 'internal',
      allowScriptAccess: 'sameDomain',
    },
    swfLocation: '',
  };
}

export function getDefaultSWFViewerConfig(): SWFViewerLoaderConfig {
  return {
    swfLocation: '',
  };
}

export function getDefaultRuffleConfig(): RuffleLoaderConfig {
  return {
    swfLocation: '',
    loaderLocation: '',
  };
}

export function getDefaultIframeConfig(): IframeLoaderConfig {
  return {
    url: '',
  };
}

export function getDefaultLinkConfig(): LinkLoaderConfig {
  return {
    url: '',
  };
}

/** Used also for Unity 2021, 2022, 2023, Unity 6 */
export function getDefaultUnity_2020_Config(): Unity_2020_LoaderConfig {
  return {
    unityLoaderUrl: '',
    unityConfigOptions: {
      dataUrl: '',
      frameworkUrl: '',
      codeUrl: '',
      memoryUrl: '',
      symbolsUrl: '',
      streamingAssetsUrl: '',
    },
  };
}

/** Used also for Unity 2017, 2018, 2019 */
export function getDefaultUnity_5_6_X_Config(): Unity_5_6_X_LoaderConfig {
  return {
    moduleJsonUrl: '',
    unityLoaderUrl: '',
  };
}

export function getDefaultUnity_5_4_X_Config(): Unity_5_4_X_LoaderConfig {
  return {
    module: {
      memUrl: '',
      codeUrl: '',
      dataUrl: '',
    },
    unityLoaderUrl: '',
  };
}

export function getDefaultLoaderConfig(gameLoaderType: GameLoaderType): GameLoaderConfig {
  switch (gameLoaderType) {
    case 'iframe':
    case 'html5':
      return getDefaultIframeConfig();
    case 'flash':
      return getDefaultFlashConfig();
    case 'unity6':
    case 'unity2023':
    case 'unity2022':
    case 'unity2021':
    case 'unity2020':
      return getDefaultUnity_2020_Config();
    case 'unity2019':
    case 'unity2018':
    case 'unity2017':
    case '5.6.x':
      return getDefaultUnity_5_6_X_Config();
    case '5.4.x':
      return getDefaultUnity_5_4_X_Config();
    case 'external':
      return getDefaultLinkConfig();
    case 'swfviewer':
      return getDefaultSWFViewerConfig();
    case 'ruffle':
      return getDefaultRuffleConfig();
    default:
      throw new Error(`[Tech index] unknown game loader type: ${gameLoaderType}`);
  }
}

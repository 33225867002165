import React from 'react';

import SessionContext, { SessionContextData } from './SessionContext';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export interface WithSession {
  session: SessionContextData;
}

// any cast on Component is needed for some reason. Please enlighten me type-god (:
function withSession<TProps extends WithSession>(Component: any): React.FC<Omit<TProps, 'session'>> {
  const wrapper: React.FC<Omit<TProps, 'session'>> = (props) => (
    <SessionContext.Consumer>{(context) => <Component session={context} {...props} />}</SessionContext.Consumer>
  );
  return wrapper;
}

export default withSession;

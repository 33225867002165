import { Theme, styled } from '@mui/material';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';

export const StyledQuoteSection = styled('section')(({ theme: { breakpoints, spacing } }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: spacing(6),
  paddingLeft: spacing(20),
  paddingRight: spacing(20),
  color: COLORS.white[80],
  [breakpoints.down('sm')]: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
}));

export const QuoteAvatarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export const QuoteAvatarLine = styled('div')({
  flex: 1,
  height: 1,
  opacity: 0.2,
  backgroundColor: '#FFF',
});

export const QuoteAvatarImage = styled('img')(({ theme: { breakpoints, spacing } }: { theme: Theme }) => ({
  width: 60,
  height: 60,
  marginLeft: spacing(4),
  marginRight: spacing(4),
  [breakpoints.down('sm')]: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
  },
}));

export const QuoteAuthorName = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  marginTop: spacing(2),
  fontWeight: 900,
}));

export const QuoteAuthorTitle = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  fontWeight: 400,
  marginBottom: spacing(2),
}));

export const QuoteText = styled('h5')(({ theme: { spacing } }: { theme: Theme }) => ({
  textAlign: 'center',
  fontStyle: 'italic',
}));

import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import MainRequirements from './MainRequirements';
import SDKRequirements from './SDKRequirements';
import { StyledHeaderTextH2 } from '../../../../../../../common/Styleguide/Common/Text';
import { PreviewSubmission } from '../../../../Preview/preview-graphql';

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 7,
      height: 7,
    },
    '&::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
      border: '0px none #ffffff',
      borderRadius: 50,
    },
  };
});

export interface Props {
  submission?: PreviewSubmission;
}

const Requirements: React.FC<Props> = ({ submission }) => {
  return (
    <StyledGrid item container direction="column" wrap="nowrap">
      <StyledHeaderTextH2 sx={{ m: 0, mb: 1 }}>Requirements</StyledHeaderTextH2>
      <Grid item container direction="column">
        <StyledHeaderTextH2 sx={{ m: 0 }} variant="h3" color="white80">
          Requirements
        </StyledHeaderTextH2>
        <MainRequirements />
      </Grid>
      <Grid item container direction="column">
        <StyledHeaderTextH2 sx={{ m: 0 }} variant="h3" color="white80">
          SDK (optional)
        </StyledHeaderTextH2>
        <Grid item>
          <Grid container>
            <SDKRequirements submission={submission} />
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default Requirements;

import { Box, Switch } from '@mui/material';
import { styled } from '@mui/system';
import { HEADER_HEIGHT } from '../../../Header/Header';
import { scrollbarStyles } from '../../../theme';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';

export const TOP_BOX_HEIGHT = 100;

interface StyledBottomBoxProps {
  gameframeHeight: number;
}

export const StyledOptionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(2.5),
  gap: theme.spacing(1),
}));

export const StyledBottomBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gameframeHeight',
})<StyledBottomBoxProps>(({ gameframeHeight }) => ({
  width: '100%',
  height: `max(calc(100vh - ${HEADER_HEIGHT}px - ${TOP_BOX_HEIGHT}px),${gameframeHeight}px)`,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledBottomAdjacentBox = styled(Box)({
  overflowY: 'auto',
  ...scrollbarStyles(4),
  flexShrink: 10,
});

export const StyledSwitch = styled(Switch)(() => ({
  height: 30,
  padding: 0,
  marginLeft: 16,
  marginRight: 16,
  borderRadius: '40px',
  '& .MuiSwitch-switchBase': {
    paddingTop: 5,
    paddingBottom: 5,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: COLORS.white[100],
    padding: 5,
  },
  '& .MuiSwitch-track': {
    backgroundColor: COLORS.black[60],
    opacity: 1,
  },

  '.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#10E9D7 !important',
    opacity: '1 !important',
  },
}));

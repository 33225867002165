import { COLORS } from '../../../../../common/Styleguide/Common/colors';

const PREFIX = 'Submission';

export const classes = {
  root: `${PREFIX}-root`,
  notStarted: `${PREFIX}-notStarted`,
  completed: `${PREFIX}-completed`,
  error: `${PREFIX}-error`,
  warning: `${PREFIX}-warning`,
  button: `${PREFIX}-button`,
  gameTitleInput: `${PREFIX}-gameTitleInput`,
  formWrapper: `${PREFIX}-formWrapper`,
  formControl: `${PREFIX}-formControl`,
  columnForm: `${PREFIX}-columnForm`,
  thirdColumn: `${PREFIX}-thirdColumn`,
  leftColumn: `${PREFIX}-leftColumn`,
};

const styledSubmission = ({ theme: { spacing, palette } }: any) => ({
  [`& .${classes.root}`]: {
    width: '90%',
  },

  [`& .${classes.notStarted}`]: {
    marginTop: spacing(),
    marginBottom: spacing(),
  },

  [`& .${classes.completed}`]: {
    marginTop: spacing(),
    marginBottom: spacing(),
  },

  [`& .${classes.error}`]: {
    marginTop: spacing(),
    marginBottom: spacing(),
    color: palette.error.main,
  },

  [`& .${classes.warning}`]: {
    marginTop: spacing(),
    marginBottom: spacing(),
    color: palette.error.light,
  },

  [`& .${classes.button}`]: {
    margin: spacing(),
  },

  [`& .${classes.gameTitleInput}`]: {
    '& input': {
      color: '#5e5e5e',
      fontWeight: 700,
      fontSize: '1.3rem',
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginTop: 16,
    '& label': {
      fontWeight: 700,
      color: COLORS.black[80],
      fontSize: '1.1rem',
      paddingBottom: spacing(),
      display: 'block',
    },
  },

  [`& .${classes.formControl}`]: {
    marginBottom: spacing(2),
  },

  [`& .${classes.columnForm}`]: {
    flexGrow: 1,
  },

  [`& .${classes.thirdColumn}`]: {
    width: '32%',
  },

  [`& .${classes.leftColumn}`]: {
    paddingRight: spacing(2),
  },
});

export default styledSubmission;

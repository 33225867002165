import { SessionContextData } from './SessionContext';
import defer from '../defer';
import moment from 'moment';
import { setHeader } from '../graphql';
import { onAuthStateChanged, User } from 'firebase/auth';
import { getAuth } from '../firebase';

const initialSession = defer<SessionContextData>();
let initialSessionResolved = false;
let currentUser: User | null = null;
let currentToken: string | null = null;
let tokenValidUntil = moment.utc();

export type AuthChangeListener = (session: SessionContextData) => any;

let listeners: { [key: number]: AuthChangeListener } = {};

/**
 * internal bookkeeping
 */
async function setCurrentUser(user: User | null, forceRefreshToken: boolean): Promise<void> {
  currentUser = user;
  if (user) {
    currentToken = await user.getIdToken(forceRefreshToken);
    // token is valid for 1 hour according to firebase docs
    tokenValidUntil = moment.utc().add(1, 'hour');
    setHeader('Authorization', `Bearer ${currentToken}`);
  } else {
    currentToken = null;
    tokenValidUntil = moment.utc();
  }
}

export const resolveInitialSession = initialSession.promise;

export type DetachListener = () => void;

let listenerId = 0;

/**
 * To avoid strange effects, listeners may not be attached before the initial user is resolved.
 * @param listener Callback that receives new user object or null
 */
export function onAuthChange(listener: AuthChangeListener): DetachListener {
  const id = listenerId++;
  listeners[id] = listener;
  return () => delete listeners[id];
}

/**
 * When a user sign's in, the firebase auth state is not immediately changed.
 * You need to wait for the onAuthStateChange callback to trigger. Invoking this
 * method immediately after receiving the OK from the sign in will return a promise
 * that resolves as soon as the user is properly signed in.
 * @param timeoutInMs If the onAuthStateChange handler was not invoked within this timeout, the promise is rejected.
 */
export async function waitOnAuthChange(timeoutInMs: number): Promise<SessionContextData> {
  const authChange = defer<SessionContextData>();
  const detach = onAuthChange((session) => authChange.resolve(session));
  const timeout = new Promise((_resolve, reject) => setTimeout(() => reject('[firebase] waitOnAuthChange timed out'), timeoutInMs));
  try {
    await Promise.race([authChange.promise, timeout]);
    // authChange.promise is resolved at this point, as the timeout didn't win the race (:
    return authChange.promise;
  } finally {
    // stop listening for auth changes once the promise is resolved or the timeout has expired
    detach();
  }
}

const MINIMUM_TOKEN_VALIDITY = moment.duration(5, 'minutes');

let checkingForRefresh = false;

const checkForTokenRefresh = async () => {
  // avoid running multiple refreshes at the same time to avoid interval pile-up.
  if (checkingForRefresh) {
    return;
  }
  if (currentUser) {
    const validFor = tokenValidUntil.diff(moment.utc());
    if (validFor > MINIMUM_TOKEN_VALIDITY.asMilliseconds()) {
      console.debug(`[firebase] Token expires in ${moment.duration(validFor).toISOString()}`);
      return;
    } else {
      console.debug('[firebase] Token about to expire, refreshing');
      // toggle checkingForRefresh once we start something async within the interval callback
      checkingForRefresh = true;
      // the token is not valid for much longer, forcefully refresh the token
      try {
        await setCurrentUser(currentUser, true);
      } finally {
        checkingForRefresh = false;
      }
    }
  }
};

let initialized = false;

export function initializeFirebaseSession() {
  if (initialized) {
    throw new Error('[firebase] tried to initialize firebase session twice');
  }
  initialized = true;

  // Start listening for firebase auth state changes
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    // tokens are shared across tabs, and our initial session may reuse a token if its still valid
    // since we dont know how long that token will be valid we need to force a fresh token for every session
    await setCurrentUser(user, true);
    // provide the firebase user in a more usable form to all listeners
    const session = new SessionContextData(user);
    if (!initialSessionResolved) {
      initialSessionResolved = true;
      initialSession.resolve(session);
    }
    for (const listener of Object.values(listeners)) {
      listener(session);
    }
  });

  // Window returned back in focus possibly after a long while
  // Chrome sometimes "suspends" background processes such as interval & setTimeout
  // So manually check to see if the session is still valid, otherwise refresh the token
  window.addEventListener('focus', checkForTokenRefresh);

  // Periodically check if the token needs to be refreshed
  const INTERVAL_DURATION = moment.duration(4, 'minutes');
  setInterval(() => checkForTokenRefresh(), INTERVAL_DURATION.asMilliseconds());
}

import { Box } from '@mui/system';
import React from 'react';

export const GradientBackground: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, 0%)',
        opacity: 0.5,
        overflow: 'hidden',
        zIndex: 0,
        pointerEvents: 'none',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 1143,
          height: 1075,
          left: '35%',
          top: 0,
          opacity: 0.6,
          overflow: 'hidden',
        }}
      >
        <svg width="1144" height="948" viewBox="0 0 1144 948" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="571.694" cy="409.748" rx="571.653" ry="537.748" fill="url(#paint0_radial_305_2350)" />
          <defs>
            <radialGradient
              id="paint0_radial_305_2350"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(571.694 409.748) rotate(90) scale(537.748 571.653)"
            >
              <stop stopColor="#5A45DF" />
              <stop offset="1" stopColor="#0C0D14" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: 1143,
          height: 1075,
          left: '15%',
          top: 0,
          opacity: 0.6,
          overflow: 'hidden',
        }}
      >
        <svg width="1144" height="1005" viewBox="0 0 1144 1005" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="571.653" cy="537.945" rx="571.653" ry="537.748" fill="url(#paint0_radial_305_2348)" />
          <defs>
            <radialGradient
              id="paint0_radial_305_2348"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(571.653 537.945) rotate(90) scale(537.748 571.653)"
            >
              <stop stopColor="#FB23FF" />
              <stop offset="1" stopColor="#0C0D14" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </Box>
    </Box>
  );
};

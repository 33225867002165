import React from 'react';
import { Grid } from '@mui/material';
import { imgix } from '../../../helpers/imgix';
import { StyledTestimonialGrid, TestimonialText, TestimonialSlide } from './landingPage.styles';

interface TestimonialsSlideProps {
  imageSrc: string;
  imageLink: string;
  author?: string;
  organisation?: string;
  testimonial: string;
}

const TESTIMONIALS_DATA: TestimonialsSlideProps[] = [
  {
    imageLink: 'https://www.crazygames.com/game/shellshockersio',
    imageSrc: imgix('devportal/testimonial2.png', { w: 145, h: 145 }),
    author: 'Jason Kapalka',
    organisation: 'CEO of Blue Wizard & founder of Popcap',
    testimonial: `CrazyGames has been great to work with on Shell Shockers and our other web games. The CrazyGames team is always professional and helpful!`,
  },
  {
    imageSrc: imgix('devportal/testimonial5.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/idle-mining-empire',
    author: 'Ben Chong',
    organisation: 'CEO and Founder of MarketJS.com',
    testimonial: `CrazyGames has been a fantastic partner to work with over the years. They are friendly, highly technical and easy to work with. Above all, they understand casual games at a deep level, which is what matters at the end of the day`,
  },
  {
    imageSrc: imgix('devportal/testimonial.8.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/12-minibattles',
    author: 'Mariano Maffia',
    organisation: 'CEO of Shared Dreams Studios',
    testimonial: `We have been very happy working together with Crazy Games for many years. Crazy Games is more than an amazing partner, today we are friends`,
  },
  {
    imageSrc: imgix('devportal/testimonial4.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/summoner-master',
    author: 'Jean-Claude Yalap',
    organisation: 'Co-founder of YSO Corp',
    testimonial:
      'CrazyGames makes quality games a priority on their platform. They are quick to provide feedback and are always ready to help.',
  },
  {
    imageSrc: imgix('devportal/testimonial9.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/air-wars-3',
    author: 'Shade',
    organisation: 'Owner at Martian Games',
    testimonial:
      'Ironically, CrazyGames is one of the few publishers I’ve worked with in 20 years of game development who has NOT drived me Crazy!',
  },
  {
    imageSrc: imgix('devportal/testimonial1.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/evowarsio',
    author: 'Toby',
    organisation: 'Co-Founder at Night Steed',
    testimonial:
      'Unlike many other partners we worked with, CrazyGames always had a “human approach”. There was no issue we couldn’t solve with just friendly communication. CrazyGames is here after all these years. Stronger than ever. Trouble-solver not a trouble-bringer!',
  },
  {
    imageSrc: imgix('devportal/testimonial10.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/the-mage',
    author: 'Gabriel Bissonnette',
    organisation: 'The Mage',
    testimonial:
      'The SDK was by far the best I’ve worked with ever. I just installed a package and done. With CrazyGames, everything is super simple and clearly indicated.',
  },
  {
    imageSrc: imgix('devportal/testimonial3.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/bullet-force-multiplayer',
    author: 'Clint Wilde',
    organisation: 'CEO of Blayze Games',
    testimonial:
      'CrazyGames provided an easy to use interface for uploading games and their staff are great. They are always helpful and make the games a win-win for both parties.',
  },
  {
    imageSrc: imgix('devportal/testimonial7.png', { w: 145, h: 145 }),
    imageLink: 'https://www.crazygames.com/game/smash-karts',
    author: 'John Halloran',
    organisation: 'Co-Founder at Tall Team',
    testimonial: `CrazyGames has been a great partner for us. When deciding where to launch our first web game, Smash Karts, we chose CrazyGames as we felt that it was the home of IO games and success there would lead to success elsewhere.`,
  },
];

const TestimonialsSlide: React.FC<TestimonialsSlideProps> = ({ imageLink, imageSrc, author, organisation, testimonial }) => {
  return (
    <TestimonialSlide>
      <a href={imageLink} target="_blank" rel="noopener noreferrer">
        <img src={imageSrc} alt={author} />
      </a>

      {author && <div className="testimonialAuthor">{author}</div>}
      {organisation && <div className="testimonialOrg">{organisation}</div>}

      <TestimonialText>{`"${testimonial}"`}</TestimonialText>
    </TestimonialSlide>
  );
};

const TestimonialsGrid: React.FC = () => {
  return (
    <Grid container justifyContent="center">
      <StyledTestimonialGrid>
        {TESTIMONIALS_DATA.map((props: TestimonialsSlideProps) => (
          <TestimonialsSlide {...props} key={props.imageLink} />
        ))}
      </StyledTestimonialGrid>
    </Grid>
  );
};

export default TestimonialsGrid;

import React, { useContext } from 'react';
import moment from 'moment/moment';
import { StyledButton } from '../../../../../../common/Styleguide/Common/Button';
import { SubmissionContext } from '../Context/SubmissionProvider';
import Alert from '../../../../../../common/Styleguide/Common/Alert';
import { GAME_GUIDELINES_URL } from './UpdateSubmission.types';

interface SubmissionStatusHeaderProps {
  navToFeedback: () => void;
}

const SubmissionStatusHeader: React.FC<SubmissionStatusHeaderProps> = ({ navToFeedback }) => {
  const { submission } = useContext(SubmissionContext);

  if (!submission) {
    return null;
  }

  const renderWaitingForReview = () => {
    return (
      <Alert color="purple" title="Waiting for review">
        Your game has been sent for review. We will notify you once the team has reviewed your game.
      </Alert>
    );
  };

  const renderCongratulations = () => {
    return (
      <Alert color="green" title="Congratulations!">
        The team has reviewed your game, and approved it! It is now live on CrazyGames.
      </Alert>
    );
  };

  const renderRejected = () => {
    return (
      <Alert color="red" title="Your submission has been rejected">
        Unfortunately, your game has been rejected. Make sure to read{' '}
        <a target="_blank" rel="noopener noreferrer" href={GAME_GUIDELINES_URL}>
          our documentation
        </a>{' '}
        to find all the guidelines and requirements regarding game submissions. Due to the large number of submissions we receive every day,
        we are unable to give you personalised feedback. Thank you for your understanding.
      </Alert>
    );
  };

  const renderFeedback = () => {
    const { feedback } = submission;
    if (!feedback || feedback?.length < 1) {
      return null;
    }
    const lastFeedback = feedback.at(-1);
    const lastFeedbackDate = moment(lastFeedback?.postedOn).format('DD.MM.YYYY');
    const title = (
      <>
        Feedback from {lastFeedbackDate}{' '}
        <StyledButton variant="link" onClick={navToFeedback}>
          View all team feedback
        </StyledButton>{' '}
      </>
    );
    return (
      <Alert color="orange" title={title}>
        Here's the feedback from our team:
        <br />
        <p>{lastFeedback?.comment && <em dangerouslySetInnerHTML={{ __html: lastFeedback?.comment.replaceAll('\n', '<br />') }} />}</p>
        Once you’ve applied those changes, please upload the files and go to our QA tool to submit again.
      </Alert>
    );
  };

  if (submission.status === 'NEW_SUBMISSION' || submission.status === 'SYNC_REQUIRED') {
    return renderWaitingForReview();
  }
  if (submission.status === 'AWAITING_DEVELOPER_CHANGE') {
    return renderFeedback();
  }
  if (submission.status === 'REJECTED') {
    return renderRejected();
  }
  if (submission.status === 'RELEASED') {
    return renderCongratulations();
  }

  return null;
};

export default SubmissionStatusHeader;

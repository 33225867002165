import { Option, SelectOption } from '@mui/base';
import * as React from 'react';
import { CustomSelect } from '../../../../../../common/Styleguide/Common/Select';
import { GameTag } from './gamedetailsform-graphql';
import CloseIcon from '@mui/icons-material/Close';
import { styled, Theme } from '@mui/material';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';

export interface Props {
  selected: string[];
  tags: GameTag[];
  disabled?: boolean;
  onChange: (tags: string[]) => void;
  error?: boolean;
}

class TagsSelector extends React.Component<Props> {
  render() {
    const { selected, tags, disabled, error } = this.props;
    return (
      <CustomSelect
        multiple
        renderValue={(option: any) => {
          return this.renderValue(option);
        }}
        error={error}
        value={selected ? selected : []}
        disabled={disabled}
        onChange={(_e: any, newValue: any) => {
          this.handleOnChange(newValue);
        }}
      >
        {tags
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((singleTag) => {
            return (
              <Option key={singleTag.id} value={singleTag.id}>
                {singleTag.name}
              </Option>
            );
          })}
      </CustomSelect>
    );
  }

  private handleOnChange(newIds: string[]) {
    const { onChange } = this.props;
    const filteredIds = newIds.length > 5 ? newIds.splice(-5) : newIds;
    onChange(filteredIds);
  }

  handleOnRemove = (removingId: string) => {
    const { selected, onChange } = this.props;
    const newids = [...selected];
    onChange(newids.filter((id) => id !== removingId));
  };

  private renderValue(option: SelectOption<any>[]) {
    if (option == null) {
      return null;
    }
    const { disabled } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {option.map((singleOption) => (
          <StyledDiv
            key={singleOption.value}
            onClick={(e) => {
              e.preventDefault();
              if (disabled) {
                return;
              }
              this.handleOnRemove(singleOption.value);
            }}
          >
            {singleOption.label}
            <CloseIcon style={{ height: '100%' }} />
          </StyledDiv>
        ))}
      </div>
    );
  }
}

export const StyledDiv = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  marginLeft: spacing(0.5),
  marginRight: spacing(0.5),
  borderRadius: 8,
  padding: spacing(1, 2),
  display: 'flex',
  background: COLORS.black[30],
  color: 'white',
  fontSize: 14,
  fontWeight: 700,
}));
export default TagsSelector;

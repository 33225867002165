export function imageFileAsDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });
    reader.addEventListener('error', (err) => {
      reject(err);
    });
  });
}

export function isImageFile(fileName: string) {
  const imageFileExtensions = ['.png', '.jpg', '.jpeg', '.bmp', '.gif'];
  return imageFileExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
}

export function basename(fileName: string) {
  if (fileName.endsWith('/') || fileName.endsWith('.')) {
    throw new Error('invalid fileName');
  }
  // remove path
  let base = fileName.substring(fileName.lastIndexOf('/') + 1);
  if (base.lastIndexOf('.') > 0) {
    // remove extension
    base = base.substring(0, base.lastIndexOf('.'));
  }
  return base;
}

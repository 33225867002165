import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Chart: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1678 5.77499C29.6974 6.24923 30 6.92648 30 7.63734V30H52.3627C53.0735 30 53.7508 30.3026 54.225 30.8322C54.6993 31.3617 54.9257 32.0681 54.8475 32.7747C53.4655 45.2772 42.8699 55 30 55C16.1929 55 5 43.8071 5 30C5 17.1301 14.7228 6.53456 27.2253 5.15248C27.9319 5.07437 28.6383 5.30075 29.1678 5.77499ZM25 10.6296C16.3747 12.849 10 20.6815 10 30C10 41.0457 18.9543 50 30 50C39.3185 50 47.151 43.6253 49.3704 35H27.5C26.1193 35 25 33.8807 25 32.5V10.6296ZM36.0565 6.73959C36.7191 6.27101 37.568 6.15322 38.3331 6.42367C45.4382 8.93494 51.0652 14.5619 53.5766 21.6669C53.8471 22.432 53.7293 23.2809 53.2607 23.9435C52.7922 24.6061 52.0311 25 51.2196 25H37.5C36.1193 25 35 23.8807 35 22.5V8.78077C35 7.96922 35.3939 7.20817 36.0565 6.73959ZM40 12.6757V20H47.3246C45.568 16.9644 43.0357 14.4322 40 12.6757Z"
      />
    </SvgIcon>
  );
});

export default Chart;

import { padStart, clone, debounce, sample } from 'lodash';

const lodash = {
  padStart: padStart,
  clone: clone,
  debounce: debounce,
  sample: sample,
};

export default lodash;

const playConfetti = async () => {
  const confetti = (await import('canvas-confetti')).default;

  const colors = [
    '#9258e3',
    '#d58939',
    '#09ffea',
    '#ffef4b',
    '#4bd3ff',
    '#ff4bde',
    '#a399f4',
    '#f27b3e',
    '#5dff4b',
    '#ffea4b',
    '#09ffea',
    '#ffa62a',
  ];

  confetti({
    particleCount: 150,
    angle: 60,
    spread: 65,
    origin: { x: 0 },
    colors: colors,
  });
  confetti({
    particleCount: 150,
    angle: 120,
    spread: 65,
    origin: { x: 1 },
    colors: colors,
  });
};

export default playConfetti;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const GoogleColoredIcon: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 10.1894C18.5 9.59851 18.447 9.03033 18.3485 8.48488H10.5V11.7084H14.9848C14.7917 12.75 14.2045 13.6326 13.322 14.2235V16.3144H16.0151C17.5909 14.8637 18.5 12.7273 18.5 10.1894Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4997 18.3333C12.7497 18.3333 14.6361 17.5871 16.0149 16.3144L13.3217 14.2235C12.5755 14.7235 11.6209 15.0189 10.4997 15.0189C8.32925 15.0189 6.49213 13.553 5.83682 11.5833H3.05273V13.7424C4.42395 16.4659 7.24213 18.3333 10.4997 18.3333Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83696 11.5834C5.67029 11.0834 5.57559 10.5493 5.57559 10C5.57559 9.45079 5.67029 8.9167 5.83696 8.4167V6.25761H3.05287C2.48847 7.38261 2.1665 8.65534 2.1665 10C2.1665 11.3447 2.48847 12.6175 3.05287 13.7425L5.83696 11.5834Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4997 4.9811C11.7232 4.9811 12.8217 5.40155 13.6853 6.22731L16.0755 3.83716C14.6323 2.49246 12.7459 1.6667 10.4997 1.6667C7.24213 1.6667 4.42395 3.53413 3.05273 6.25761L5.83682 8.4167C6.49213 6.447 8.32925 4.9811 10.4997 4.9811Z"
        fill="#EA4335"
      />
    </SvgIcon>
  );
});

export default GoogleColoredIcon;

import { ConnectorUser } from '../../QAEventListener';

export type HardcodedUsers = 'user1' | 'user2' | 'user3' | 'user4' | 'user5';
export type FakeUserSelectKeys = HardcodedUsers | 'userLoggedOut';

export const FakeUserSelectValues: { [k in FakeUserSelectKeys]: string } = {
  user1: 'User 1',
  user2: 'User 2',
  user3: 'User 3',
  user4: 'User 4',
  user5: 'User 5',
  userLoggedOut: 'Logged out',
};

export const FakeUsers: { [k in HardcodedUsers]: ConnectorUser } = {
  // To generate QA tool IDs, UP requires them to be prefixed with "qatool" to avoid impersonation of real user IDs
  user1: {
    id: 'qatoolUser1',
    username: 'User1',
    profilePictureUrl: 'https://images.crazygames.com/userportal/avatars/1.png',
  },
  user2: {
    id: 'qatoolUser2',
    username: 'User2',
    profilePictureUrl: 'https://images.crazygames.com/userportal/avatars/2.png',
  },
  user3: {
    id: 'qatoolUser3',
    username: 'User3',
    profilePictureUrl: 'https://images.crazygames.com/userportal/avatars/3.png',
  },
  user4: {
    id: 'qatoolUser4',
    username: 'User4',
    profilePictureUrl: 'https://images.crazygames.com/userportal/avatars/4.png',
  },
  user5: {
    id: 'qatoolUser5',
    username: 'User5',
    profilePictureUrl: 'https://images.crazygames.com/userportal/avatars/5.png',
  },
};

export type UserEventType = 'userPortalInfoSync' | 'userLoggedIn';

export interface PortalUserEventData {
  type: UserEventType;
  data: {
    userId?: string;
    userData?: PortalUserData;
  };
}

export interface PortalUserData {
  username: string;
  profilePictureUrl: string;
}

export type Callback<T = any> = (data: T) => void;

import * as React from 'react';
import { StyledBodyText } from '../../../../common/Styleguide/Common/Text';
import { FirebaseError } from 'firebase/app';

export interface ErrorMessageProps {
  error?: FirebaseError | string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  const getErrorMessage = () => {
    if (!error) {
      return '';
    }
    const errorCode = (error as FirebaseError).code ? (error as FirebaseError).code : (error as string);

    if (errorCode?.includes('auth/invalid-email')) {
      return 'Email address is not valid';
    } else if (errorCode?.includes('auth/user-not-found')) {
      return "Account doesn't exist";
    } else if (errorCode?.includes('auth/wrong-password')) {
      return 'Password is wrong';
    } else if (errorCode?.includes('auth/email-already-in-use')) {
      return 'Account with the given Email address already exists';
    }
    return error;
  };
  return <StyledBodyText color="error">{`${getErrorMessage()}`}</StyledBodyText>;
};

export default ErrorMessage;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const Upload: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C8.79086 4 7 5.79086 7 8C7 8.30288 7.03348 8.59674 7.09656 8.87852C7.21716 9.41729 6.8783 9.95187 6.33958 10.0727C5.00015 10.3732 4 11.571 4 13C4 14.6569 5.34315 16 7 16C7.55228 16 8 16.4477 8 17C8 17.5523 7.55228 18 7 18C4.23858 18 2 15.7614 2 13C2 10.9436 3.24073 9.1787 5.01385 8.41034C5.00466 8.27465 5 8.13781 5 8C5 4.68629 7.68629 2 11 2C13.6297 2 15.8626 3.69093 16.6738 6.0444C19.6243 6.40195 22 8.91263 22 12C22 14.8798 19.9616 17.4192 17.1989 17.98C16.6577 18.0899 16.1299 17.7402 16.02 17.1989C15.9101 16.6577 16.2598 16.1299 16.8011 16.02C18.6029 15.6542 20 13.9581 20 12C20 9.78791 18.132 7.97916 15.91 8.00093C15.4306 8.00563 15.0154 7.66951 14.9201 7.19971C14.5499 5.37395 12.9343 4 11 4ZM11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929L15.7071 13.2929C16.0976 13.6834 16.0976 14.3166 15.7071 14.7071C15.3166 15.0976 14.6834 15.0976 14.2929 14.7071L13 13.4142V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V13.4142L9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.29289 14.7071C7.90237 14.3166 7.90237 13.6834 8.29289 13.2929L11.2929 10.2929Z"
      />
    </SvgIcon>
  );
});

export default Upload;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Twitter: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M22 5.92858C21.2645 6.25449 20.4744 6.47469 19.6438 6.57423C20.5008 6.0614 21.142 5.25428 21.4477 4.30346C20.6425 4.78174 19.7612 5.11839 18.8422 5.29879C18.2242 4.63894 17.4057 4.20157 16.5136 4.0546C15.6216 3.90764 14.706 4.05929 13.909 4.48601C13.1119 4.91274 12.4781 5.59066 12.1058 6.41454C11.7336 7.23841 11.6437 8.16213 11.8503 9.0423C10.2187 8.96038 8.62259 8.53631 7.16553 7.79761C5.70847 7.05891 4.42301 6.02209 3.39258 4.75444C3.04025 5.36221 2.83766 6.06687 2.83766 6.81733C2.83727 7.49292 3.00364 8.15816 3.32201 8.75403C3.64038 9.3499 4.10091 9.85797 4.66273 10.2332C4.01117 10.2124 3.37397 10.0364 2.80419 9.71965V9.7725C2.80413 10.72 3.13189 11.6384 3.73186 12.3718C4.33184 13.1052 5.16707 13.6084 6.09583 13.7961C5.4914 13.9597 4.85769 13.9838 4.24258 13.8666C4.50462 14.6819 5.01506 15.3948 5.70243 15.9056C6.3898 16.4164 7.21969 16.6995 8.07593 16.7152C6.62242 17.8562 4.82735 18.4751 2.97948 18.4724C2.65215 18.4725 2.32509 18.4534 2 18.4151C3.87569 19.6212 6.05914 20.2612 8.28909 20.2587C15.8378 20.2587 19.9644 14.0066 19.9644 8.58427C19.9644 8.4081 19.96 8.23018 19.9521 8.05401C20.7548 7.47352 21.4476 6.7547 21.9982 5.93122L22 5.92858Z"
      />
    </SvgIcon>
  );
});

export default Twitter;

import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import config from '../config';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { sha256 } from 'crypto-hash';

const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

const httpLink = createHttpLink({
  uri: config.userPortal,
  credentials: 'same-origin',
});

const link = ApolloLink.from([persistedQueriesLink, httpLink]);

export const apolloClientUserPortal = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

import * as React from 'react';

const DevportalIcon: React.FC = React.memo(() => {
  return (
    <svg width="138" height="41" viewBox="0 0 138 41">
      <path
        d="M2.3802 17.55C1.74607 17.55 1.25887 17.3798 0.918603 17.0396C0.578336 16.6993 0.408203 16.2121 0.408203 15.578V3.16597C0.408203 2.53184 0.578336 2.04464 0.918603 1.70437C1.25887 1.3641 1.74607 1.19397 2.3802 1.19397H7.0666C8.8762 1.19397 10.4306 1.5265 11.7298 2.19157C13.0445 2.84117 14.0498 3.7769 14.7458 4.99877C15.4418 6.22064 15.7898 7.6745 15.7898 9.36037C15.7898 11.0617 15.4418 12.531 14.7458 13.7684C14.0653 14.9902 13.0677 15.926 11.753 16.5756C10.4538 17.2252 8.89167 17.55 7.0666 17.55H2.3802ZM6.765 14.1164C8.35807 14.1164 9.549 13.7297 10.3378 12.9564C11.1421 12.1676 11.5442 10.9689 11.5442 9.36037C11.5442 7.75184 11.1421 6.5609 10.3378 5.78757C9.549 5.01424 8.35807 4.62757 6.765 4.62757H4.677V14.1164H6.765Z"
        fill="#EFF0F7"
      />
      <path
        d="M20.324 17.55C19.6898 17.55 19.2026 17.3798 18.8624 17.0396C18.5221 16.6993 18.352 16.2121 18.352 15.578V3.16597C18.352 2.53184 18.5221 2.04464 18.8624 1.70437C19.2026 1.3641 19.6898 1.19397 20.324 1.19397H28.3512C29.0162 1.19397 29.5189 1.33317 29.8592 1.61157C30.1994 1.88997 30.3696 2.2921 30.3696 2.81797C30.3696 3.93157 29.6968 4.48837 28.3512 4.48837H22.5048V7.59717H27.8872C29.2328 7.59717 29.9056 8.14624 29.9056 9.24437C29.9056 9.77024 29.7354 10.1724 29.3952 10.4508C29.0549 10.7292 28.5522 10.8684 27.8872 10.8684H22.5048V14.2556H28.3512C29.6968 14.2556 30.3696 14.8124 30.3696 15.926C30.3696 16.4518 30.1994 16.854 29.8592 17.1324C29.5189 17.4108 29.0162 17.55 28.3512 17.55H20.324Z"
        fill="#EFF0F7"
      />
      <path
        d="M44.1154 2.19157C44.2855 1.82037 44.5252 1.54197 44.8346 1.35637C45.1439 1.17077 45.4842 1.07797 45.8554 1.07797C46.4122 1.07797 46.9148 1.25584 47.3634 1.61157C47.8119 1.9673 48.0362 2.41584 48.0362 2.95717C48.0362 3.2201 47.9666 3.49077 47.8274 3.76917L41.7026 16.4132C41.5015 16.8308 41.1999 17.1556 40.7978 17.3876C40.3956 17.6041 39.9626 17.7124 39.4986 17.7124C39.0346 17.7124 38.6015 17.6041 38.1994 17.3876C37.7972 17.1556 37.4956 16.8308 37.2946 16.4132L31.1698 3.76917C31.046 3.5217 30.9842 3.25104 30.9842 2.95717C30.9842 2.4313 31.2162 1.9905 31.6802 1.63477C32.1442 1.26357 32.6623 1.07797 33.2346 1.07797C33.6058 1.07797 33.946 1.17077 34.2554 1.35637C34.5802 1.54197 34.8276 1.82037 34.9978 2.19157L39.5682 12.0748L44.1154 2.19157Z"
        fill="#EFF0F7"
      />
      <path
        d="M51.5896 17.55C50.9554 17.55 50.4682 17.3798 50.128 17.0396C49.7877 16.6993 49.6176 16.2121 49.6176 15.578V3.16597C49.6176 2.53184 49.7877 2.04464 50.128 1.70437C50.4682 1.3641 50.9554 1.19397 51.5896 1.19397H59.6168C60.2819 1.19397 60.7845 1.33317 61.1248 1.61157C61.465 1.88997 61.6352 2.2921 61.6352 2.81797C61.6352 3.93157 60.9624 4.48837 59.6168 4.48837H53.7704V7.59717H59.1528C60.4984 7.59717 61.1712 8.14624 61.1712 9.24437C61.1712 9.77024 61.001 10.1724 60.6608 10.4508C60.3205 10.7292 59.8178 10.8684 59.1528 10.8684H53.7704V14.2556H59.6168C60.9624 14.2556 61.6352 14.8124 61.6352 15.926C61.6352 16.4518 61.465 16.854 61.1248 17.1324C60.7845 17.4108 60.2819 17.55 59.6168 17.55H51.5896Z"
        fill="#EFF0F7"
      />
      <path
        d="M65.4325 17.55C64.7984 17.55 64.3112 17.3798 63.971 17.0396C63.6307 16.6993 63.4605 16.2044 63.4605 15.5548V3.16597C63.4605 2.53184 63.6462 2.02917 64.0174 1.65797C64.404 1.2713 64.9221 1.07797 65.5718 1.07797C66.2368 1.07797 66.7627 1.2713 67.1494 1.65797C67.536 2.02917 67.7293 2.53184 67.7293 3.16597V14.0932H73.0654C74.4109 14.0932 75.0838 14.6732 75.0838 15.8332C75.0838 16.4209 74.9136 16.854 74.5733 17.1324C74.2486 17.4108 73.7459 17.55 73.0654 17.55H65.4325Z"
        fill="#EFF0F7"
      />
      <path
        d="M83.5575 17.7588C81.8872 17.7588 80.4333 17.4185 79.196 16.738C77.9741 16.042 77.0306 15.0676 76.3656 13.8148C75.7005 12.5465 75.368 11.0617 75.368 9.36037C75.368 7.65904 75.7005 6.18197 76.3656 4.92917C77.0306 3.6609 77.9741 2.6865 79.196 2.00597C80.4333 1.32544 81.8872 0.985168 83.5575 0.985168C85.228 0.985168 86.6741 1.32544 87.896 2.00597C89.1333 2.6865 90.0768 3.6609 90.7263 4.92917C91.3914 6.18197 91.724 7.65904 91.724 9.36037C91.724 11.0617 91.3914 12.5465 90.7263 13.8148C90.0613 15.0676 89.1101 16.042 87.8728 16.738C86.6509 17.4185 85.2125 17.7588 83.5575 17.7588ZM83.5575 14.4876C84.7949 14.4876 85.7616 14.0545 86.4576 13.1884C87.1536 12.3068 87.5015 11.0308 87.5015 9.36037C87.5015 7.68997 87.1458 6.4217 86.4343 5.55557C85.7383 4.68944 84.7794 4.25637 83.5575 4.25637C82.3202 4.25637 81.3536 4.68944 80.6575 5.55557C79.9615 6.40624 79.6136 7.6745 79.6136 9.36037C79.6136 11.0462 79.9615 12.3222 80.6575 13.1884C81.3536 14.0545 82.3202 14.4876 83.5575 14.4876Z"
        fill="#EFF0F7"
      />
      <path
        d="M96.3616 17.6892C95.712 17.6892 95.1939 17.5036 94.8072 17.1324C94.436 16.7457 94.2504 16.2276 94.2504 15.578V3.16597C94.2504 2.53184 94.4205 2.04464 94.7608 1.70437C95.1011 1.3641 95.5883 1.19397 96.2224 1.19397H102.162C103.971 1.19397 105.379 1.65797 106.384 2.58597C107.389 3.4985 107.892 4.7745 107.892 6.41397C107.892 8.03797 107.382 9.31397 106.361 10.242C105.355 11.17 103.956 11.634 102.162 11.634H98.5192V15.578C98.5192 16.2276 98.3259 16.7457 97.9392 17.1324C97.5525 17.5036 97.0267 17.6892 96.3616 17.6892ZM101.582 8.43237C102.37 8.43237 102.95 8.26997 103.322 7.94517C103.708 7.62037 103.902 7.1177 103.902 6.43717C103.902 5.09157 103.128 4.41877 101.582 4.41877H98.5192V8.43237H101.582Z"
        fill="#EFF0F7"
      />
      <path
        d="M111.923 17.55C111.289 17.55 110.802 17.3798 110.462 17.0396C110.121 16.6993 109.951 16.2121 109.951 15.578V3.16597C109.951 2.53184 110.121 2.04464 110.462 1.70437C110.802 1.3641 111.289 1.19397 111.923 1.19397H119.95C120.615 1.19397 121.118 1.33317 121.458 1.61157C121.799 1.88997 121.969 2.2921 121.969 2.81797C121.969 3.93157 121.296 4.48837 119.95 4.48837H114.104V7.59717H119.486C120.832 7.59717 121.505 8.14624 121.505 9.24437C121.505 9.77024 121.335 10.1724 120.994 10.4508C120.654 10.7292 120.151 10.8684 119.486 10.8684H114.104V14.2556H119.95C121.296 14.2556 121.969 14.8124 121.969 15.926C121.969 16.4518 121.799 16.854 121.458 17.1324C121.118 17.4108 120.615 17.55 119.95 17.55H111.923Z"
        fill="#EFF0F7"
      />
      <path
        d="M137.552 14.8356C137.83 15.2532 137.969 15.6476 137.969 16.0188C137.969 16.5137 137.761 16.9313 137.343 17.2716C136.941 17.5964 136.469 17.7588 135.928 17.7588C135.557 17.7588 135.209 17.6737 134.884 17.5036C134.559 17.3334 134.281 17.0782 134.049 16.738L131.033 12.33C130.816 12.0206 130.584 11.7964 130.337 11.6572C130.105 11.5025 129.826 11.4252 129.501 11.4252H127.993V15.578C127.993 16.2276 127.808 16.7457 127.437 17.1324C127.065 17.5036 126.555 17.6892 125.905 17.6892C125.256 17.6892 124.738 17.5036 124.351 17.1324C123.98 16.7457 123.794 16.2276 123.794 15.578V3.16597C123.794 2.53184 123.964 2.04464 124.305 1.70437C124.645 1.3641 125.132 1.19397 125.766 1.19397H131.589C133.569 1.19397 135.054 1.62704 136.044 2.49317C137.049 3.34384 137.552 4.58117 137.552 6.20517C137.552 7.4425 137.211 8.49424 136.531 9.36037C135.85 10.211 134.891 10.7833 133.654 11.0772C134.165 11.1545 134.605 11.3246 134.977 11.5876C135.363 11.8505 135.742 12.2449 136.113 12.7708L137.552 14.8356ZM130.917 8.22357C131.86 8.22357 132.533 8.07664 132.935 7.78277C133.353 7.4889 133.561 7.00944 133.561 6.34437C133.561 5.66384 133.353 5.17664 132.935 4.88277C132.533 4.57344 131.86 4.41877 130.917 4.41877H127.947V8.22357H130.917Z"
        fill="#EFF0F7"
      />
      <path
        d="M2.1714 40.8892C1.72287 40.8892 1.3594 40.7577 1.081 40.4948C0.81807 40.2164 0.686603 39.8452 0.686603 39.3812V25.8092C0.686603 25.3606 0.810337 25.0126 1.0578 24.7652C1.32074 24.5177 1.67647 24.394 2.125 24.394H7.8322C9.57994 24.394 10.941 24.8348 11.9154 25.7164C12.8898 26.5825 13.377 27.8044 13.377 29.382C13.377 30.9596 12.8898 32.1892 11.9154 33.0708C10.941 33.9524 9.57994 34.3932 7.8322 34.3932H3.6794V39.3812C3.6794 39.8452 3.5402 40.2164 3.2618 40.4948C2.99887 40.7577 2.6354 40.8892 2.1714 40.8892ZM7.461 32.0964C9.51807 32.0964 10.5466 31.1993 10.5466 29.4052C10.5466 27.611 9.51807 26.714 7.461 26.714H3.6794V32.0964H7.461Z"
        fill="#EFF0F7"
      />
      <path
        d="M20.5128 40.9356C19.3528 40.9356 18.332 40.6958 17.4504 40.2164C16.5843 39.7369 15.9115 39.0486 15.432 38.1516C14.9525 37.2545 14.7128 36.2105 14.7128 35.0196C14.7128 33.8286 14.9525 32.7924 15.432 31.9108C15.9115 31.0137 16.5843 30.3254 17.4504 29.846C18.332 29.3665 19.3528 29.1268 20.5128 29.1268C21.6728 29.1268 22.6859 29.3665 23.552 29.846C24.4336 30.3254 25.1064 31.0137 25.5704 31.9108C26.0499 32.7924 26.2896 33.8286 26.2896 35.0196C26.2896 36.2105 26.0499 37.2545 25.5704 38.1516C25.1064 39.0486 24.4336 39.7369 23.552 40.2164C22.6859 40.6958 21.6728 40.9356 20.5128 40.9356ZM20.4896 38.6852C21.4331 38.6852 22.1523 38.3758 22.6472 37.7572C23.1421 37.1385 23.3896 36.226 23.3896 35.0196C23.3896 33.8286 23.1421 32.9238 22.6472 32.3052C22.1523 31.671 21.4408 31.354 20.5128 31.354C19.5848 31.354 18.8656 31.671 18.3552 32.3052C17.8603 32.9238 17.6128 33.8286 17.6128 35.0196C17.6128 36.226 17.8603 37.1385 18.3552 37.7572C18.8501 38.3758 19.5616 38.6852 20.4896 38.6852Z"
        fill="#EFF0F7"
      />
      <path
        d="M35.0173 29.1732C35.4195 29.1422 35.7365 29.2273 35.9685 29.4284C36.2005 29.6294 36.3165 29.931 36.3165 30.3332C36.3165 30.7508 36.216 31.0601 36.0149 31.2612C35.8139 31.4622 35.4504 31.5937 34.9245 31.6556L34.2285 31.7252C33.316 31.818 32.6432 32.1273 32.2101 32.6532C31.7925 33.179 31.5837 33.8364 31.5837 34.6252V39.4972C31.5837 39.9457 31.4445 40.2937 31.1661 40.5412C30.8877 40.7732 30.5397 40.8892 30.1221 40.8892C29.7045 40.8892 29.3565 40.7732 29.0781 40.5412C28.8152 40.2937 28.6837 39.9457 28.6837 39.4972V30.5188C28.6837 30.0857 28.8152 29.7532 29.0781 29.5212C29.3565 29.2892 29.6968 29.1732 30.0989 29.1732C30.5011 29.1732 30.8259 29.2892 31.0733 29.5212C31.3208 29.7377 31.4445 30.0548 31.4445 30.4724V31.4004C31.7384 30.7198 32.1715 30.194 32.7437 29.8228C33.3315 29.4516 33.9811 29.2428 34.6925 29.1964L35.0173 29.1732Z"
        fill="#EFF0F7"
      />
      <path
        d="M44.5 38.7316C45.2733 38.778 45.66 39.126 45.66 39.7756C45.66 40.1468 45.5053 40.4329 45.196 40.634C44.9021 40.8196 44.4768 40.8969 43.92 40.866L43.2936 40.8196C40.6952 40.634 39.396 39.242 39.396 36.6436V31.586H38.236C37.8184 31.586 37.4936 31.4932 37.2616 31.3076C37.0451 31.122 36.9368 30.8513 36.9368 30.4956C36.9368 30.1398 37.0451 29.8692 37.2616 29.6836C37.4936 29.498 37.8184 29.4052 38.236 29.4052H39.396V27.2708C39.396 26.8532 39.5275 26.5206 39.7904 26.2732C40.0533 26.0257 40.409 25.902 40.8576 25.902C41.2906 25.902 41.6387 26.0257 41.9016 26.2732C42.1645 26.5206 42.296 26.8532 42.296 27.2708V29.4052H44.268C44.6856 29.4052 45.0027 29.498 45.2192 29.6836C45.4512 29.8692 45.5672 30.1398 45.5672 30.4956C45.5672 30.8513 45.4512 31.122 45.2192 31.3076C45.0027 31.4932 44.6856 31.586 44.268 31.586H42.296V36.8524C42.296 37.9969 42.8219 38.6078 43.8736 38.6852L44.5 38.7316Z"
        fill="#EFF0F7"
      />
      <path
        d="M52.0896 29.1268C53.6673 29.1268 54.835 29.5212 55.5928 30.31C56.3662 31.0833 56.7528 32.2665 56.7528 33.8596V39.5204C56.7528 39.938 56.6291 40.2705 56.3816 40.518C56.1342 40.75 55.7939 40.866 55.3609 40.866C54.9587 40.866 54.6262 40.7422 54.3633 40.4948C54.1158 40.2473 53.9921 39.9225 53.9921 39.5204V39.01C53.7291 39.6132 53.3115 40.0849 52.7393 40.4252C52.1825 40.7654 51.5328 40.9356 50.7905 40.9356C50.0326 40.9356 49.3443 40.7809 48.7257 40.4716C48.107 40.1622 47.6198 39.7369 47.264 39.1956C46.9083 38.6542 46.7304 38.051 46.7304 37.386C46.7304 36.5508 46.9393 35.8934 47.3568 35.414C47.7899 34.9345 48.4859 34.5865 49.4449 34.37C50.4038 34.1534 51.7262 34.0452 53.4121 34.0452H53.9921V33.5116C53.9921 32.7537 53.8297 32.2046 53.5049 31.8644C53.1801 31.5086 52.6542 31.3308 51.9272 31.3308C51.4787 31.3308 51.0224 31.4004 50.5584 31.5396C50.0945 31.6633 49.5454 31.8489 48.9113 32.0964C48.5091 32.2974 48.2153 32.398 48.0297 32.398C47.7513 32.398 47.5193 32.2974 47.3337 32.0964C47.1635 31.8953 47.0785 31.6324 47.0785 31.3076C47.0785 31.0446 47.1403 30.8204 47.264 30.6348C47.4033 30.4337 47.6275 30.2481 47.9369 30.078C48.4782 29.7841 49.1201 29.5521 49.8624 29.382C50.6203 29.2118 51.3627 29.1268 52.0896 29.1268ZM51.3705 38.8476C52.1438 38.8476 52.7702 38.5924 53.2497 38.082C53.7446 37.5561 53.9921 36.8833 53.9921 36.0636V35.5764H53.5745C52.5382 35.5764 51.7339 35.6228 51.1617 35.7156C50.5894 35.8084 50.1795 35.9708 49.9321 36.2028C49.6846 36.4348 49.5609 36.7518 49.5609 37.154C49.5609 37.6489 49.731 38.0588 50.0713 38.3836C50.427 38.6929 50.8601 38.8476 51.3705 38.8476Z"
        fill="#EFF0F7"
      />
      <path
        d="M61.1386 40.8892C60.721 40.8892 60.373 40.7732 60.0946 40.5412C59.8316 40.2937 59.7002 39.9457 59.7002 39.4972V25.6468C59.7002 25.1982 59.8316 24.858 60.0946 24.626C60.373 24.394 60.721 24.278 61.1386 24.278C61.5562 24.278 61.9042 24.394 62.1826 24.626C62.461 24.858 62.6002 25.1982 62.6002 25.6468V39.4972C62.6002 39.9457 62.461 40.2937 62.1826 40.5412C61.9042 40.7732 61.5562 40.8892 61.1386 40.8892Z"
        fill="#EFF0F7"
      />
    </svg>
  );
});

export default DevportalIcon;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import Cropper from 'cropperjs';
import { imageFileAsDataUrl, basename } from '../../file';
import defer, { DeferredPromise } from '../../defer';

const StyledCropContainer = styled('div')(({ theme: { spacing } }) => ({
  maxWidth: '100%',
  marginLeft: spacing(3),
  marginRight: spacing(3),
}));

const StyledCropImage = styled('img')(() => ({
  maxWidth: 800,
  maxHeight: 600,
}));

export interface Ratio {
  width: number;
  height: number;
}

export interface CzyCropperProps {
  file: File;
  ratio: Ratio;
  onCrop: (file: File) => void;
  minWidth?: number;
  minHeight?: number;
  isNonEditable?: boolean;
}

class CzyCropper extends React.Component<CzyCropperProps> {
  private editorRef: React.RefObject<HTMLImageElement>;

  private cropper: DeferredPromise<Cropper>;

  constructor(props: CzyCropperProps) {
    super(props);
    this.cropper = defer<Cropper>();
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    const img = this.editorRef.current;
    if (!img) {
      throw new Error(`[CzyCropper] img ref is undefined`);
    }
    this.initializeCropper(img);
  }

  render() {
    return (
      <>
        <StyledCropContainer>
          <StyledCropImage ref={this.editorRef} alt="cropper" />
        </StyledCropContainer>
        {/* spacer */}
        <div style={{ height: '30px' }} />
        <Grid container={true} justifyContent="center">
          <Grid item={true}>
            <Button variant="contained" color="primary" onClick={this.onCrop} disabled={this.props.isNonEditable}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  private async initializeCropper(img: HTMLImageElement) {
    const { file, ratio, minWidth, minHeight } = this.props;
    const b64Img = await imageFileAsDataUrl(file);
    img.src = b64Img;
    const cropper = new Cropper(img, {
      aspectRatio: ratio.width / ratio.height,
      viewMode: 1, //  restrict the crop box to not exceed the size of the canvas.
      crop: (event) => {
        const { width, height } = event.detail;
        const newWidth = minWidth === undefined ? width : Math.max(width, minWidth);
        const newHeight = minHeight === undefined ? height : Math.max(height, minHeight);
        if (newWidth !== width && newHeight !== height) {
          cropper.setData({
            width: newWidth,
            height: newHeight,
          });
        }
      },
    });
    this.cropper.resolve(cropper);
  }

  private onCrop = async () => {
    const file = await this.getCroppedFile();
    this.props.onCrop(file);
  };

  private async getCroppedBlob(): Promise<Blob> {
    const cropper = await this.cropper.promise;
    return new Promise<Blob>((resolve, reject) => {
      const croppedCanvas = cropper.getCroppedCanvas({});

      if (!croppedCanvas) {
        reject('Could not get cropped canvas');
      }

      croppedCanvas.toBlob((blob) => {
        if (!blob) {
          reject('Could not blob-ify cropped canvas');
        } else {
          resolve(blob);
        }
      }, 'image/png');
    });
  }

  private async getCroppedFile(): Promise<File> {
    const { file, ratio } = this.props;
    const fileName = `${basename(file.name)}_${ratio.width}x${ratio.height}.png`;
    const blob = await this.getCroppedBlob();
    return new File([blob], fileName);
  }
}

export default CzyCropper;

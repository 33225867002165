import React from 'react';
import { PREVIEW_QUERY, PreviewQuery } from './preview-graphql';
import { SubmissionPreviewUrlProvider } from './PreviewUrlProvider';
import { useQuery } from '@apollo/client';
import GraphQLLoader from '../../../../common/graphql/graphql-loader';
import QATool from '../UserDeveloper/QATool/QATool';
import { PreviewOptions } from './PreviewRoute';

interface PreviewLoaderInput {
  submissionId: string;
  previewOptions: PreviewOptions;
}

const SubmissionPreviewLoader: React.FC<PreviewLoaderInput> = ({ submissionId, previewOptions }) => {
  const { loading, error, data } = useQuery<PreviewQuery>(PREVIEW_QUERY, {
    variables: { id: submissionId },
    fetchPolicy: 'network-only',
  });

  return (
    <GraphQLLoader loading={loading} error={error}>
      {!loading && !error && (
        <SubmissionPreviewUrlProvider
          submissionId={submissionId}
          minTimeForAds={previewOptions.minTimeForAds}
          noTestAds={previewOptions.noTestAds}
          previewComponent={(url) => (
            <QATool
              gameName={data!.me.submission.gameName}
              submission={data!.me.submission}
              previewUrl={url}
              previewOptions={previewOptions}
            />
          )}
        />
      )}
    </GraphQLLoader>
  );
};

export default SubmissionPreviewLoader;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Comments: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.5C5 8.35787 8.35786 5 12.5 5H37.5C41.6421 5 45 8.35786 45 12.5V15H47.5C51.6421 15 55 18.3579 55 22.5V37.5C55 41.6421 51.6421 45 47.5 45H45V52.5C45 53.5112 44.3909 54.4227 43.4567 54.8097C42.5225 55.1967 41.4472 54.9828 40.7322 54.2678L31.4645 45H22.5C21.3431 45 20.2461 44.737 19.2669 44.2686C18.5519 44.9829 17.4771 45.1965 16.5433 44.8097C15.6091 44.4227 15 43.5112 15 42.5V35H12.5C8.35786 35 5 31.6421 5 27.5V12.5ZM23.5355 40H32.5C33.163 40 33.7989 40.2634 34.2678 40.7322L40 46.4645V42.5C40 41.1193 41.1193 40 42.5 40H47.5C48.8807 40 50 38.8807 50 37.5V22.5C50 21.1193 48.8807 20 47.5 20H45V27.5C45 31.6421 41.6421 35 37.5 35H28.5355L23.5355 40ZM40 12.5C40 11.1193 38.8807 10 37.5 10H12.5C11.1193 10 10 11.1193 10 12.5V27.5C10 28.8807 11.1193 30 12.5 30H17.5C18.8807 30 20 31.1193 20 32.5V36.4645L25.7322 30.7322C26.2011 30.2634 26.837 30 27.5 30H37.5C38.8807 30 40 28.8807 40 27.5V12.5Z"
      />
    </SvgIcon>
  );
});

export default Comments;

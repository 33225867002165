export interface DeferredPromise<T = void> {
  resolve: (data: T) => void;
  reject: (err: {}) => void;
  promise: Promise<T>;
}

function defer<T>(): DeferredPromise<T> {
  const ret = {
    resolve: (data: T) => {
      return;
    },
    reject: (err: {}) => {
      return;
    },
    promise: null as any as Promise<T>,
  };
  ret.promise = new Promise<T>((resolve, reject) => {
    ret.resolve = resolve;
    ret.reject = reject;
  });
  return ret;
}

export default defer;

import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';

const DELETE_SUBMISSION = gql`
  mutation deleteSubmission($input: DeveloperSubmissionDeleteInput!) {
    developerSubmissionDelete(input: $input) {
      id
    }
  }
`;

export interface UpdateSubmissionMutation {
  developerSubmissionDelete: DeleteSubmissionResult;
}

export interface DeleteSubmissionResult {}

export interface DeveloperSubmissionDeleteInput {
  id: string;
}

export function deleteSubmission(input: DeveloperSubmissionDeleteInput) {
  return mutate<DeveloperSubmissionDeleteInput, UpdateSubmissionMutation>(DELETE_SUBMISSION, input);
}

import { prefixGraphUrl } from './util';
import { SessionContextData } from './Session/SessionContext';
import { Unity56Encoding } from '../components/App/Content/UserDeveloper/Submission/GameBaseForm/UnityLoaderOptions/UnityCompressionSelect';
import { GameLoaderType } from './domain/game';
import { UploadFile } from './DropZone/UploadDropZone';
import pako from 'pako';
import brotliPromise from 'brotli-wasm';

const FILE_ENDPOINT = prefixGraphUrl('upload');
const PRE_SIGNED_URL_ENDPOINT = prefixGraphUrl('upload/request-presigned-url');
const CONFIRM_PRE_SIGNED_URL_ENDPOINT = prefixGraphUrl('upload/check-presigned-url-upload');
const STANDALONE_COPY_ENDPOINT = prefixGraphUrl('upload/standalone-copy');

export interface PostDataReponse {
  field: string;
  fileName: string;
  size: number;
  uploadId: string;
}

export interface CopyToStandaloneResponse {
  standaloneId: string;
}

export type PresignedUrlResponse = { path: string; presignedUrl: string; type?: string; encoding?: string }[];

export async function uploadFile(file: File, session: SessionContextData, fileName?: string): Promise<PostDataReponse[]> {
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload] not authenticated');
  }
  const token = await session.getUser().firebaseUser.getIdToken();
  const formData = new FormData();
  formData.append('file', file);
  const endpoint = new URL(FILE_ENDPOINT);
  if (fileName) {
    endpoint.searchParams.set('fileName', fileName);
  }
  const resp = await fetch(endpoint.toString(), {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (resp.status === 200) {
    return resp.json();
  } else {
    return resp.text().then((str) => {
      console.error(str);
      return Promise.reject(str);
    });
  }
}

export async function getPresignedUrls(files: UploadFile[], session: SessionContextData): Promise<PresignedUrlResponse> {
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload] not authenticated');
  }
  const token = await session.getUser().firebaseUser.getIdToken();
  const resp = await fetch(PRE_SIGNED_URL_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(files.map((file) => ({ path: file.path, name: file.blob?.name }))),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (resp.status === 200) {
    return resp.json();
  } else {
    return resp.text().then((str) => {
      console.error(str);
      return Promise.reject(str);
    });
  }
}

export async function uploadWithPresignedUrl(file: UploadFile, session: SessionContextData): Promise<PostDataReponse> {
  // We're sending the file directly to s3 with the presigned url, then we call the confirm endpoint.
  // Confirm endpoint will check if the file was uploaded correctly and will save the file in the upload table.
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload] not authenticated');
  }
  if (!file.blob) {
    return Promise.reject('No file blob found');
  }
  if (!file.presigned) {
    return Promise.reject('No presigned url found');
  }
  const { url, type, encoding } = file.presigned;

  const [presignedResp, uncompressedSize] = await Promise.all([
    fetch(url, {
      method: 'PUT',
      body: file.blob,
      headers: {
        ...(type ? { 'Content-Type': type } : {}),
        ...(encoding ? { 'Content-Encoding': encoding } : {}),
      },
    }),
    getUncompressedSize(file.blob, file.presigned.encoding),
  ]);
  if (!presignedResp.ok) {
    return presignedResp.text().then((str) => {
      console.error(str);
      return Promise.reject(str);
    });
  }
  const token = await session.getUser().firebaseUser.getIdToken();
  const confirmResp = await fetch(CONFIRM_PRE_SIGNED_URL_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      filePath: file.path,
      size: file.blob.size,
      uncompressedSize,
      presignedUrl: url,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!confirmResp.ok) {
    return confirmResp.text().then((str) => {
      console.error(str);
      return Promise.reject(str);
    });
  }
  return confirmResp.json();
}

export async function copyToStandalone(
  fileIds: string[],
  session: SessionContextData,
  gameLoaderType: GameLoaderType,
  unityEncoding?: Unity56Encoding,
): Promise<CopyToStandaloneResponse> {
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload/standalone-copy] not authenticated');
  }
  const endpoint = new URL(STANDALONE_COPY_ENDPOINT);
  const token = await session.getUser().firebaseUser.getIdToken();

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Accept', 'application/json');

  const resp = await fetch(endpoint.toString(), {
    method: 'POST',
    body: JSON.stringify({
      fileIds,
      gameLoaderType,
      unityEncoding,
    }),
    headers: myHeaders,
  });
  if (resp.status === 200) {
    return resp.json();
  } else {
    return resp.text().then((str) => {
      throw new Error(str);
    });
  }
}

async function getUncompressedSize(file: File, compressionType?: string): Promise<number> {
  if (!compressionType) {
    // compressionType can be detected from the file itself, it's returned by the server on presigned request
    // Though it doesn't work for gzip (because gzip .unitweb file don't have gzip extension) so to be sure we check using magic numbers detection in file content
    compressionType = await detectCompressionType(file);
  }
  switch (compressionType) {
    case 'gzip':
      return handleGzipFile(file);
    case 'br':
      return handleBrotliFile(file);
    default:
      return file.size;
  }
}

function handleGzipFile(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const compressedData = new Uint8Array(event.target?.result as ArrayBuffer);
        const uncompressedData = pako.ungzip(compressedData);
        resolve(uncompressedData.length);
      } catch (error) {
        reject(error);
      }
    };
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });
}

async function handleBrotliFile(file: File): Promise<number> {
  const arrayBuffer = await file.arrayBuffer();
  const compressedData = new Uint8Array(arrayBuffer);
  const brotliDecompress = await brotliPromise;
  const uncompressedData = brotliDecompress.decompress(compressedData);
  return uncompressedData.length;
}

function detectCompressionType(file: File): Promise<'gzip' | 'br' | 'unknown'> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (event.target && event.target.result) {
        const uint8Array = new Uint8Array(event.target.result as ArrayBuffer);
        // Check the magic numbers for gzip and brotli
        if (uint8Array[0] === 0x1f && uint8Array[1] === 0x8b) {
          resolve('gzip');
        } else if (uint8Array[0] === 0xce && uint8Array[1] === 0xb2) {
          resolve('br');
        } else {
          resolve('unknown');
        }
      }
    };
    fileReader.readAsArrayBuffer(file.slice(0, 2));
  });
}

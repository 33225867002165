import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { sdkAdsFeatures, SDKYesNoFeatures } from './Requirements/SDKRequirements';
import PopupManager, { Popups } from './Popup/PopupManager';
import {
  getDetectedSDKFeatures,
  getFailedSDKFeatures,
  getMissingSDKFeatures,
  getUnfulfilledMainRequirements,
} from './Requirements/RequirementsUtils';
import { QAToolContext } from '../QAToolProvider';
import { useHistory } from 'react-router-dom';
import { submissionRoutePath } from '../../Submission/SubmissionRoute';
import ActionLink, { ActionLinkButton } from '../../../../Footer/ActionLink';
import { PreviewOptions, QANextStep } from '../../../Preview/PreviewRoute';
import { updateSubmissionAddQAResultAndNote } from '../../Submission/Mutations/update-submission-qa-result-mutation';
import { toast } from 'react-toastify';
import {
  updateSubmissionStatusForReapproval,
  updateSubmissionStatusToSync,
} from '../../Submission/Mutations/update-submission-status-mutation';
import { PreviewSubmission } from '../../../Preview/preview-graphql';

function getNextStep(currentPopup: Popups): Popups {
  switch (currentPopup) {
    case 'mainRequirementNotFulfilled':
      return 'noSDK';
    case 'noSDK':
      return 'sdkFeaturesFailed';
    case 'sdkFeaturesFailed':
      return 'sdkFeaturesNotDetected';
    case 'sdkFeaturesNotDetected':
      return 'submissionInfoInput';
    case 'quitQaTool':
      return 'quitQaTool';
    case 'submissionInfoInput':
      return null;
    default:
      return 'mainRequirementNotFulfilled';
  }
}

interface IsProcessFinishedOptions {
  unfulfilledMainRequirements: { key: string; name: string }[];
  detectedSDKFeatures: { key: string; name: string }[];
  failedSDKFeatures: { key: string; name: string }[];
  missingSDKFeatures: { key: string; name: string }[];
  isDraft: boolean;
}

function getIsProcessFinished(currentPopup: Popups, options: IsProcessFinishedOptions): { isFinished: boolean; nextPopUp?: Popups } {
  const hasAds = sdkAdsFeatures.some((sdkAdFeature: SDKYesNoFeatures) => {
    return options.detectedSDKFeatures.some((feature) => feature.key === sdkAdFeature);
  });

  let currentStep = getNextStep(currentPopup);
  if (currentStep === 'mainRequirementNotFulfilled') {
    if (options.unfulfilledMainRequirements.length > 0) {
      return { isFinished: false, nextPopUp: 'mainRequirementNotFulfilled' };
    }
    currentStep = 'noSDK';
  }
  if (currentStep === 'noSDK') {
    if (options.detectedSDKFeatures.length === 0) {
      return { isFinished: false, nextPopUp: 'noSDK' };
    }
    currentStep = 'sdkFeaturesFailed';
  }
  if (currentStep === 'sdkFeaturesFailed') {
    if (options.failedSDKFeatures.length > 0) {
      return { isFinished: false, nextPopUp: 'sdkFeaturesFailed' };
    }
    currentStep = 'sdkFeaturesNotDetected';
  }
  if (currentStep === 'sdkFeaturesNotDetected') {
    if (options.missingSDKFeatures.length > 0 && options.detectedSDKFeatures.length > 0) {
      return { isFinished: false, nextPopUp: 'sdkFeaturesNotDetected' };
    }
    currentStep = 'submissionInfoInput';
  }
  if (currentStep === 'submissionInfoInput' && (!options.isDraft || hasAds)) {
    return { isFinished: false, nextPopUp: 'submissionInfoInput' };
  }
  return { isFinished: true };
}

interface SubmissionSubmitManagerProps {
  submission: PreviewSubmission;
  previewOptions: PreviewOptions;
}

const SubmissionSubmitManager: React.FC<SubmissionSubmitManagerProps> = ({ submission, previewOptions }) => {
  const [currentPopup, setCurrentPopup] = useState<Popups>(null);
  const history = useHistory();
  const { SDKInfo, sdkRequirements, loading, displaysCorrectly, sdkYesNoRequirements, updateNote, adScenario } =
    React.useContext(QAToolContext);
  const submissionId = submission.id;

  const handleSubmit = async () => {
    const detectedSDKFeatures = getDetectedSDKFeatures(sdkRequirements, sdkYesNoRequirements);
    const unfulfilledMainRequirements = getUnfulfilledMainRequirements(loading, displaysCorrectly);
    const failedSDKFeatures = getFailedSDKFeatures(sdkRequirements, sdkYesNoRequirements);
    const missingSDKFeatures = getMissingSDKFeatures(sdkRequirements, sdkYesNoRequirements);

    const isProcessFinished = getIsProcessFinished(currentPopup, {
      unfulfilledMainRequirements,
      detectedSDKFeatures,
      failedSDKFeatures,
      missingSDKFeatures,
      isDraft: previewOptions.draft,
    });

    if (!isProcessFinished.isFinished) {
      if (isProcessFinished.nextPopUp) {
        setCurrentPopup(isProcessFinished.nextPopUp);
      }
      return;
    }

    setCurrentPopup(null);
    const qaResult = {
      unfulfilledMainRequirements: unfulfilledMainRequirements.map((r) => r.key),
      SDKImplemented: detectedSDKFeatures.length > 0,
      SDKFeatures: detectedSDKFeatures.map((f) => f.key),
      failedSDKFeatures: failedSDKFeatures.map((f) => f.key),
      SDKInfo,
      adScenario,
    };
    await updateSubmissionAddQAResultAndNote({ id: submissionId, qaResult, updateNoteText: updateNote });
    const submissionLink = submissionRoutePath.replace(':id', submissionId);
    switch (previewOptions.nextStep) {
      case QANextStep.REAPPROVAL:
        await updateSubmissionStatusForReapproval({
          id: submissionId,
        });
        toast.success('Game successfully updated and sent for re-approval for a manual check on our side. This can take up to 48 hours.', {
          autoClose: 8000,
        });
        history.push({
          pathname: submissionLink,
          search: '?fromQA=true',
        });
        break;
      case QANextStep.SYNC:
        await updateSubmissionStatusToSync({
          id: submissionId,
        });
        toast.success('We have received your update. We will review and process it soon. This can take up to 48 hours.', {
          autoClose: 8000,
        });
        history.push({
          pathname: submissionLink,
          search: '?fromQA=true',
        });
        break;
      case QANextStep.UPDATE_COMPLETE:
        toast.success('Game successfully updated.');
        history.push({
          pathname: submissionLink,
          search: '?fromQA=true',
        });
        break;
    }
  };

  const getPrimaryButton = (): ActionLinkButton => {
    if (!previewOptions.nextStep) {
      const submissionLink = submissionRoutePath.replace(':id', submissionId);
      return {
        title: 'Quit Preview',
        callback: () => {
          history.push(submissionLink);
        },
      };
    }
    return {
      title: 'Continue',
      callback: handleSubmit,
    };
  };

  const getSecondaryButton = (): ActionLinkButton | undefined => {
    if (!previewOptions.nextStep) {
      return undefined;
    }
    return {
      title: 'Quit QA tool',
      callback: () => {
        setCurrentPopup('quitQaTool');
      },
    };
  };

  return (
    <Grid item sx={{ mb: 2, mt: 1 }}>
      <PopupManager
        submission={submission}
        currentPopup={currentPopup}
        nextStep={handleSubmit}
        isDraft={!!previewOptions.draft}
        close={() => {
          setCurrentPopup(null);
        }}
      />
      <ActionLink primaryButton={getPrimaryButton()} secondaryButton={getSecondaryButton()} />
    </Grid>
  );
};

export default SubmissionSubmitManager;

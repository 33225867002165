import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';
import { UploadedFileInput } from '../../../../../../common/graphql/upload-input';
import { SubmissionUnity56Encoding } from '../submission-graphql';
import { QAResult } from '../../QATool/components/SubmitManager';
import { GAME_ORIENTATION } from '../../../../../../common/CzyForm/OrientationSelector';
import { GameCoverWithUploadedFileInput } from '../../../../../../common/graphql/games/game';
import { SubmissionAPSDetail } from '../../SubmitGame/submit-game-graphql';
import { GameLoaderType } from '../../../../../../common/domain/game';

const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($input: DeveloperSubmissionUpdateInput!) {
    developerSubmissionUpdate(input: $input) {
      id
      slug
      filesChanged
      requiresDevQaCheck
      coverChanged
      iframeLinkChanged
      gameBuildId
      qaResult {
        unfulfilledMainRequirements
        SDKImplemented
        SDKFeatures
        failedSDKFeatures
      }
    }
  }
`;

export interface UpdateSubmissionMutation {
  developerSubmissionUpdate: UpdateSubmissionResult;
}

export interface UpdateSubmissionResult {
  id: string;
  slug: string;
  filesChanged?: boolean;
  coverChanged?: boolean;
  iframeLinkChanged?: boolean;
  qaResult?: QAResult;
  requiresDevQaCheck?: boolean;
  /** Some submission changes result in a new build being created, otherwise the latest build ID will be returned. */
  gameBuildId: string;
}

export interface DeveloperSubmissionUpdateInput {
  id: string;
  gameFiles: UploadedFileInput[];
  gameCovers?: GameCoverWithUploadedFileInput[];
  unity56Encoding?: SubmissionUnity56Encoding;

  gameName: string;
  categoryId?: string;
  tagIds?: string[];
  description?: string;
  controls?: string;

  iframeLink?: string;
  fullscreenable: boolean;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  orientation?: GAME_ORIENTATION;
  steamStoreLink?: string;
  appStoreLink?: string;
  playStoreLink?: string;
  playStoreDownloads?: number;
  appStoreDownloads?: number;
  steamDownloads?: number;
  hasIAP: boolean;
  isChromebookFriendly: boolean;

  gameLoaderType: GameLoaderType;
  apsDetail: SubmissionAPSDetail;
  isGameJam?: boolean;
}

export function updateSubmission(input: DeveloperSubmissionUpdateInput) {
  return mutate<DeveloperSubmissionUpdateInput, UpdateSubmissionMutation>(UPDATE_SUBMISSION, input);
}

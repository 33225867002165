export function getInputValue(ev: { target: { value: string } }) {
  return ev.target.value;
}

export type InputEvent = {
  target: {
    value: string;
  };
  currentTarget: {
    value: string;
  };
};

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const YouTube: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M21.4911 6.63154C21.409 6.30155 21.2483 5.99642 21.0229 5.74232C20.7974 5.48821 20.5138 5.29271 20.1964 5.17256C17.1429 3.99105 12.2679 4 12 4C11.7321 4 6.85714 3.99105 3.80357 5.17256C3.4862 5.29271 3.20262 5.48821 2.97713 5.74232C2.75165 5.99642 2.59099 6.30155 2.50893 6.63154C2.27679 7.51767 2 9.14672 2 11.8409C2 14.5351 2.27679 16.1641 2.50893 17.0503C2.59099 17.3803 2.75165 17.6854 2.97713 17.9395C3.20262 18.1936 3.4862 18.3891 3.80357 18.5093C6.73214 19.646 11.3214 19.6818 11.9375 19.6818H12.0625C12.6786 19.6818 17.2679 19.646 20.1964 18.5093C20.5138 18.3891 20.7974 18.1936 21.0229 17.9395C21.2483 17.6854 21.409 17.3803 21.4911 17.0503C21.7232 16.1641 22 14.5351 22 11.8409C22 9.14672 21.7232 7.51767 21.4911 6.63154ZM15.0536 12.1363L10.7679 15.0005C10.711 15.0425 10.642 15.0645 10.5714 15.0632C10.5123 15.061 10.4544 15.0457 10.4018 15.0184C10.3447 14.9887 10.297 14.9437 10.2639 14.8884C10.2308 14.8331 10.2136 14.7697 10.2143 14.7052V8.97665C10.2136 8.91217 10.2308 8.84876 10.2639 8.79346C10.297 8.73816 10.3447 8.69313 10.4018 8.66337C10.4587 8.63292 10.5228 8.61851 10.5873 8.62166C10.6517 8.62481 10.7141 8.64541 10.7679 8.68127L15.0536 11.5455C15.1034 11.577 15.1444 11.6206 15.1729 11.6722C15.2013 11.7239 15.2162 11.7819 15.2162 11.8409C15.2162 11.8999 15.2013 11.9579 15.1729 12.0096C15.1444 12.0613 15.1034 12.1048 15.0536 12.1363Z"
      />
    </SvgIcon>
  );
});

export default YouTube;

import React from 'react';
import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';
import { captureApolloError } from '../../sentry';

export interface ApolloErrorProps {
  error?: ApolloError | Error | null;
  children?: React.ReactNode;
}

export const GraphQLError: React.FC<ApolloErrorProps> = ({ children, error }) => {
  if (!error) {
    return null;
  } else if (error instanceof ApolloError) {
    captureApolloError(error);
    let errors: Readonly<Error[]> = [];
    if (error.graphQLErrors?.length > 0) {
      errors = error.graphQLErrors;
    } else if ((error.networkError as any)?.result?.errors?.length > 0) {
      errors = (error.networkError as any)?.result?.errors;
    }
    return (
      <div>
        <Typography variant="h6" color="error">
          {children}
        </Typography>
        <ul>
          {errors.length === 0 && <li>{error.message}</li>}
          {errors.length > 0 && errors.map((err: Error) => <li key={JSON.stringify(err)}>{err.message}</li>)}
        </ul>
      </div>
    );
  } else {
    if (error.message) {
      return <pre>{error.message}</pre>;
    } else {
      return <pre>{JSON.stringify(error, null, 2)}</pre>;
    }
  }
};

import { COLORS } from './Common/colors';
import { Grid, styled } from '@mui/material';

interface StyledContentHeaderContainerProps {}

export const StyledContentHeaderContainer = styled(Grid)<StyledContentHeaderContainerProps>(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.75, 4),
  justifyContent: 'space-between',
  boxShadow: `0px 1px 0px ${COLORS.black[60]}`,
}));

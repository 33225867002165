import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Ads: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.5 15H12.5C11.1193 15 10 16.1193 10 17.5V42.5C10 43.8807 11.1193 45 12.5 45H47.5C48.8807 45 50 43.8807 50 42.5V17.5C50 16.1193 48.8807 15 47.5 15ZM12.5 10C8.35786 10 5 13.3579 5 17.5V42.5C5 46.6421 8.35786 50 12.5 50H47.5C51.6421 50 55 46.6421 55 42.5V17.5C55 13.3579 51.6421 10 47.5 10H12.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 22.5C15 21.1193 16.1193 20 17.5 20H37.5C38.8807 20 40 21.1193 40 22.5V25C40 26.3807 38.8807 27.5 37.5 27.5H17.5C16.1193 27.5 15 26.3807 15 25V22.5Z"
      />
    </SvgIcon>
  );
});

export default Ads;

import { gql } from '@apollo/client';
import { SubmissionStatus } from '../../../../../common/domain/submission';
import { GAME_STATUS, GameCoverWithUploadedFile } from '../../../../../common/graphql/games/game';
import { TRANSLATED_ENTRY_FRAGMENT } from '../../../../../common/graphql/translatedEntry/translated-entry-graphql';
import { TranslatedEntry } from '../../../../../common/i18n';
import { Revenue } from '../../Admin/Developer/developer-games-graphql';

export interface Submission {
  gameName: string;
  gameStatus: GAME_STATUS;
  id: string;
  status: SubmissionStatus;
  feedback: Review[];
  createdAt: string;
  slug: string;
  gameCovers?: GameCoverWithUploadedFile[];
}

export interface Review {
  comment: string;
  postedOn: string;
  authorName: string;
}

export interface MySubmissionsQuery {
  me: {
    submissions: Submission[];
  };
}

export const SUBMISSIONS_FRAGMENT = gql`
  fragment SubmissionsFragment on Developer {
    submissions {
      id
      gameName
      gameStatus
      createdAt
      status
      slug
      gameCovers {
        size
        uploadedFile {
          uploadId
          path
          size
          temporaryUrl
        }
      }
      unitySaveFileNames
      apsDetail {
        progressType
      }
    }
  }
`;

export const MY_SUBMISSIONS_QUERY = gql`
  ${SUBMISSIONS_FRAGMENT}
  query {
    me {
      id
      ...SubmissionsFragment
    }
  }
`;

export interface DeveloperDailyRevenueStat {
  date: string;
  revenueShareDaily: Revenue; // Ordered from newest to oldest
}

export interface DeveloperGame {
  slug: string;
  id: string;
  name: TranslatedEntry[];
  status: GAME_STATUS;
  crazygamesUrl: string;
  devStats: DeveloperGameStatsForTimespan;
  dailyRevenueStats: DeveloperDailyRevenueStat[];
  coverUrl: string;
}

export interface DeveloperGameStatsAnalytics {
  pageviews: number;
  avgTimeOnPage: number;
  users: number;
  mobileUsers: number;
  rating: number;
  totalVotes: number;
  retentionD1: number;
  retentionD7: number;
  returningPlayers: number;
  avgConversion: number;
  avgLoadingTime: number;
  issues: number;
}

export interface DeveloperGameStatsForTimespan {
  from: string;
  until: string;
  revenueShare: Revenue;
  analytics: DeveloperGameStatsAnalytics;
}

export interface MyGamesStatsQuery {
  me: {
    games: DeveloperGame[];
  };
}

export const GAMES_STATS_FRAGMENT = gql`
  fragment GamesStatsFragment on Developer {
    games {
      id
      slug
      name {
        ...TranslatedEntryFragment
      }
      status
      coverUrl
      crazygamesUrl
      devStats(period: $period, options: $options) {
        from
        until
        revenueShare {
          total
        }
        analytics {
          pageviews
          users
          avgTimeOnPage
          rating
          totalVotes
          mobileUsers
          retentionD1
          retentionD7
          returningPlayers
          avgConversion
          avgLoadingTime
          issues
        }
      }
    }
  }
`;

export const MY_GAMES_STATS_QUERY = gql`
  ${GAMES_STATS_FRAGMENT}
  query gameStats($period: StatsForPeriod!, $options: StatsForPeriodOptionsInput) {
    me {
      id
      ...GamesStatsFragment
    }
  }
  ${TRANSLATED_ENTRY_FRAGMENT}
`;

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import withSession, { WithSession } from '../../../common/Session/withSession';

export type AuthenticatedRouteProps = RouteProps & WithSession;

class AuthenticatedRoute extends React.Component<AuthenticatedRouteProps> {
  render() {
    const { session } = this.props;
    const { component: Component, render, children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(route) => {
          if (!session.isLoggedIn()) {
            const savedRoute = window.location.pathname + window.location.search;
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    redirectRoute: savedRoute,
                  },
                }}
              />
            );
          }
          if (Component) {
            return <Component {...route} />;
          }
          if (render) {
            return render(route);
          }
          if (children) {
            return <>{children}</>;
          }
          throw new Error('[AuthenticatedRoute] expected either component render or children to be set');
        }}
      />
    );
  }
}

export default withSession<AuthenticatedRouteProps>(AuthenticatedRoute);

import React from 'react';

interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
  fullWidth?: boolean;
}

const Container: React.FC<ContainerProps> = ({ children, className, fullWidth }) => {
  const cssProps: React.CSSProperties = {};
  if (fullWidth) {
    cssProps.maxWidth = 'none';
  }
  return (
    <div className={`czy-container czy-clearfix ${className ? className : ''}`} style={cssProps}>
      {children}
    </div>
  );
};

export default Container;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const Delete: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6H18.9883C18.9953 5.99993 19.0024 5.99993 19.0095 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H19.9201L18.997 19.0778C18.8882 20.5542 17.6582 22 16 22H8C6.34175 22 5.11178 20.5542 5.00301 19.0778L4.07987 8H4C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6H4.99054C4.9976 5.99993 5.00466 5.99993 5.0117 6H8V4ZM6.0868 8L6.99753 18.9287C7.04142 19.5433 7.56213 20 8 20H16C16.4379 20 16.9586 19.5433 17.0025 18.9288L17.0034 18.9169L17.9132 8H6.0868ZM14 6H10V4H14V6ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
      />
    </SvgIcon>
  );
});

export default Delete;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const Warning: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1821 3.70783C9.18651 2.02952 11.7033 2.02952 12.7077 3.70783L18.7381 13.7842C19.7349 15.4498 18.4955 17.5509 16.4753 17.5509H4.41456C2.39432 17.5509 1.15491 15.4498 2.15175 13.7842L8.1821 3.70783ZM11.1973 4.53642C10.8616 3.97555 10.0282 3.97555 9.69254 4.53642L3.66219 14.6128C3.31895 15.1863 3.75464 15.8842 4.41456 15.8842H16.4753C17.1352 15.8842 17.5709 15.1863 17.2276 14.6128L11.1973 4.53642ZM10.4449 7.48727C10.9256 7.48727 11.3153 7.86036 11.3153 8.3206V9.99999C11.3153 10.4602 10.9256 10.8333 10.4449 10.8333C9.9642 10.8333 9.5745 10.4602 9.5745 9.99999V8.3206C9.5745 7.86036 9.9642 7.48727 10.4449 7.48727ZM9.5745 13.3588C9.5745 12.8985 9.9642 12.5254 10.4449 12.5254H10.4536C10.9343 12.5254 11.324 12.8985 11.324 13.3588C11.324 13.819 10.9343 14.1921 10.4536 14.1921H10.4449C9.9642 14.1921 9.5745 13.819 9.5745 13.3588Z"
      />
    </SvgIcon>
  );
});

export default Warning;

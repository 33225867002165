import { useTheme } from '@mui/system';
import * as React from 'react';
import { StyledHeaderText } from './Common/Text';
import { StyledContentHeaderContainer } from './contentHeader.styles';
import { Grid } from '@mui/material';

interface ContentHeaderProps {
  title: string;
  button?: React.ReactNode;
  children?: React.ReactNode;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ title, children, button }) => {
  const { spacing } = useTheme();
  return (
    <StyledContentHeaderContainer container>
      <Grid item sm={12} md="auto">
        <StyledHeaderText variant="h1" style={{ flex: 1, margin: 0, display: 'flex', alignItems: 'center' }}>
          {title}
          {button && <span style={{ marginLeft: spacing(), marginRight: spacing() }} />}
          {button && button}
        </StyledHeaderText>
      </Grid>
      <Grid item container sm={12} md="auto" sx={{ gap: 3 }}>
        {children}
      </Grid>
    </StyledContentHeaderContainer>
  );
};

export default ContentHeader;

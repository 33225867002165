import * as React from 'react';
import { convertPeriodToOption, Timespan, timespansPerMonth } from '../../datetime';
import { Grid, TextField, Tooltip } from '@mui/material';
import moment, { Moment } from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { CustomSelect } from '../../Styleguide/Common/Select';
import { Option } from '@mui/base';
import { StyledBodyText } from '../../Styleguide/Common/Text';
import { StyledDatePicker } from '../../Styleguide/Common/DatePicker';

const TIMESPAN_MONTHS_BACK = 6;

interface MenuItemLabelValue {
  value: StatsPeriod;
  label: string;
}

export type StatsPeriodValue = 'YESTERDAY' | 'LAST_7_DAYS' | 'LAST_30_DAYS' | 'LAST_6_MONTHS' | 'ALL_TIME' | 'CUSTOM' | 'PRESET';

export interface StatsPeriod {
  period: StatsPeriodValue;
  options?: StatsPeriodOptions;
  preDefinedTimeframe: boolean;
}

export interface StatsPeriodOptions {
  from: string;
  until: string;
}

export interface SelectTimeframeProps {
  initialPeriod: StatsPeriodValue;
  onChange: (period: StatsPeriod) => void;
  hideLabel?: boolean;
  hideTooltip?: boolean;
  maxDate?: Moment;
  minDate?: Moment;
}

interface SelectTimeframeState {
  period: StatsPeriod;
  openTimeframe: boolean;
  customPeriod: StatsPeriodOptions;
}

class SelectTimeframe extends React.Component<SelectTimeframeProps, SelectTimeframeState> {
  constructor(props: SelectTimeframeProps) {
    super(props);
    this.state = {
      period: {
        period: props.initialPeriod,
        options: convertPeriodToOption(props.initialPeriod),
        preDefinedTimeframe: true,
      },
      openTimeframe: false,
      customPeriod: convertPeriodToOption('LAST_7_DAYS'),
    };
  }

  render() {
    const customTimespans = timespansPerMonth(TIMESPAN_MONTHS_BACK).map((timespan) => {
      const { from, until } = timespan.toGraphInputTimespan();
      return {
        value: {
          period: 'PRESET',
          options: {
            from: from,
            until: until,
          },
          preDefinedTimeframe: true,
        },
        label: timespan.from.format('MMMM YYYY'),
      } as MenuItemLabelValue;
    });

    const predefinedTimespans: MenuItemLabelValue[] = (
      [
        ['YESTERDAY', 'Yesterday', convertPeriodToOption('YESTERDAY')],
        ['LAST_7_DAYS', 'Last 7 Days', convertPeriodToOption('LAST_7_DAYS')],
        ['LAST_30_DAYS', 'Last 30 Days', convertPeriodToOption('LAST_30_DAYS')],
        ['LAST_6_MONTHS', 'Last 6 Months', convertPeriodToOption('LAST_6_MONTHS')],
        ['ALL_TIME', 'All time', convertPeriodToOption('ALL_TIME')],
        ['CUSTOM', 'Custom', this.state.customPeriod],
      ] as [StatsPeriodValue, string, StatsPeriodOptions][]
    ).map(([period, label, options]) => {
      return {
        value: { period: period, options: options, preDefinedTimeframe: label !== 'Custom' },
        label: label,
      } as MenuItemLabelValue;
    });

    const options: MenuItemLabelValue[] = predefinedTimespans.concat(customTimespans);

    return (
      <Grid container direction="row" sx={{ width: 'auto', alignItems: 'center' }}>
        {!this.props.hideLabel && (
          <Grid item>
            <StyledBodyText sx={{ fontWeight: 700 }}>Stats Timeframe</StyledBodyText>
          </Grid>
        )}
        {!this.props.hideTooltip && (
          <Grid item justifyContent="center" alignItems={'center'}>
            <Tooltip
              sx={{ height: '100%', width: 15, m: 1, mt: '14px' }}
              title="Note that some details are not available with a custom date range"
            >
              <InfoIcon />
            </Tooltip>
          </Grid>
        )}

        <Grid item>
          <CustomSelect
            value={JSON.stringify(this.state.period)}
            onChange={(_evt, newValue) => {
              if (newValue) {
                this.handlePeriodChange(this.deserializePeriod(newValue as string));
              }
            }}
            style={{ height: 40 }}
            onListboxOpenChange={(value) => {
              this.setState({ openTimeframe: value });
            }}
            renderValue={(option: any) => option?.label}
          >
            {options.map((option) => {
              const value = this.serializePeriod(option.value);
              return (
                <Option key={value} value={value}>
                  {option.label}
                </Option>
              );
            })}
          </CustomSelect>
        </Grid>

        <Grid item>
          {this.state.period.period === 'CUSTOM' && (
            <>
              <StyledDatePicker
                format="DD MMMM YYYY"
                value={!this.state.period.preDefinedTimeframe ? moment.utc(this.state.period.options?.from) : null}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                onChange={(newValue: Moment | null) => {
                  if (newValue) {
                    const timespan = new Timespan(newValue, moment.utc(this.state.period.options?.until));
                    this.handlePeriodChange({
                      period: 'CUSTOM',
                      options: timespan.toGraphInputTimespan(),
                      preDefinedTimeframe: false,
                    });
                  }
                }}
                slotProps={{ textField: (params) => <TextField size="small" {...params} /> }}
                sx={{ ml: 0.5 }}
              />
              <StyledDatePicker
                format="DD MMMM YYYY"
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                value={!this.state.period.preDefinedTimeframe ? moment.utc(this.state.period.options?.until) : null}
                onChange={(newValue: Moment | null) => {
                  if (newValue) {
                    const timespan = new Timespan(moment.utc(this.state.period.options?.from), newValue);
                    this.handlePeriodChange({
                      period: 'CUSTOM',
                      options: timespan.toGraphInputTimespan(),
                      preDefinedTimeframe: false,
                    });
                  }
                }}
                sx={{ ml: 0.5 }}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  }

  private serializePeriod(period: StatsPeriod): string {
    return JSON.stringify(period);
  }

  private deserializePeriod(period: string): StatsPeriod {
    return JSON.parse(period);
  }

  private handlePeriodChange(statsPeriod: StatsPeriod) {
    this.setState({ period: statsPeriod });
    let parsedPeriod = { ...statsPeriod };
    if (statsPeriod.period === 'PRESET') {
      // The PRESET timeframes need to be sent as CUSTOM to the backend
      parsedPeriod.period = 'CUSTOM';
    } else if (statsPeriod.period === 'CUSTOM') {
      this.setState({ customPeriod: statsPeriod.options! });
    }
    this.props.onChange(parsedPeriod);
  }
}

export default SelectTimeframe;

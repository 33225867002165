import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '../../common/graphql';
import Session from '../../common/Session/Session';
import AppContent from './AppContent';
import CzyErrorBoundary from '../../common/components/CzyErrorBoundary';
import theme from './theme';
import { ProfileProvider } from './Content/Profile/ProfileLoader';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './icons';
import './App.css';
import ReleasePathProvider from './Content/UserDeveloper/Submission/ReleasePath/ReleasePathProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export interface Props {}

class App extends React.Component<Props> {
  render() {
    return (
      <CzyErrorBoundary>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={apolloClient}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Session>
                  <CssBaseline />
                  <ProfileProvider>
                    <ReleasePathProvider>
                      <Router>
                        <AppContent />
                        <ToastContainer hideProgressBar position="bottom-right" autoClose={4000} draggablePercent={60} closeOnClick />
                      </Router>
                    </ReleasePathProvider>
                  </ProfileProvider>
                </Session>
              </LocalizationProvider>
            </ApolloProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CzyErrorBoundary>
    );
  }

  componentDidMount() {
    if (process.env.REACT_APP_CONFIG !== 'prod') {
      // Searching if the emoji is already in the title
      const unifiedEmojiRanges = ['\ud83c[\udf00-\udfff]', '\ud83d[\udc00-\ude4f]', '\ud83d[\ude80-\udeff]'];
      const reg = new RegExp(unifiedEmojiRanges.join('|'), 'g');
      document.title = `${document.title.match(reg) ? '' : '🦙 -'} ${document.title}`;
    }
  }
}

export default App;

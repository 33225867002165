import moment from 'moment';
import * as EmailValidator from 'email-validator';

/**
 * Check if a given number is in fact a number and between min & max (inclusive)
 * @param input a number
 * @param min minimum boundary
 * @param max maximum boundary
 */
export function isValidNumberBetween(input: number, min: number, max: number) {
  if (isNaN(input)) {
    return false;
  }
  if (input < min) {
    return false;
  }
  if (input > max) {
    return false;
  }
  return true;
}

/**
 * Check if a given number is an integer and between min & max (inclusive)
 * @param input a number
 * @param min minimum boundary
 * @param max maximum boundary
 */
export function isValidIntegerBetween(input: string | number, min: number, max: number): boolean {
  const parsed = Number(input);
  if (!isValidNumberBetween(parsed, min, max)) {
    return false;
  }
  if (parsed % 1 !== 0) {
    return false;
  }
  return true;
}

export function isValidMoment(input: string, format: string) {
  const date = moment(input, format, true);
  return date.isValid();
}

export function isValidJSON(input: string): boolean {
  try {
    JSON.parse(input);
    return true;
  } catch (_e) {
    return false;
  }
}

export function isValidUrl(input: string) {
  let parsed: URL;
  try {
    parsed = new URL(input);
  } catch {
    return false;
  }
  if (!['http:', 'https:'].includes(parsed.protocol)) {
    return false;
  }
  return true;
}

const MAX_STRING_LENGTH = 255;

export function isTooLongStringInput(input: string) {
  return input.length > MAX_STRING_LENGTH;
}

const MIN_INT = 0;
const MAX_INT = 1000000000;

export function isOutOfBoundsInt(input: number) {
  return input < MIN_INT || input > MAX_INT;
}

export function isValidEmail(email: string) {
  if (email === '') {
    return true;
  }
  return EmailValidator.validate(email);
}

const MAX_SOCIAL_MEDIA_LENGTH = 40;

export function isValidTiktokUsername(tiktok: string) {
  if (tiktok === '') {
    return true;
  }

  if (tiktok.length > MAX_SOCIAL_MEDIA_LENGTH) {
    return false;
  }

  // Username can contain alphanumeric + underscore and period. Keep this regex and the one from graph in sync.
  return RegExp('^@[0-9a-zA-Z_\\.]+$').test(tiktok);
}

export function isValidDiscordUsername(discord: string) {
  return discord === '' || discord.length <= MAX_SOCIAL_MEDIA_LENGTH;
}

export function isValidInstagramUsername(instagram: string) {
  if (instagram === '') {
    return true;
  }

  if (instagram.length > MAX_SOCIAL_MEDIA_LENGTH) {
    return false;
  }

  // Username can contain alphanumeric + underscore and period. Keep this regex and the one from graph in sync.
  return RegExp('^[0-9a-zA-Z_\\.]+$').test(instagram);
}

export function isValidFacebookUsername(facebook: string) {
  if (facebook === '') {
    return true;
  }

  if (facebook.length > MAX_SOCIAL_MEDIA_LENGTH) {
    return false;
  }

  // Username can contain alphanumeric + period. Keep this regex and the one from graph in sync.
  return RegExp('^[0-9a-zA-Z\\.]+$').test(facebook);
}

export function isValidTwitterUsername(twitter: string) {
  if (twitter === '') {
    return true;
  }

  if (twitter.length > MAX_SOCIAL_MEDIA_LENGTH) {
    return false;
  }

  // Username can contain alphanumeric + underscore. Keep this regex and the one from graph in sync.
  return RegExp('^[0-9a-zA-Z_]+$').test(twitter);
}

export function isValidYouTubeChannelName(youtube: string) {
  if (youtube === '') {
    return true;
  }

  if (youtube.length > MAX_SOCIAL_MEDIA_LENGTH) {
    return false;
  }

  // youtube channels can be youtube.com/@channel, youtube.com/c/id, youtube.com/channel/id
  return RegExp('^[0-9a-zA-Z_./@/-]+$').test(youtube);
}

export function isValidDisplayName(displayName: string): { isValid: boolean; error?: string } {
  const dn = displayName || '';

  if (dn.length > 24) {
    return { isValid: false, error: 'Display name should contain max 24 characters' };
  }

  if (dn.includes('@')) {
    return { isValid: false, error: 'Display name cannot contain @ symbol' };
  }

  return { isValid: true };
}

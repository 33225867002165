import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { StyledEngineProvider, Box, Tooltip, Grid } from '@mui/material';
import { loadIubendaIfNeeded } from '../../../../../common/iubenda';
import GamePreview from './components/Preview/GamePreview';
import Requirements from './components/Requirements/Requirements';
import QAToolProvider from './QAToolProvider';
import SubmitManager from './components/SubmitManager';
import { useIsMobileOrTablet } from '../../../../../common/device';
import LogList from './components/Log/LogList';
import FakeUser from './components/FakeUserOptions/FakeUser';
import Header from '../../../Header/Header';
import { StyledBottomAdjacentBox, StyledBottomBox, StyledOptionsContainer, StyledSwitch } from './QATool.styles';
import { GAME_PREVIEW_SIZES, GameSize, getGameSizeTitle } from './components/Preview/GamePreviewSizes';
import { StyledBodyText } from '../../../../../common/Styleguide/Common/Text';
import { ACTION_LINK_HEIGHT } from '../../../Footer/ActionLink';
import ReleasePath from '../Submission/ReleasePath/ReleasePath';
import { ReleasePathContext } from '../Submission/ReleasePath/ReleasePathProvider';
import { PreviewOptions } from '../../Preview/PreviewRoute';
import MobileSizeButton from './components/MobileSizeButton';
import { StyledButton } from '../../../../../common/Styleguide/Common/Button';
import { QAProvider } from './components/Log/QAContext';
import { ApolloProvider } from '@apollo/client';
import { apolloClientUserPortal } from '../../../../../common/userportal/apollo-client';
import { PreviewSubmission } from '../../Preview/preview-graphql';

const PREFIX = 'QATool';

export const REALISTIC_CONDITIONS_PARAM_NAME = 'realisticConditions';

const classes = {
  spaceAround: `${PREFIX}-spaceAround`,
  popupText: `${PREFIX}-popupText`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.spaceAround}`]: {
    margin: `0 ${theme.spacing(3)}`,
  },
  [`& .${classes.popupText}`]: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
}));

export interface Props {
  gameName: string;
  submission?: PreviewSubmission;
  previewUrl: string;
  previewOptions: PreviewOptions;
}

const gameframeTopPadding = 20;

type QAToolProps = Props;

const QAToolInner: React.FC<QAToolProps> = ({ gameName, previewUrl, submission, previewOptions }) => {
  const [showUserProfileOptionsPopup, setShowUserProfileOptionsPopup] = useState(false);
  const isMobile = useIsMobileOrTablet();
  const [showSideColumns, setShowSideColumns] = useState<boolean>(true);
  const { setReleasePathState } = React.useContext(ReleasePathContext);
  const [gameSize, setGameSize] = useState<GameSize>(GameSize.NORMAL);
  const [lastSelectedSize, setLastSelectedSize] = useState<GameSize>(GameSize.NORMAL);
  const fillMemoryRef = useRef<WebAssembly.Memory>(
    new WebAssembly.Memory({
      initial: 1,
      maximum: 1,
    }),
  );

  const urlParams = new URLSearchParams(window.location.search);
  const realisticConditionsParam = urlParams.get(REALISTIC_CONDITIONS_PARAM_NAME);
  const realisticConditionsInitialValue = realisticConditionsParam === null || realisticConditionsParam === 'true';
  const [realisticConditions, setRealisticConditions] = useState<boolean>(realisticConditionsInitialValue);

  useEffect(() => {
    setGameSize(isMobile ? GameSize.MOBILE : lastSelectedSize);
    setShowSideColumns(!isMobile);
  }, [isMobile, lastSelectedSize]);

  // allocate additional memory to simulate the overhead of the real website
  useEffect(() => {
    if (realisticConditions) {
      const wasmPageSizeBytes = 65536; // WASM works with "pages", where a page has 65536 bytes
      const filledMemoryBytes = 419430400; // 400mb
      const totalPages = Math.round(filledMemoryBytes / wasmPageSizeBytes);
      fillMemoryRef.current = new WebAssembly.Memory({
        initial: totalPages,
        maximum: totalPages,
      });
    } else {
      fillMemoryRef.current = new WebAssembly.Memory({
        initial: 1,
        maximum: 1,
      });
    }
  }, [realisticConditions]);

  useEffect(() => {
    setReleasePathState('qa');
  }, [setReleasePathState]);

  useEffect(() => {
    loadIubendaIfNeeded();
  }, []);

  const renderSizeButton = (changeGameSize: GameSize) => {
    return (
      <StyledButton
        variant="contained"
        color={gameSize === changeGameSize ? 'purple' : 'darkGrey'}
        shape="square"
        onClick={() => {
          setGameSize(changeGameSize);
          setLastSelectedSize(changeGameSize);
        }}
      >
        {getGameSizeTitle(changeGameSize)}
      </StyledButton>
    );
  };

  const shouldDisplayReleasePath = !!submission && previewOptions.nextStep;

  return (
    <>
      {!isMobile && <Header key="appbar" type="PREVIEW" />}
      <div
        className="app--content"
        style={{
          ...(isMobile && {
            paddingTop: '0px',
          }),
        }}
      >
        <Root>
          {shouldDisplayReleasePath && <ReleasePath />}
          <StyledBottomBox gameframeHeight={GAME_PREVIEW_SIZES[gameSize].height + gameframeTopPadding + ACTION_LINK_HEIGHT}>
            {showSideColumns && (
              <StyledBottomAdjacentBox
                sx={{
                  width: 220,
                  pb: `${ACTION_LINK_HEIGHT}px`,
                  borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                }}
              >
                <Box sx={{ padding: '20px 30px' }}>
                  <LogList submission={submission} />
                </Box>
              </StyledBottomAdjacentBox>
            )}
            <Grid container flex={1} direction={'column'} wrap="nowrap">
              <Grid item sx={{ pl: 1 }}>
                {!isMobile && (
                  <StyledOptionsContainer>
                    <MobileSizeButton realisticConditions={realisticConditions} submission={submission} />
                    {renderSizeButton(GameSize.NORMAL)}
                    {renderSizeButton(GameSize.MEDIUM)}
                    {renderSizeButton(GameSize.LARGE)}
                    <div style={{ flex: 1 }}></div>
                    <StyledButton
                      variant="contained"
                      color={'darkGrey'}
                      shape="square"
                      onClick={() => {
                        setShowUserProfileOptionsPopup(true);
                      }}
                    >
                      Simulate user
                    </StyledButton>
                    <Tooltip title="This option allocates additional memory to simulate the overhead of the real website. We suggest to test your game with a lower-end device (e.g. Chromebook) before submitting, as we might reject games that do not run smoothly.">
                      <StyledBodyText sx={{ fontWeight: 700, ml: 1 }} color="white100">
                        Realistic conditions
                        <StyledSwitch
                          checked={realisticConditions}
                          onChange={(e) => {
                            setRealisticConditions(e.target.checked);
                          }}
                        />
                      </StyledBodyText>
                    </Tooltip>
                    <Tooltip title="Show/hide the side columns">
                      <StyledBodyText sx={{ fontWeight: 700, ml: 1 }} color="white100">
                        Side columns
                        <StyledSwitch
                          checked={showSideColumns}
                          onChange={(e) => {
                            setShowSideColumns(e.target.checked);
                          }}
                        />
                      </StyledBodyText>
                    </Tooltip>
                  </StyledOptionsContainer>
                )}
              </Grid>
              <Grid item>{renderContent()}</Grid>
            </Grid>
            {showSideColumns && (
              <StyledBottomAdjacentBox
                sx={{
                  width: 300,
                  pb: `${ACTION_LINK_HEIGHT}px`,
                  borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                }}
              >
                <Box sx={{ padding: '20px 30px' }}>
                  <Requirements submission={submission} />
                </Box>
              </StyledBottomAdjacentBox>
            )}
          </StyledBottomBox>

          <ApolloProvider client={apolloClientUserPortal}>
            <FakeUser
              submissionId={submission?.id}
              showOptionsModal={showUserProfileOptionsPopup}
              closeOptionsModal={() => setShowUserProfileOptionsPopup(false)}
            />
          </ApolloProvider>
          <SubmitManager submission={submission} previewOptions={previewOptions} onMobile={isMobile} />
        </Root>
      </div>
    </>
  );

  function renderContent() {
    return (
      <Box sx={{ marginTop: `${gameframeTopPadding}px`, pb: `${ACTION_LINK_HEIGHT}px` }}>
        <GamePreview name={gameName} size={gameSize} previewUrl={previewUrl} />
      </Box>
    );
  }
};

const QATool: React.FC<QAToolProps> = (props: QAToolProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <QAProvider>
        <QAToolProvider>
          <QAToolInner {...props} />
        </QAToolProvider>
      </QAProvider>
    </StyledEngineProvider>
  );
};

export default QATool;

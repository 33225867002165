import { Theme, styled } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';
import { StyledContainer } from '../../../../common/Styleguide/Common/Container';
import { COLORS } from '../../../../common/Styleguide/Common/colors';
import { StyledBodyText } from '../../../../common/Styleguide/Common/Text';

export const StyledAuthenticateFormControlLabel = styled(FormControlLabel)(() => ({
  display: 'flex',
  gap: 4,
  margin: 0,
  marginTop: 8,
  marginBottom: 24,
}));

export const StyledAuthenticatePolicyText = styled(StyledBodyText)(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    fontSize: 11,
  },
}));

export const StyledAuthenticateContainer = styled(StyledContainer)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(5),
  maxWidth: 430,
  backgroundColor: COLORS.black[70],
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(5, 2.5, 5, 2.5),
    margin: theme.spacing(0, 2.5),
  },
}));

export const StyledAuthenticateBottom = styled('div')(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(0, -5, -5, -5),
  padding: theme.spacing(5),
  background: COLORS.black[90],
  borderBottomLeftRadius: theme.spacing(3),
  borderBottomRightRadius: theme.spacing(3),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, -2.5, -5, -2.5),
    padding: theme.spacing(2.5),
  },
}));

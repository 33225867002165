import React from 'react';
import { Grid } from '@mui/material';
import GameIframe from './GameIframe';
import { GameSize } from './GamePreviewSizes';

interface Props {
  name: string;
  size: GameSize;
  previewUrl: string;
}

const GamePreview: React.FC<Props> = (props) => {
  return (
    <>
      <Grid container direction="column">
        <Grid item container justifyContent="center" sx={{ position: 'relative' }}>
          <GameIframe {...props} gameSize={props.size} />
        </Grid>
      </Grid>
    </>
  );
};

export default GamePreview;

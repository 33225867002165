import * as React from 'react';
import { Submission } from './submission-graphql';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import { MailReceiver } from '../../../../../modules/Mailer/Mailer';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from '../../../../../common/components/ApolloError/GraphQLError';
import { deleteSubmission } from './Mutations/delete-submission-mutation';
import { withRouter, RouteComponentProps } from 'react-router';
import { StyledButton } from '../../../../../common/Styleguide/Common/Button';
import { StyledBodyText } from '../../../../../common/Styleguide/Common/Text';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';
import { termsUrl } from '../../../../../common/components/TermsLink';

export interface ManageSubmissionProps extends RouteComponentProps<{}> {
  submission: Submission;
}

type DeleteState = 'not-started' | 'in-progress';

export interface ManageSubmissionState {
  dialogWarningOpen: boolean;
  state: DeleteState;
  error?: ApolloError;
}

class ManageSubmission extends React.Component<ManageSubmissionProps, ManageSubmissionState> {
  constructor(props: ManageSubmissionProps) {
    super(props);
    this.state = {
      dialogWarningOpen: false,
      state: 'not-started',
    };
  }

  render() {
    return (
      <Box sx={{ ml: 2.5 }}>
        <h2 style={{ fontWeight: 700, fontSize: 24, marginBlock: 8 }}>Remove your game</h2>
        <div style={{ color: COLORS.white[60] }}>{this.renderMessage()}</div>
        {this.canBeDeleted() && this.renderButton()}
        {this.canBeDeleted() && this.renderWarningDialog()}
        {this.renderError()}
      </Box>
    );
  }

  private renderMessage() {
    if (this.canBeDeleted()) {
      return `If you remove your game, it will not be visible on CrazyGames.com and you'll not be able to access the game from the dashboard.`;
    } else {
      return (
        <>
          Refer to article IX of the{` `}
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>
          {` `}
          on how to end the agreement regarding your game. If you have questions regarding your submission you can contact us at{` `}
          <a href="submissions@crazygames.com">submissions@crazygames.com</a>.
        </>
      );
    }
  }

  private renderButton() {
    const { state } = this.state;
    const inProgress = state === 'in-progress';
    return (
      <StyledButton
        variant="contained"
        onClick={this.handleWarningOpen}
        disabled={inProgress}
        color="red"
        sx={{ '&.Mui-disabled': { opacity: 0.5 }, mt: 2 }}
      >
        Remove my game
      </StyledButton>
    );
  }

  private renderWarningDialog() {
    const { dialogWarningOpen } = this.state;
    return (
      <Dialog open={dialogWarningOpen} onClose={this.handleWarningClose}>
        <DialogTitle id="alert-dialog-slide-title">Delete submission?</DialogTitle>
        <DialogContent>
          <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2 }}>
            This will delete your submission and take it offline from Crazygames.com and affiliated sites. This operation cannot be undone.
            If you have questions regarding your submission you can contact us at{' '}
            <a href={`mailto:${MailReceiver.submissions}`}>{MailReceiver.submissions}</a>.
          </StyledBodyText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={this.handleWarningClose} variant="contained" color="grey" height={50} sx={{ minWidth: 130, mr: 2 }}>
            Cancel
          </StyledButton>
          <StyledButton onClick={this.delete} variant="contained" color="red" height={50} style={{ minWidth: 130 }}>
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    );
  }

  private canBeDeleted() {
    const { submission } = this.props;
    return !(submission.gameStatus && submission.gameStatus === 'PUBLISHED');
  }

  private renderError() {
    const { error } = this.state;
    return <GraphQLError error={error} />;
  }

  private handleWarningOpen = () => {
    this.setState({
      dialogWarningOpen: true,
    });
  };

  private handleWarningClose = () => {
    this.setState({
      dialogWarningOpen: false,
    });
  };

  private delete = () => {
    const { submission, history } = this.props;
    this.setState({
      state: 'in-progress',
    });
    deleteSubmission({ id: submission.id })
      .then((res) => {
        this.setState({
          state: 'not-started',
        });
        history.push('/games');
      })
      .catch((err: ApolloError) => {
        this.setState({
          state: 'not-started',
          error: err,
        });
      });
  };
}

export default withRouter(ManageSubmission);

import { SvgIcon } from '@mui/material';
import * as React from 'react';

const ExternalLinkIcon: React.FunctionComponent = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1429 0C10.9155 3.38746e-09 10.6975 0.0903059 10.5368 0.251051C10.376 0.411797 10.2857 0.629814 10.2857 0.857143C10.2857 1.08447 10.376 1.30249 10.5368 1.46323C10.6975 1.62398 10.9155 1.71429 11.1429 1.71429H13.0743L8.25143 6.53714C8.10002 6.69963 8.0176 6.91454 8.02151 7.1366C8.02543 7.35866 8.11539 7.57052 8.27243 7.72757C8.42948 7.88461 8.64135 7.97457 8.8634 7.97849C9.08546 7.9824 9.30037 7.89998 9.46286 7.74857L14.2857 2.92571V4.85714C14.2857 5.08447 14.376 5.30249 14.5368 5.46323C14.6975 5.62398 14.9155 5.71429 15.1429 5.71429C15.3702 5.71429 15.5882 5.62398 15.7489 5.46323C15.9097 5.30249 16 5.08447 16 4.85714V0H11.1429ZM1.71429 3.42857V13.7143C1.71429 13.8658 1.77449 14.0112 1.88165 14.1183C1.98882 14.2255 2.13416 14.2857 2.28571 14.2857H12.5714C12.723 14.2857 12.8683 14.2255 12.9755 14.1183C13.0827 14.0112 13.1429 13.8658 13.1429 13.7143V8.85714C13.1429 8.62982 13.2332 8.4118 13.3939 8.25105C13.5547 8.09031 13.7727 8 14 8C14.2273 8 14.4453 8.09031 14.6061 8.25105C14.7668 8.4118 14.8571 8.62982 14.8571 8.85714V13.7143C14.8571 14.3205 14.6163 14.9019 14.1877 15.3305C13.759 15.7592 13.1776 16 12.5714 16H2.28571C1.67951 16 1.09812 15.7592 0.66947 15.3305C0.240816 14.9019 0 14.3205 0 13.7143V3.42857C0 2.82236 0.240816 2.24098 0.66947 1.81233C1.09812 1.38367 1.67951 1.14286 2.28571 1.14286H7.14286C7.37019 1.14286 7.5882 1.23316 7.74895 1.39391C7.90969 1.55465 8 1.77267 8 2C8 2.22733 7.90969 2.44535 7.74895 2.60609C7.5882 2.76684 7.37019 2.85714 7.14286 2.85714H2.28571C2.13416 2.85714 1.98882 2.91735 1.88165 3.02451C1.77449 3.13167 1.71429 3.27702 1.71429 3.42857Z"
      />
    </SvgIcon>
  );
});

export default ExternalLinkIcon;

import React from 'react';
import { getMissingSDKFeatures } from '../Requirements/RequirementsUtils';
import { QAToolContext } from '../../QAToolProvider';
import { DOCS_URL } from '../../../../../SideMenu/Menu';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
  Checkbox,
} from '@mui/material';
import { StyledButton } from '../../../../../../../common/Styleguide/Common/Button';
import { COLORS } from '../../../../../../../common/Styleguide/Common/colors';
import { StyledBodyText, StyledWarningText } from '../../../../../../../common/Styleguide/Common/Text';
import { PreviewSubmission } from '../../../../Preview/preview-graphql';

interface SDKFeaturesNotDetectedPopupProps {
  submission: PreviewSubmission;
  next: () => void;
  back: () => void;
}

const SDKFeaturesNotDetectedPopup: React.FC<SDKFeaturesNotDetectedPopupProps> = ({ submission, back, next }) => {
  const { sdkRequirements, sdkYesNoRequirements } = React.useContext(QAToolContext);
  const [checked, setChecked] = React.useState(false);
  const missingSDKFeatures = getMissingSDKFeatures(sdkRequirements, sdkYesNoRequirements);

  return (
    <Dialog open onClose={back}>
      <DialogTitle id="alert-dialog-slide-title">Warning</DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2, mt: 0 }}>
          The following SDK features have not been detected:
        </StyledBodyText>
        {missingSDKFeatures.map((value) => {
          return <StyledWarningText key={value.key}>{value.name}</StyledWarningText>;
        })}
        <StyledBodyText color="white60" sx={{ textAlign: 'center' }}>
          Do you confirm that you did not implement these features? (
          <a href={DOCS_URL} target="_blank" rel="noopener noreferrer">
            more info
          </a>
          ).
        </StyledBodyText>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', pt: 2, pb: 3 }}>
        <FormControl fullWidth sx={{ alignItems: 'center', mb: 2 }}>
          <FormControlLabel
            sx={{ [`& .${formControlLabelClasses.label}`]: { color: COLORS.white[60] } }}
            control={<Checkbox checked={checked} onChange={(_evt: {}, checked: boolean) => setChecked(checked)} />}
            label="I did not implement these features"
          />
        </FormControl>

        <div style={{ display: 'flex' }}>
          <StyledButton onClick={back} variant="contained" color="purple" height={50} sx={{ minWidth: 208, mr: 2 }}>
            Back to preview
          </StyledButton>
          <StyledButton onClick={next} variant="contained" color="white" height={50} style={{ minWidth: 208 }} disabled={!checked}>
            Continue anyway
          </StyledButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SDKFeaturesNotDetectedPopup;

import { loadScript, loadScriptContent } from '../../script';
import config from '../config';

const GTAG_URL = 'https://www.googletagmanager.com/gtag/js';

async function loadGA4(): Promise<void> {
  if (!config.ga4ID) {
    return;
  }
  await loadScript(`${GTAG_URL}?id=${config.ga4ID}`);

  const gtagScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {dataLayer.push(arguments)};
    gtag('js', new Date());
    gtag('config', '${config.ga4ID}', {'debug_mode':window.location.search.includes('ga4_debug=true')});
    `;

  await loadScriptContent(gtagScript);
}

loadGA4();

import { gql } from '@apollo/client';
import { GAME_ORIENTATION } from '../../../../common/CzyForm/OrientationSelector';

export const PREVIEW_QUERY = gql`
  query GetPreviewData($id: String!) {
    me {
      id
      submission(id: $id) {
        id
        gameName
        orientation
        isIOSFriendly
        isAndroidFriendly
        hasIAP
      }
    }
  }
`;

export interface PreviewQueryInput {
  id: string;
}

export interface Developer {
  id: string;
  gameName: string;
  width: number;
  height: number;
}

export interface PreviewSubmission {
  id: string;
  gameName: string;
  orientation?: GAME_ORIENTATION;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  hasIAP: boolean;
}

export interface PreviewQuery {
  me: {
    submission: PreviewSubmission;
  };
}

import * as React from 'react';

export const link = 'https://pegi.info/what-do-the-labels-mean';

const PEGIRatingLink: React.FC = () => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    PEGI 12 label
  </a>
);

export default PEGIRatingLink;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Refresh: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C9.04023 4 6.45407 5.60745 5.07002 8H7C7.55228 8 8 8.44772 8 9C8 9.55228 7.55228 10 7 10H2V4C2 3.44772 2.44772 3 3 3C3.55228 3 4 3.44772 4 4V5.99936C5.82377 3.57172 8.72763 2 12 2C16.7604 2 20.7417 5.32525 21.7522 9.77871C21.8744 10.3173 21.5369 10.853 20.9983 10.9752C20.4597 11.0974 19.924 10.7599 19.8018 10.2213C18.9934 6.65879 15.806 4 12 4ZM3.00174 13.0248C3.54033 12.9026 4.07602 13.2401 4.19824 13.7787C5.00661 17.3412 8.19401 20 12 20C14.9597 20 17.5456 18.3929 18.93 16H17C16.4477 16 16 15.5523 16 15C16 14.4477 16.4477 14 17 14H22V20C22 20.5523 21.5523 21 21 21C20.4477 21 20 20.5523 20 20V18.0007C18.1764 20.4281 15.2724 22 12 22C7.23966 22 3.25836 18.6747 2.24782 14.2213C2.12561 13.6827 2.46315 13.147 3.00174 13.0248Z"
      />
    </SvgIcon>
  );
});

export default Refresh;

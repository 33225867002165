import React from 'react';
import Paper from '@mui/material/Paper';
import { FeedbackType } from '../../components/App/Content/UserDeveloper/Submission/submission-graphql';
import { StyledBodyText } from '../Styleguide/Common/Text';
import moment from 'moment';

export interface FeedbackProps {
  feedback: FeedbackType;
}

const Feedback: React.FC<FeedbackProps> = ({ feedback }) => {
  return (
    <div>
      {feedback.length === 0 && <p>There is no feedback yet on this submission.</p>}
      {feedback
        .slice(0)
        .sort((a, b) => {
          const dateA = moment(a.postedOn).format('YYYY-MM-DD HH:mm:ss');
          const dateB = moment(b.postedOn).format('YYYY-MM-DD HH:mm:ss');
          return dateB < dateA ? -1 : 1;
        })
        .map((entry, idx) => (
          <Paper elevation={2} key={idx} sx={{ py: 1, px: 3, mb: 2 }}>
            <StyledBodyText>
              <strong>{entry.authorName}</strong> on {entry.postedOn}
            </StyledBodyText>

            <div>
              <em>
                <StyledBodyText color="white60" sx={{ whiteSpace: 'pre-line' }}>
                  {entry.comment}
                </StyledBodyText>
              </em>
            </div>
          </Paper>
        ))}
    </div>
  );
};

export default Feedback;

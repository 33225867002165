import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Mail: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99998 7C1.99998 5.34315 3.34313 4 4.99998 4H19C20.6568 4 22 5.34315 22 7V7.98448C22.0001 7.99422 22.0001 8.00395 22 8.01368V17C22 18.6569 20.6568 20 19 20H4.99998C3.34313 20 1.99998 18.6569 1.99998 17V8.01367C1.99984 8.00395 1.99984 7.99422 1.99998 7.98449V7ZM3.99998 9.86852V17C3.99998 17.5523 4.4477 18 4.99998 18H19C19.5523 18 20 17.5523 20 17V9.86852L13.6641 14.0925C12.6564 14.7642 11.3436 14.7642 10.3359 14.0925L3.99998 9.86852ZM20 7.46482L12.5547 12.4283C12.2188 12.6523 11.7812 12.6523 11.4453 12.4283L3.99998 7.46482V7C3.99998 6.44772 4.4477 6 4.99998 6H19C19.5523 6 20 6.44772 20 7V7.46482Z"
      />
    </SvgIcon>
  );
});

export default Mail;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Help: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2004 17.7359C11.5677 21.1226 10 25.3782 10 30C10 34.6218 11.5677 38.8774 14.2004 42.2641L19.571 36.8935C18.2622 34.9174 17.5 32.5477 17.5 30C17.5 27.4523 18.2622 25.0826 19.571 23.1065L14.2004 17.7359ZM17.7359 14.2004L23.1065 19.571C25.0826 18.2622 27.4523 17.5 30 17.5C32.5477 17.5 34.9174 18.2622 36.8935 19.571L42.2641 14.2004C38.8774 11.5677 34.6218 10 30 10C25.3782 10 21.1226 11.5677 17.7359 14.2004ZM45.7996 17.7359L40.429 23.1065C41.7378 25.0826 42.5 27.4523 42.5 30C42.5 32.5477 41.7378 34.9174 40.429 36.8935L45.7996 42.2641C48.4323 38.8774 50 34.6218 50 30C50 25.3782 48.4323 21.1226 45.7996 17.7359ZM42.2641 45.7996L36.8935 40.429C34.9174 41.7378 32.5477 42.5 30 42.5C27.4523 42.5 25.0826 41.7378 23.1065 40.429L17.7359 45.7996C21.1226 48.4323 25.3782 50 30 50C34.6218 50 38.8774 48.4323 42.2641 45.7996ZM5 30C5 16.1929 16.1929 5 30 5C43.8071 5 55 16.1929 55 30C55 43.8071 43.8071 55 30 55C16.1929 55 5 43.8071 5 30ZM22.5 30C22.5 34.1421 25.8579 37.5 30 37.5C34.1421 37.5 37.5 34.1421 37.5 30C37.5 25.8579 34.1421 22.5 30 22.5C25.8579 22.5 22.5 25.8579 22.5 30Z"
      />
    </SvgIcon>
  );
});

export default Help;

import * as React from 'react';
import { NumberContainer, PathItem, Root, StyledArrowSimpleRight } from './releasePath.styles';
import { ReleasePathContext } from './ReleasePathProvider';

interface ReleasePathProps {
  isGameJam?: boolean;
}

const ReleasePath: React.FunctionComponent<ReleasePathProps> = ({ isGameJam = false }) => {
  const { releasePathState } = React.useContext(ReleasePathContext);
  const isQaActive = ['qa'].includes(releasePathState);
  const isSubmitActive = ['submit'].includes(releasePathState);
  const isReviewActive = ['review'].includes(releasePathState);
  const isDetailsActive = ['details'].includes(releasePathState);
  const isYourGameActive = ['your_game'].includes(releasePathState);
  return (
    <Root>
      <PathItem active={isYourGameActive}>
        <NumberContainer active={isYourGameActive}>1</NumberContainer>
        Your game
      </PathItem>
      {!isGameJam && (
        <>
          <StyledArrowSimpleRight />
          <PathItem active={isQaActive}>
            <NumberContainer active={isQaActive}>2</NumberContainer>
            QA tool
          </PathItem>
          <StyledArrowSimpleRight />
          <PathItem active={isDetailsActive}>
            <NumberContainer active={isDetailsActive}>3</NumberContainer>
            Details
          </PathItem>
          <StyledArrowSimpleRight />
          <PathItem active={isSubmitActive}>
            <NumberContainer active={isSubmitActive}>4</NumberContainer>
            Submit
          </PathItem>
          <StyledArrowSimpleRight />
          <PathItem active={isReviewActive}>
            <NumberContainer active={isReviewActive}>5</NumberContainer>
            Sent for review
          </PathItem>
        </>
      )}
    </Root>
  );
};

export default ReleasePath;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Feedback: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V16C22 17.6569 20.6569 19 19 19H8.55397C8.37272 19 8.19489 19.0493 8.03947 19.1425L3.5145 21.8575C3.20556 22.0429 2.82081 22.0477 2.5073 21.8702C2.19379 21.6927 2 21.3603 2 21V5ZM5 4C4.44772 4 4 4.44772 4 5V19.2338L7.01048 17.4275C7.47673 17.1478 8.01024 17 8.55397 17H19C19.5523 17 20 16.5523 20 16V5C20 4.44772 19.5523 4 19 4H5Z"
      />
      <path d="M11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7V11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11V7Z" />
      <path d="M11 14C11 13.4477 11.4477 13 12 13C12.5523 13 13 13.4477 13 14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14Z" />
    </SvgIcon>
  );
});

export default Feedback;

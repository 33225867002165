import { SvgIcon } from '@mui/material';
import * as React from 'react';

const SupportIcon: React.FunctionComponent = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M1.6 0C1.17565 0 0.768687 0.168571 0.468629 0.468629C0.168571 0.768687 0 1.17565 0 1.6V11.2C0 11.6243 0.168571 12.0313 0.468629 12.3314C0.768687 12.6314 1.17565 12.8 1.6 12.8H4.8V15.2C4.8 15.4122 4.88429 15.6157 5.03431 15.7657C5.18434 15.9157 5.38783 16 5.6 16H6C6.2 16 6.4 15.92 6.56 15.768L9.52 12.8H14.4C14.8243 12.8 15.2313 12.6314 15.5314 12.3314C15.8314 12.0313 16 11.6243 16 11.2V1.6C16 1.17565 15.8314 0.768687 15.5314 0.468629C15.2313 0.168571 14.8243 0 14.4 0H1.6ZM1.6 1.6H14.4V11.2H8.864L6.4 13.664V11.2H1.6V1.6ZM8.152 2.8C7.44 2.8 6.872 2.944 6.44 3.232C6 3.52 5.776 4 5.816 4.552H7.392C7.392 4.328 7.472 4.16 7.6 4.048C7.76 3.936 7.936 3.88 8.152 3.88C8.4 3.88 8.616 3.944 8.76 4.088C8.904 4.224 8.976 4.4 8.976 4.64C8.976 4.864 8.912 5.064 8.8 5.232C8.664 5.408 8.496 5.552 8.288 5.664C7.872 5.92 7.6 6.144 7.432 6.336C7.28 6.528 7.2 6.8 7.2 7.2H8.8C8.8 6.976 8.84 6.8 8.912 6.656C8.984 6.52 9.12 6.4 9.328 6.28C9.696 6.112 10 5.888 10.232 5.6C10.464 5.304 10.584 4.992 10.584 4.64C10.584 4.08 10.368 3.632 9.936 3.296C9.504 2.968 8.904 2.8 8.152 2.8ZM7.2 8V9.6H8.8V8H7.2Z" />
    </SvgIcon>
  );
});

export default SupportIcon;

import React from 'react';
import { StyledBodyText, StyledHeaderText, StyledSpanText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import withTheme, { WithTheme } from '../../../../../helpers/WithTheme';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import withSubmission, { WithSubmission } from '../Context/withSubmission';
import { valueFromInput } from '../../../../../../common/input';
import { Link } from 'react-router-dom';
import { HALF_INPUT_WIDTH } from '../UpdateSubmission/UpdateSubmission.types';
import { MAX_SLUG_LENGTH } from '../../../../../../common/slug';

interface Props {}

type _Props = Props & WithTheme & WithSubmission;

interface State {
  instructionsDialogOpen: boolean;
  isCollapsed: boolean;
}

class GameBaseFormName extends React.Component<_Props, State> {
  constructor(props: _Props) {
    super(props);
    this.state = {
      instructionsDialogOpen: false,
      isCollapsed: this.props.isNonEditable,
    };
  }

  render() {
    return (
      <>
        <StyledContainer
          sx={{
            width: 900,
            m: 2,
            mt: 3,
            p: 2,
            px: 3,
          }}
        >
          <StyledHeaderText
            variant="h2"
            sx={{
              paddingY: 1,
              margin: 0,
            }}
          >
            Your game
          </StyledHeaderText>
          {this.renderName()}
        </StyledContainer>
      </>
    );
  }

  private renderName() {
    const { name } = this.props.baseForm;
    const { isReleased, baseFormProblems, attemptedToSave = false, gameJamLocked } = this.props;
    return (
      <>
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
          Game Title <span>* </span>
          {(isReleased || gameJamLocked) && (
            <StyledSpanText variant="bodyLower2" color="white80">
              You can't update this field. Feel free to <Link to="/support">contact us</Link> if you have any question.
            </StyledSpanText>
          )}
        </StyledBodyText>
        <StyledInput
          value={name || ''}
          error={baseFormProblems?.includes('NAME_INVALID') || (baseFormProblems?.includes('NAME_MISSING') && attemptedToSave)}
          disabled={isReleased || gameJamLocked}
          required
          onChange={this.handleNameChange}
          style={{ width: HALF_INPUT_WIDTH }}
        />
        {baseFormProblems?.includes('NAME_INVALID') && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="error">
            {`Max length must be lower than ${MAX_SLUG_LENGTH} chars `}
          </StyledBodyText>
        )}
        {baseFormProblems?.includes('NAME_MISSING') && attemptedToSave && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="error">
            {`Please fill in the input `}
          </StyledBodyText>
        )}
        {!isReleased && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="white30">
            {`Must be the same as the title that appears in your game - Max length is ${MAX_SLUG_LENGTH} chars.`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const name = valueFromInput(evt);
    this.props.updateBaseForm({ ...this.props.baseForm, name });
  };
}

export default withTheme(withSubmission(GameBaseFormName));

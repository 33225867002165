export type SubmissionStatus =
  | 'DRAFT'
  | 'NEW_SUBMISSION'
  | 'RELEASED'
  | 'REQUIRES_APPROVAL'
  | 'PREPARING_RELEASE'
  | 'AWAITING_DEVELOPER_CHANGE'
  | 'REJECTED'
  | 'SYNC_REQUIRED'
  | 'FINALIZED'
  | 'NEW_BUSINESS_REVIEW'
  | 'UNDER_BUSINESS_REVIEW';

export function readableStatus(status: SubmissionStatus): string {
  switch (status) {
    case 'DRAFT':
      return 'Draft';
    case 'FINALIZED':
      return 'Released (finalized)';
    case 'RELEASED':
      return 'Released';
    case 'REQUIRES_APPROVAL':
      return 'Under Review';
    case 'NEW_SUBMISSION':
      return 'Awaiting Moderator Approval';
    case 'AWAITING_DEVELOPER_CHANGE':
      return 'Changes Required';
    case 'PREPARING_RELEASE':
      return 'Preparing for Release';
    case 'REJECTED':
      return 'Rejected';
    case 'SYNC_REQUIRED':
      return 'Update under Verification';
    case 'NEW_BUSINESS_REVIEW':
      return 'Waiting for business review';
    case 'UNDER_BUSINESS_REVIEW':
      return 'Under business review';
    default:
      throw new Error(`[readableStatus] unexpected submission status: ${status}`);
  }
}

export type SubmissionFilterType =
  | 'ALL'
  | 'DRAFT'
  | 'ALL_PENDING'
  | 'RELEASED'
  | 'REJECTED'
  | 'SYNC_REQUIRED'
  | 'REQUIRES_APPROVAL'
  | 'PREPARING_RELEASE'
  | 'AWAITING_DEVELOPER_CHANGE'
  | 'NEW_SUBMISSION'
  | 'NEW_BUSINESS_REVIEW'
  | 'UNDER_BUSINESS_REVIEW';

export const SUBMISSION_FILTERS: { [key: string]: SubmissionFilterType } = {
  all: 'ALL',
  draft: 'DRAFT',
  allPending: 'ALL_PENDING',
  released: 'RELEASED',
  rejected: 'REJECTED',
  syncRequired: 'SYNC_REQUIRED',
  requiresApproval: 'REQUIRES_APPROVAL',
  preparingRelease: 'PREPARING_RELEASE',
  awaitingDeveloperChange: 'AWAITING_DEVELOPER_CHANGE',
  newSubmission: 'NEW_SUBMISSION',
  newBusinessReview: 'NEW_BUSINESS_REVIEW',
  underBusinessReview: 'UNDER_BUSINESS_REVIEW',
};

export function filterByStatus(status: SubmissionStatus, filter: SubmissionFilterType): boolean {
  const statusFilter = (statuses: SubmissionStatus[]) => {
    return statuses.indexOf(status) >= 0;
  };
  switch (filter) {
    case 'ALL':
      return true;
    case 'DRAFT':
      return statusFilter(['DRAFT']);
    case 'ALL_PENDING':
      return statusFilter(['REQUIRES_APPROVAL', 'PREPARING_RELEASE', 'AWAITING_DEVELOPER_CHANGE', 'NEW_SUBMISSION']);
    case 'REJECTED':
      return statusFilter(['REJECTED']);
    case 'RELEASED':
      return statusFilter(['RELEASED', 'SYNC_REQUIRED']);
    case 'SYNC_REQUIRED':
      return statusFilter(['SYNC_REQUIRED']);
    case 'REQUIRES_APPROVAL':
      return statusFilter(['REQUIRES_APPROVAL']);
    case 'PREPARING_RELEASE':
      return statusFilter(['PREPARING_RELEASE']);
    case 'AWAITING_DEVELOPER_CHANGE':
      return statusFilter(['AWAITING_DEVELOPER_CHANGE']);
    case 'NEW_SUBMISSION':
      return statusFilter(['NEW_SUBMISSION']);
    case 'NEW_BUSINESS_REVIEW':
      return statusFilter(['NEW_BUSINESS_REVIEW']);
    case 'UNDER_BUSINESS_REVIEW':
      return statusFilter(['UNDER_BUSINESS_REVIEW']);
    default:
      throw new Error(`Unsupported filter: ${filter}`);
  }
}

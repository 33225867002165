import React from 'react';
import { readableStatus } from '../../../../../common/domain/submission';
import { Submission } from './submission-graphql';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';
import { styled, useTheme } from '@mui/material/styles';
import { StyledButton } from '../../../../../common/Styleguide/Common/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { StyledBodyText } from '../../../../../common/Styleguide/Common/Text';
import { getGameUrl } from '../../../../../common/domain-names';
import PublishedDate from './PublishedDate';

interface SubmissionStatusBadgeProps {
  submission: Submission;
}

const StyledSubmissionStatusBadge = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  width: 'fit-content',
  padding: '5px 10px',
  borderRadius: 6,
  color: COLORS.black[90],
  fontSize: 12,
  textTransform: 'uppercase',
  fontWeight: '700',
  lineHeight: '135%',
}));

const SubmissionStatusBadge: React.FC<SubmissionStatusBadgeProps> = ({ submission }) => {
  const { spacing } = useTheme();
  const getColor = () => {
    switch (submission.status) {
      case 'NEW_SUBMISSION':
      case 'REQUIRES_APPROVAL':
      case 'PREPARING_RELEASE':
      case 'SYNC_REQUIRED':
      case 'NEW_BUSINESS_REVIEW':
      case 'UNDER_BUSINESS_REVIEW':
        return COLORS.brand[80];
      case 'RELEASED':
      case 'FINALIZED':
        return COLORS.success[100];
      case 'REJECTED':
        return COLORS.alert[100];
      case 'DRAFT':
        return COLORS.white[40];
      case 'AWAITING_DEVELOPER_CHANGE':
      default:
        return COLORS.warning[100];
    }
  };

  const slug = submission.slug;
  const gameUrl = getGameUrl(slug, { cacheBust: true });

  return (
    <div style={{ display: 'flex', gap: spacing(1), alignItems: 'center' }}>
      <StyledSubmissionStatusBadge color={getColor()}>{readableStatus(submission.status)}</StyledSubmissionStatusBadge>
      {submission.status === 'RELEASED' && (
        <a href={gameUrl} target="_blank" rel="noopener noreferrer">
          <StyledButton sx={{ borderRadius: 0.75, px: 1 }} height={26} variant="outlined" color="darkGrey">
            <StyledBodyText variant="bodyLower2" sx={{ fontWeight: 700 }}>
              VIEW GAME
            </StyledBodyText>
            <OpenInNewIcon style={{ marginRight: 0, marginLeft: spacing(0.75) }} />
          </StyledButton>
        </a>
      )}
      {/* draft submissions don't have a slug */}
      {submission.slug && <PublishedDate slug={submission.slug} />}
    </div>
  );
};

export default SubmissionStatusBadge;

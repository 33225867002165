import React, { useState } from 'react';
import { LogEntry } from './LogItem';

export interface QAContextType {
  logs: LogEntry[];
  pushLog: (newLogEntry: LogEntry) => void;
}

const QAContext = React.createContext<QAContextType>({
  logs: [],
  pushLog: () => {},
});

export const QAProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [logs, setLogs] = useState<LogEntry[]>([]);

  function pushLog(newLogEntry: LogEntry) {
    setLogs((oldValue) => [newLogEntry, ...oldValue]);
  }

  const context: QAContextType = {
    logs: logs,
    pushLog: pushLog,
  };

  return <QAContext.Provider value={context}>{children}</QAContext.Provider>;
};

export default QAContext;

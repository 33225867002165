import { styled, SxProps } from '@mui/material';
import React from 'react';

export interface SvgIconProps extends React.SVGAttributes<SVGElement> {
  color?: 'primary' | 'secondary' | 'inherit';
  size?: 'small' | 'large';
  sx?: SxProps;
}

const StyledSVG = styled('svg', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size' && prop !== 'sx',
})<SvgIconProps>(({ size }) => ({
  display: 'inline-block',
  fill: 'currentcolor',
  height: size === 'small' ? 20 : 24,
  width: size === 'small' ? 20 : 24,
}));

const SvgIcon: React.FC<SvgIconProps> = ({ color, size, ...props }) => (
  <StyledSVG viewBox="0 0 24 24" focusable="false" aria-hidden="true" {...props}></StyledSVG>
);

export default SvgIcon;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Edit: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.405 3.59533C16.0423 3.23261 15.4542 3.23261 15.0915 3.59533L7.46516 11.2217V12.5352H8.77868L16.405 4.90885C16.7677 4.54613 16.7677 3.95805 16.405 3.59533ZM13.9201 2.42392C14.9297 1.41425 16.5667 1.41426 17.5764 2.42392C18.5861 3.43359 18.5861 5.07059 17.5764 6.08026L9.70748 13.9492C9.55214 14.1045 9.34146 14.1918 9.12177 14.1918H6.63685C6.17938 14.1918 5.80854 13.8209 5.80854 13.3635V10.8786C5.80854 10.6589 5.8958 10.4482 6.05114 10.2929L13.9201 2.42392ZM4.98023 5.0804C4.0653 5.0804 3.32361 5.82209 3.32361 6.73701V15.0201C3.32361 15.935 4.0653 16.6767 4.98023 16.6767H13.2633C14.1782 16.6767 14.9199 15.935 14.9199 15.0201V12.5352C14.9199 12.0777 15.2908 11.7069 15.7482 11.7069C16.2057 11.7069 16.5766 12.0777 16.5766 12.5352V15.0201C16.5766 16.85 15.0932 18.3333 13.2633 18.3333H4.98023C3.15038 18.3333 1.66699 16.85 1.66699 15.0201V6.73701C1.66699 4.90716 3.15038 3.42378 4.98023 3.42378H8.29346C8.75093 3.42378 9.12177 3.79462 9.12177 4.25209C9.12177 4.70955 8.75093 5.0804 8.29346 5.0804H4.98023Z"
      />
    </SvgIcon>
  );
});

export default Edit;

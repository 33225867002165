import React from 'react';
import { Grid } from '@mui/material';
import InternalLoader, { LoaderType } from 'react-loaders';

export interface LoaderProps {
  type: LoaderType;
  active: boolean;
  innerClassName?: string;
  color?: string;
  style?: React.CSSProperties;
}

const loaderBgCss: React.CSSProperties = {
  backgroundColor: 'gray',
  width: '150px',
  height: '100px',
  paddingTop: '25px',
  paddingLeft: '45px',
};
const loaderWrapperCss: React.CSSProperties = {
  position: 'absolute',
  top: 240,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 99,
};

const CzyLoader: React.FC = () => {
  return (
    <div style={loaderWrapperCss}>
      <Grid container justifyContent="center">
        <Grid item>
          <div style={loaderBgCss}>
            <InternalLoader type="pacman" active />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CzyLoader;

import { User } from '../../common/Session/SessionContext';
import { DeveloperProfile } from '../App/Content/Profile/profile-graphql';

export type ProfileCompletionRequirements = 'PROFILE-NAME_MISSING' | 'EMAIL-NOT-VERIFIED' | 'LEGAL-MISSING' | 'COMPLETE';

export default function getProfileCompletionRequirements(profile: DeveloperProfile, user: User): ProfileCompletionRequirements[] {
  const requirements: ProfileCompletionRequirements[] = [];
  if (!profile.name || profile.name.trim().length === 0) {
    requirements.push('PROFILE-NAME_MISSING');
  }
  if (!user.emailVerified) {
    requirements.push('EMAIL-NOT-VERIFIED');
  }
  if (!profile.hasAcceptedLegal) {
    requirements.push('LEGAL-MISSING');
  }
  return requirements;
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import CzyLoader from '../../../../../../../common/Loader';
import { GAME_PREVIEW_SIZES, GameSize } from './GamePreviewSizes';
import { Button } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import vhCheck from 'vh-check';
import { QAToolContext } from '../../QAToolProvider';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { StyledHeaderText } from '../../../../../../../common/Styleguide/Common/Text';
import { useIsMobileOrTablet } from '../../../../../../../common/device';

const StyledIframe = styled('iframe')(() => ({
  border: 'none',
  transition: 'all 0.1s linear',
  backgroundColor: '#FFFFFF', // White background only to read iframe eventual errors that are displayed in black by default.
}));

const StyledFsContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  inset: 0,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  zIndex: 9999,
}));

interface Props {
  gameSize: GameSize;
  name: string;
  previewUrl: string;
}

const enableFakeFs = (element: HTMLElement) => {
  vhCheck();

  document.documentElement.style.height = '100%';
  document.documentElement.style.overflow = 'hidden';
  document.body.style.height = 'calc(100vh - var(--vh-offset, 0px))';

  element.style.position = 'fixed';
  element.style.overflow = 'hidden';
  element.style.top = '0';
  element.style.left = '0';
  element.style.zIndex = '200';
  element.style.width = '100vw';
  element.style.height = 'calc(100vh - var(--vh-offset, 0px))';
};

const GameIframe: React.FC<Props> = ({ previewUrl, name, gameSize }) => {
  const isMobile = useIsMobileOrTablet();
  const [showFSButton, toggleFSButton] = useState(isMobile);
  const { setData: setQAToolContextData } = useContext(QAToolContext);
  const [iframeKey, setIframeKey] = useState(new Date().getTime());
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const [gameWidthAndHeight, setGameWidthAndHeight] = useState(GAME_PREVIEW_SIZES[gameSize]);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    setGameWidthAndHeight(GAME_PREVIEW_SIZES[gameSize]);
  }, [gameSize, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    setGameWidthAndHeight({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const onWindowSizeChange = () => {
      setGameWidthAndHeight({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', onWindowSizeChange);

    return function cleanup() {
      window.removeEventListener('resize', onWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    setIframeKey(new Date().getTime()); // change the key so the iframe is refreshed when mobile mode is toggled
    toggleFSButton(isMobile);
  }, [isMobile]);

  const openFullscreen = () => {
    const iframeContainer = iframeContainerRef.current;
    if (iframeContainer) {
      try {
        const fsFn =
          // @ts-ignore
          iframeContainer.requestFullscreen ||
          // @ts-ignore
          iframeContainer.mozRequestFullScreen ||
          // @ts-ignore
          iframeContainer.webkitRequestFullScreen ||
          // @ts-ignore
          iframeContainer.msRequestFullscreen;
        fsFn.call(iframeContainer);
      } catch (e) {
        enableFakeFs(iframeContainer);
      } finally {
        toggleFSButton(false);
      }
    }
  };

  if (previewUrl) {
    const previewUrlObj = new URL(previewUrl);
    const previewUrlDomain = `${previewUrlObj.protocol}//${previewUrlObj.host}`;
    const webCamPermission = window.location.href.includes('allowWebCam=true') ? 'camera;' : '';

    let iframeVisible = false;
    if (!isMobile) {
      // on PC iframe always visible
      iframeVisible = true;
    }
    if (isMobile) {
      // on mobile we copy portal behaviour, the iframe will become visible only when you enter full screen
      iframeVisible = !showFSButton;
    }

    return (
      <>
        <div ref={iframeContainerRef}>
          {iframeVisible && (
            <StyledIframe
              key={iframeKey}
              ref={iframeRef}
              src={previewUrl}
              width={gameWidthAndHeight.width}
              height={gameWidthAndHeight.height}
              allowFullScreen
              title={`Preview ${name}`}
              allow={`${webCamPermission} accelerometer; gyroscope; microphone; clipboard-read; payment; clipboard-write self ${previewUrlDomain}`}
              onLoad={() => setQAToolContextData({ gameIframe: iframeRef.current?.contentWindow })}
            />
          )}
        </div>
        {showFSButton && (
          <StyledFsContainer>
            <StyledHeaderText>Mobile preview</StyledHeaderText>
            <Button color="primary" variant="contained" onClick={openFullscreen}>
              Open Game
              <FullscreenIcon sx={{ width: '40px', height: '40px' }} />
            </Button>
            <StyledBodyText>When you click the above button, the game will open in fullscreen.</StyledBodyText>
          </StyledFsContainer>
        )}
      </>
    );
  } else {
    return <CzyLoader />;
  }
};

export default GameIframe;

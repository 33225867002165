import * as React from 'react';
import documentUrl from '../document-url';

export const termsVersion = 'developer_terms_20240802';

export const termsUrl = documentUrl(`${termsVersion}.pdf`);

interface TermsLinkProps {
  style?: React.CSSProperties;
}

const TermsLink: React.FC<TermsLinkProps> = ({ style }) => (
  <a style={style} href={termsUrl} target="_blank" rel="noopener noreferrer">
    Terms &amp; Conditions
  </a>
);

export default TermsLink;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Earth: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6301 25H12.5C16.6421 25 20 28.3579 20 32.5V35C20 36.3807 21.1193 37.5 22.5 37.5C26.6421 37.5 30 40.8579 30 45V50C31.7265 50 33.4019 49.7812 35 49.3699V45C35 40.8579 38.3579 37.5 42.5 37.5H48.5462C49.4837 35.1839 50 32.6523 50 30C50 27.3477 49.4837 24.8161 48.5462 22.5L47.5 22.5C46.1193 22.5 45 23.6193 45 25C45 29.1421 41.6421 32.5 37.5 32.5C33.3579 32.5 30 29.1421 30 25C30 23.6193 28.8807 22.5 27.5 22.5H26.25C21.6257 22.5 17.8393 18.9128 17.5216 14.3693C14.1882 17.0339 11.7259 20.7427 10.6301 25ZM18.8891 7.59855C10.6572 11.6894 5 20.184 5 30C5 43.8071 16.1929 55 30 55C43.8071 55 55 43.8071 55 30C55 26.0097 54.0651 22.2377 52.4025 18.8912C52.4017 18.8896 52.401 18.8881 52.4002 18.8866C48.3089 10.6561 39.815 5 30 5C26.0089 5 22.2362 5.93525 18.8891 7.59855ZM22.5 11.4538V13.75C22.5 15.8211 24.1789 17.5 26.25 17.5H27.5C31.6421 17.5 35 20.8579 35 25C35 26.3807 36.1193 27.5 37.5 27.5C38.8807 27.5 40 26.3807 40 25C40 21.4527 42.4628 18.4805 45.7718 17.7001C42.1115 13.0133 36.4076 10 30 10C27.3477 10 24.8161 10.5163 22.5 11.4538ZM45.6134 42.5H42.5C41.1193 42.5 40 43.6193 40 45V47.3244C42.1586 46.0757 44.0622 44.4351 45.6134 42.5ZM25 49.3699C16.3739 47.1497 10 39.3192 10 30H12.5C13.8807 30 15 31.1193 15 32.5V35C15 39.1421 18.3579 42.5 22.5 42.5C23.8807 42.5 25 43.6193 25 45V49.3699Z"
      />
    </SvgIcon>
  );
});

export default Earth;

import { Tooltip } from '@mui/material';
import { StyledTotalStatsLabel } from '../developerSubmission.styles';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  children: React.ReactNode;
  tooltip?: string;
}

export const TotalStatsLabel: React.FC<Props> = ({ children, tooltip }) => {
  return (
    <StyledTotalStatsLabel>
      {children}
      {tooltip && (
        // `whiteSpace: 'pre-line'` allows us to use \n in the tooltip for newlines
        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}>
          <InfoIcon style={{ height: 16, width: 16, marginLeft: 4 }} />
        </Tooltip>
      )}
    </StyledTotalStatsLabel>
  );
};

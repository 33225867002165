import { Backdrop, Dialog, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import { StyledButton } from '../../../common/Styleguide/Common/Button';
import { StyledBodyText } from '../../../common/Styleguide/Common/Text';
import { HEADER_HEIGHT } from '../Header/Header';
import { useLocation } from 'react-router-dom';
import PartyingFace from '../../../common/icons/PartyingFaceIcon';

const NewUserDialog: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const location = useLocation();
  const isNewUser = location.state && (location.state as any).isNewUser;

  if (!isOpen || !isNewUser) {
    return null;
  }

  return (
    <Backdrop open={isOpen} sx={{ backgroundColor: '#1a1b28d4', top: HEADER_HEIGHT, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        PaperProps={{ sx: { maxWidth: 430, m: 0 } }}
      >
        <DialogTitle sx={{ mt: 1, pb: 0, pt: 5 }}>
          <div>
            <PartyingFace sx={{ width: 50, height: 50 }} />
          </div>
          Welcome on board!
        </DialogTitle>
        <DialogContent sx={{ px: 5, pb: 5 }}>
          <StyledBodyText color="white60" sx={{ textAlign: 'center', px: 2 }}>
            You’re all set! You can now upload your first game. We can’t wait to try it!
          </StyledBodyText>
          <StyledButton
            onClick={() => {
              setIsOpen(false);
            }}
            variant="contained"
            fullWidth
            color="purple"
            height={50}
          >
            Close
          </StyledButton>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
};

export default NewUserDialog;

import { CircularProgress, useTheme } from '@mui/material';
import * as React from 'react';
import moment from 'moment';
import { i18n } from '@lingui/core';
import { useQuery } from '@apollo/client';
import { StatsPeriod } from '../../../../../../common/components/SelectTimeframe/SelectTimeframe';
import { formatAmount, formatEuro, formatDurationAsHMS, formatPercentage } from '../../../../../../common/formatting';
import Clock from '../../../../../../common/icons/Clock';
import Users from '../../../../../../common/icons/Users';
import { MY_GAME_STATS_QUERY, MyGameStatsQuery, Submission } from '../submission-graphql';
import Play from '../../../../../../common/icons/Play';
import Money from '../../../../../../common/icons/Money';
import Star from '../../../../../../common/icons/Star';
import Mobile from '../../../../../../common/icons/Mobile';
import { TotalStatsContainer, TotalStatsNumber, StatsRow } from '../developerSubmission.styles';
import Day1 from '../../../../../../common/icons/Day1';
import Day7 from '../../../../../../common/icons/Day7';
import Refresh from '../../../../../../common/icons/Refresh';
import Feedback from '../../../../../../common/icons/Feedback';
import Users2 from '../../../../../../common/icons/User2';
import { TotalStatsLabel } from './TotalStatsLabel';
import { DeveloperSubmissionStatsMetric } from './DeveloperSubmissionStats';

interface IDeveloperSubmissionStatsProps {
  submission: Submission;
  timeframeFilter: StatsPeriod;
  selectedMetric: DeveloperSubmissionStatsMetric; // Add prop for selected metric
  onMetricClick: (metric: DeveloperSubmissionStatsMetric) => void; // Add prop for metric click handler
}

const DeveloperSubmissionStatsHeader: React.FunctionComponent<IDeveloperSubmissionStatsProps> = ({
  submission,
  timeframeFilter,
  selectedMetric,
  onMetricClick,
}) => {
  const { loading, data: totalGameData } = useQuery<MyGameStatsQuery>(MY_GAME_STATS_QUERY, {
    variables: { slug: submission.slug, ...timeframeFilter },
  });
  const { spacing } = useTheme();

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingBottom: spacing(4) }}>
        <CircularProgress />
      </div>
    );
  }
  const devStats = totalGameData?.gameBySlug.devStats;

  const getContainerStyle = (metric: string) => ({
    cursor: 'pointer',
    boxShadow: selectedMetric === metric ? '0 0 0 2px #1BECBA' : 'none',
    borderRadius: '8px',
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: spacing(2),
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
      }}
    >
      <StatsRow>
        <TotalStatsContainer style={getContainerStyle('pageviews')} onClick={() => onMetricClick('pageviews')}>
          <Play />
          <div>
            <TotalStatsNumber>{devStats?.analytics.pageviews ? formatAmount(devStats.analytics.pageviews) : 0}</TotalStatsNumber>
            <TotalStatsLabel tooltip="Total number of plays of the game, counting every time a game is loaded succesfully.">
              Total plays
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('avgTimeOnPage')} onClick={() => onMetricClick('avgTimeOnPage')}>
          <Clock />
          <div>
            <TotalStatsNumber>
              {devStats?.analytics.avgTimeOnPage ? formatDurationAsHMS(moment.duration(devStats.analytics.avgTimeOnPage, 'seconds')) : '-'}
            </TotalStatsNumber>
            <TotalStatsLabel tooltip="Average time played per play, excluding time away from the game.">Avg playtime</TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('retentionD1')} onClick={() => onMetricClick('retentionD1')}>
          <Day1 />
          <div>
            <TotalStatsNumber>
              {devStats?.analytics.retentionD1 ? `${formatPercentage(devStats.analytics.retentionD1)}%` : '-'}
            </TotalStatsNumber>
            <TotalStatsLabel tooltip="The ratio of users that play the game again 1 day after they played it for the first time.">
              Retention (D1)
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('retentionD7')} onClick={() => onMetricClick('retentionD7')}>
          <Day7 />
          <div>
            <TotalStatsNumber>
              {devStats?.analytics.retentionD7 ? `${formatPercentage(devStats.analytics.retentionD7)}%` : '-'}
            </TotalStatsNumber>
            <TotalStatsLabel tooltip="The ratio of users that play the game again 7 days after they played it for the first time.">
              Retention (D7)
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('rating')} onClick={() => onMetricClick('rating')}>
          <Star />
          <div>
            <TotalStatsNumber>
              {`${devStats?.analytics.rating ? devStats.analytics.rating.toFixed(1) : '-'}`}
              <span style={{ fontSize: 15, marginLeft: 4 }}>{`(${
                devStats?.analytics.totalVotes ? i18n.number(devStats.analytics.totalVotes, { notation: 'compact' }) : '-'
              })`}</span>
            </TotalStatsNumber>
            <TotalStatsLabel tooltip="The amount of user likes/dislikes and the corresponding rating. We don't provide time-based data for this metric.">
              Rating (+count)
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('revenue')} onClick={() => onMetricClick('revenue')}>
          <Money />
          <div>
            <TotalStatsNumber>€ {devStats?.revenueShare.total ? formatEuro(devStats.revenueShare.total) : 0}</TotalStatsNumber>
            <TotalStatsLabel tooltip="The revenue the game has generated for you.">Your revenue</TotalStatsLabel>
          </div>
        </TotalStatsContainer>
      </StatsRow>

      <StatsRow>
        <TotalStatsContainer style={getContainerStyle('users')} onClick={() => onMetricClick('users')}>
          <Users />
          <div>
            <TotalStatsNumber>{devStats?.analytics.users ? formatAmount(devStats.analytics.users) : 0}</TotalStatsNumber>
            <TotalStatsLabel tooltip="The amount of distinct players playing the game (we link logged in users using multiple devices).">
              Total players
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('returningPlayers')} onClick={() => onMetricClick('returningPlayers')}>
          <Refresh />
          <div>
            <TotalStatsNumber>
              {devStats?.analytics.returningPlayers ? `${formatPercentage(devStats.analytics.returningPlayers, true)}%` : 0}
            </TotalStatsNumber>
            <TotalStatsLabel tooltip="Ratio of players that played the game before.">% of returning users</TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('mobileUsers')} onClick={() => onMetricClick('mobileUsers')}>
          <Mobile />
          <div>
            <TotalStatsNumber>
              {devStats?.analytics.mobileUsers && devStats.analytics.users
                ? `${formatPercentage(devStats.analytics.mobileUsers / devStats.analytics.users, true)}%`
                : 0}
            </TotalStatsNumber>
            <TotalStatsLabel tooltip="Ratio of players playing on mobile or tablet.">% of mobile users</TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('avgConversion')} onClick={() => onMetricClick('avgConversion')}>
          <Users2 />
          <div>
            <TotalStatsNumber>
              {devStats?.analytics.avgConversion ? `${formatPercentage(devStats.analytics.avgConversion, true)}%` : 0}
            </TotalStatsNumber>
            <TotalStatsLabel
              tooltip={
                "Ratio of plays with a duration of at least 1 min compared to the clicks on 'Play Now' button.\nPro Tip: Reduce the initial loading size and simplify the flow to start gameplay in order to improve this ratio and lose fewer players early."
              }
            >
              Gameplay conversion
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('avgLoadingTime')} onClick={() => onMetricClick('avgLoadingTime')}>
          <Clock />
          <div>
            <TotalStatsNumber>
              <TotalStatsNumber>
                {devStats?.analytics.avgLoadingTime
                  ? `${i18n.number(devStats.analytics.avgLoadingTime, { notation: 'compact' })} sec`
                  : '-'}
              </TotalStatsNumber>
            </TotalStatsNumber>
            <TotalStatsLabel tooltip="Average time it takes between “Play now” click and the start of a play (excluding possible ad or in-game loading).">
              Avg loading Time
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>

        <TotalStatsContainer style={getContainerStyle('issues')} onClick={() => onMetricClick('issues')}>
          <Feedback />
          <div>
            {devStats?.analytics.issues ? formatAmount(devStats.analytics.issues) : 0}
            <TotalStatsLabel tooltip="Amount of issues per 1000 plays of the game. This includes progress save issues and breaking Unity errors.">
              Issues / 1k plays
            </TotalStatsLabel>
          </div>
        </TotalStatsContainer>
      </StatsRow>
    </div>
  );
};

export default DeveloperSubmissionStatsHeader;

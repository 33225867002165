export enum GameSize {
  MOBILE,
  NORMAL,
  MEDIUM,
  LARGE,
}

export const GAME_PREVIEW_SIZES = {
  [GameSize.MOBILE]: {
    width: 400,
    height: 675,
  },
  [GameSize.NORMAL]: {
    width: 922,
    height: 532,
  },
  [GameSize.MEDIUM]: {
    width: 938,
    height: 548,
  },
  [GameSize.LARGE]: {
    width: 1128,
    height: 660,
  },
};

export function getGameSizeTitle(gameSize: GameSize) {
  switch (gameSize) {
    case GameSize.MOBILE:
      return 'Mobile';
    case GameSize.NORMAL:
      return '922x487';
    case GameSize.MEDIUM:
      return '938x503';
    case GameSize.LARGE:
      return '1128x615';
  }
}

export const GAME_BAR_HEIGHT = 45;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const ArrowLeft: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33006 18.7424C8.74008 19.1124 9.37242 19.08 9.74241 18.6699C10.1124 18.2599 10.08 17.6276 9.66993 17.2576L4.95171 13L21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11L4.9517 11L9.66993 6.74241C10.08 6.37241 10.1124 5.74008 9.74241 5.33005C9.37242 4.92003 8.74008 4.88758 8.33006 5.25757L2.78909 10.2576C1.73696 11.207 1.73696 12.793 2.78909 13.7424L8.33006 18.7424Z"
      />
    </SvgIcon>
  );
});

export default ArrowLeft;

import React from 'react';
import { Grid, useTheme } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { COLORS } from '../../../../../../../common/Styleguide/Common/colors';

export type RequirementState = 'pending' | 'ok' | 'fail';

export interface Props {
  text: string;
  state: RequirementState;
  children?: React.ReactNode;
}

const Requirement: React.FC<Props> = ({ text, state, children }) => {
  const theme = useTheme();

  const color = {
    pending: theme.palette.warning.main,
    ok: theme.palette.success.main,
    fail: theme.palette.error.main,
  };

  const renderIcon = () => {
    switch (state) {
      case 'fail':
        return <HighlightOffOutlinedIcon style={{ color: COLORS.alert[100] }} />;
      case 'ok':
        return <CheckCircleOutlineOutlinedIcon style={{ color: color.ok }} />;
      case 'pending':
        return <RadioButtonUncheckedOutlinedIcon style={{ color: COLORS.white[10] }} />;
    }
  };

  return (
    <Grid container sx={{ pt: 1, pb: 1 }} spacing={1}>
      <Grid container item wrap="nowrap">
        {renderIcon()}
        <StyledBodyText sx={{ pl: 1, m: 0, fontWeight: 700 }} color="white60" variant="bodyLower">
          {text}
        </StyledBodyText>
      </Grid>
      {children}
    </Grid>
  );
};

export default Requirement;

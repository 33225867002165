import { gql } from '@apollo/client';

export const NOTICE_SNACKBAR_QUERY = gql`
  query GetNoticeSnackbar {
    notice {
      id
      notice
    }
  }
`;

export interface NoticeSnackbarQuery {
  notice: {
    id: string;
    notice: string;
  };
}

import { styled } from '@mui/material/styles';
import { COLORS } from '../../../common/Styleguide/Common/colors';

export const UserInfoContainer = styled('div')({
  display: 'flex',
});

export const UserInfoText = styled('div')({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontSize: 16,
  lineHeight: '18px',
});

export const UserInfoWelcome = styled('div')({
  fontWeight: '400',
  color: COLORS.white[100],
});

export const UserInfoName = styled('div')({
  fontWeight: '700',
  color: COLORS.white[100],
});

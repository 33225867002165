import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import QAEventListener, {
  GFEventCallback,
  RequestUserTokenResponseData,
  RequestXsollaUserTokenResponseData,
  ShowAuthPromptResponseData,
} from '../../QAEventListener';
import { QAToolContext } from '../../QAToolProvider';
import { StyledFakeUserSwitch } from './FakeUser.styles';
import { StyledButton } from '../../../../../../../common/Styleguide/Common/Button';
import { CustomSelect } from '../../../../../../../common/Styleguide/Common/Select';
import { Option } from '@mui/base';
import { useLazyQuery } from '@apollo/client';
import {
  XsollaUserTokenQuery,
  XsollaUserTokenQueryInput,
  XSOLLA_USER_TOKEN_QUERY,
  QA_TOOL_USER_TOKEN_QUERY,
  QaToolUserTokenQuery,
  QaToolUserTokenQueryInput,
} from '../../../../../../../common/userportal/qaToolQueries';
import { Callback, FakeUserSelectKeys, FakeUserSelectValues, FakeUsers, PortalUserEventData, UserEventType } from './FakeUser.types';

interface Props {
  submissionId?: string; // present only when QA tool is linked to a game (not standalone)
  showOptionsModal: boolean;
  closeOptionsModal: () => void;
}

/**
 * Intercepts various user requests from gameframe (get user, get token, get xsolla token, auth prompt, link account, etc) and returns fake data for them.
 * These requests usually should reach portal, but this component simulates the portal for testing purposes.
 * Also provides the modal for customizing the fake user behaviour.
 */
const FakeUser: React.FC<Props> = ({ showOptionsModal, closeOptionsModal, submissionId }: Props) => {
  const paramsFromUrl = new URLSearchParams(window.location.search);
  const defaultUser = (paramsFromUrl.get('defaultUser') as FakeUserSelectKeys) || 'user1'; // also kept for url params, to keep it after refresh
  const [activeUser, setActiveUser] = useState<FakeUserSelectKeys>(defaultUser);
  const [userCancelsAuthPrompt, setUserCancelsAuthPrompt] = useState(false);
  const { gameIframe } = useContext(QAToolContext);
  const [xsollaTokenQueryFetch] = useLazyQuery<XsollaUserTokenQuery, XsollaUserTokenQueryInput>(XSOLLA_USER_TOKEN_QUERY);
  const [userTokenQueryFetch] = useLazyQuery<QaToolUserTokenQuery, QaToolUserTokenQueryInput>(QA_TOOL_USER_TOKEN_QUERY);

  useEffect(() => {
    const paramsFromUrl = new URL(window.location.href);
    paramsFromUrl.searchParams.set('defaultUser', activeUser);
    window.history.replaceState(null, '', paramsFromUrl.toString());
  }, [activeUser]);

  useEffect(() => {
    gameIframe?.postMessage({ type: 'userAccountAvailableResponse' }, '*');
  }, [gameIframe]);

  const onUserSelect = (user: FakeUserSelectKeys) => {
    const loggedOutToLoggedIn = activeUser === 'userLoggedOut' && user !== 'userLoggedOut';
    setActiveUser(user);

    // Send the login event when switching from the logged out user to a logged in user
    if (loggedOutToLoggedIn) {
      sendLogInEvent(user);
      closeOptionsModal();
      return;
    }
    // Otherwise page refresh is required so the SDK user token is cleaned when switching between two logged in users
    var url = new URL(window.location.href);
    url.searchParams.set('defaultUser', user);
    window.location.href = url.toString();
  };

  function userToPortalEvent(eventType: UserEventType, activeUser: FakeUserSelectKeys) {
    let message: PortalUserEventData = { type: eventType, data: {} }; // gf treats empty data as a logged out user
    if (activeUser !== 'userLoggedOut') {
      const user = FakeUsers[activeUser];
      message.data.userId = user.id;
      message.data.userData = { profilePictureUrl: user.profilePictureUrl, username: user.username };
    }

    return message;
  }

  const sendInitialGfInitEvent = useCallback(
    (sourceWindow: Window) => {
      sourceWindow.postMessage(
        {
          type: 'gfInit',
          data: {
            analyticInfo: null,
            theatreModeAvailable: false,
            wasUserLoggedIn: false,
            userAccountAvailable: true,
            userExpected: false,
          },
        },
        '*',
      );
      const message = userToPortalEvent('userPortalInfoSync', activeUser);
      sourceWindow.postMessage(message, '*');
    },
    [activeUser],
  );

  const sendLogInEvent = useCallback(
    (user: FakeUserSelectKeys) => {
      if (user === 'userLoggedOut') {
        return;
      }
      const message = userToPortalEvent('userLoggedIn', user);
      gameIframe?.postMessage(message, '*');
    },
    [gameIframe],
  );

  /**
   * If the user is logged in, the SDK won't even fire this event, it will just instantly reply with "userAlreadySignedIn" error.
   * So the method here expects the "Logged out" user to be selected to function properly.
   */
  const handleShowAuthPrompt = useCallback(
    (sourceWindow: Window) => {
      const cb: Callback<ShowAuthPromptResponseData> = (data) => {
        sourceWindow.postMessage(data, '*');
      };

      if (activeUser !== 'userLoggedOut') {
        return console.error(`To simulate the auth prompt in QA tool please select the "Logged out" user`);
      }

      if (userCancelsAuthPrompt) {
        return cb({
          type: 'showAuthPromptResponse',
          data: { error: { code: 'userCancelled', message: 'User cancelled the auth prompt' } },
        });
      }

      setActiveUser('user1');
      sendLogInEvent('user1');
      return cb({ type: 'showAuthPromptResponse', data: { user: FakeUsers.user1 } });
    },
    [activeUser, sendLogInEvent, userCancelsAuthPrompt],
  );

  const handleUserTokenRequest = useCallback(
    async (sourceWindow: Window) => {
      const message: RequestUserTokenResponseData = { type: 'requestUserTokenResponse', data: {} };

      if (activeUser === 'userLoggedOut') {
        // normally this will not happen since the SDK will prevent token generation also with an error if the user is not signed in
        console.error('Please select an authenticated user to generate tokens in QA tool');
        message.data = { error: { code: 'userNotAuthenticated', message: 'The user is not authenticated' } };
        return sourceWindow.postMessage(message, '*');
      }

      const response = await userTokenQueryFetch({
        variables: { fakeUser: FakeUsers[activeUser] },
        fetchPolicy: 'network-only',
      });

      const token = response.data?.userTokenQaTool?.token;
      const expiresIn = response.data?.userTokenQaTool?.expiresIn;

      if (response.error || !token) {
        console.error('User token retrieve error', response);
        message.data = { error: { code: 'unexpectedError', message: 'Failed to retrieve user token' } };
      } else {
        message.data = { token, expiresIn };
      }
      sourceWindow.postMessage(message, '*');
    },
    [activeUser, userTokenQueryFetch],
  );

  const handleXsollaTokenRequest = useCallback(
    async (sourceWindow: Window) => {
      const message: RequestXsollaUserTokenResponseData = {
        type: 'requestXsollaUserTokenResponse',
        data: {},
      };

      if (!submissionId) {
        message.data.error = {
          code: 'notAvailableInStandaloneQaTool',
          message:
            'To obtain the Xsolla token a submission ID is required, which is not available in standalone QA Tool. Please access the QA Tool from the game submission.',
        };
        sourceWindow.postMessage(message, '*');
        return;
      }

      if (activeUser === 'userLoggedOut') {
        message.data.error = { code: 'userNotAuthenticated', message: 'The user is not authenticated' };
        sourceWindow.postMessage(message, '*');
        return;
      }

      const userId = FakeUsers[activeUser].id;
      const response = await xsollaTokenQueryFetch({
        variables: { fakeUserId: userId, submissionId: submissionId },
        fetchPolicy: 'network-only',
      });
      const xsollaUserToken = response.data?.xsollaUserTokenQaTool;

      if (response.error || !xsollaUserToken) {
        console.error('Xsolla user token retrieve error', response);
        message.data = { error: { code: 'unexpectedError', message: 'Failed to retrieve Xsolla user token' } };
      } else {
        message.data = { token: xsollaUserToken.token, expiresIn: xsollaUserToken.expiresIn };
      }
      sourceWindow.postMessage(message, '*');
    },
    [activeUser, submissionId, xsollaTokenQueryFetch],
  );

  const onGFEvent: GFEventCallback = useCallback(
    (event, _data, msg) => {
      switch (event) {
        case 'requestUserToken':
          handleUserTokenRequest(msg?.source as Window);
          break;
        case 'requestGfInit':
          sendInitialGfInitEvent(msg?.source as Window);
          break;
        case 'showAuthPrompt':
          handleShowAuthPrompt(msg?.source as Window);
          break;
        case 'requestXsollaUserToken':
          handleXsollaTokenRequest(msg?.source as Window);
          break;
        case 'requestUserAccountAvailable':
          (msg?.source as Window).postMessage({ type: 'userAccountAvailableResponse' }, '*');
          break;
      }
    },
    [handleShowAuthPrompt, handleUserTokenRequest, handleXsollaTokenRequest, sendInitialGfInitEvent],
  );

  return (
    <>
      <QAEventListener onGFEvent={onGFEvent} />
      <Dialog open={showOptionsModal} onClose={closeOptionsModal}>
        <DialogTitle>Customize user simulation</DialogTitle>
        <DialogContent sx={{ width: 350 }}>
          <Box display={'flex'} alignItems="center" justifyContent={'space-between'} sx={{ mb: 1 }}>
            <Typography>Active user</Typography>
            <CustomSelect value={activeUser} onChange={(_: any, newValue: any) => onUserSelect(newValue)} style={{ width: 100 }}>
              {(Object.keys(FakeUserSelectValues) as FakeUserSelectKeys[]).map((value) => (
                <Option key={value} value={value}>
                  {FakeUserSelectValues[value]}
                </Option>
              ))}
            </CustomSelect>
          </Box>
          {activeUser !== 'userLoggedOut' && <Typography variant="caption">Changing the user will reload the page.</Typography>}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>User cancels auth prompt</Typography>
            <StyledFakeUserSwitch
              checked={userCancelsAuthPrompt}
              onChange={(event) => {
                setUserCancelsAuthPrompt(event.target.checked);
              }}
            />
          </Box>
          <Typography variant="caption">
            The auth prompt is present only on the main website, so to simulate it here, select the "Logged out" user and call the
            showAuthPrompt() method from the SDK. "User 1" will be automatically signed in and returned. Use the above toggle to simulate
            the cancel behaviour.
          </Typography>
          {activeUser === 'userLoggedOut' && <Typography variant="caption">User is logged out, auth prompt will return user 1</Typography>}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={closeOptionsModal} variant="contained" color="purple" height={50} sx={{ minWidth: 208, mr: 2 }}>
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FakeUser;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const TikTok: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 19">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.003 7.92466C14.4917 7.92826 13.0175 7.45898 11.7886 6.58311V12.6917C11.7882 13.8231 11.4409 14.9274 10.7931 15.8569C10.1454 16.7864 9.22803 17.4969 8.16379 17.8934C7.09955 18.2898 5.93912 18.3532 4.83767 18.0753C3.73623 17.7973 2.74626 17.1911 2.00015 16.3378C1.25403 15.4845 0.787339 14.4248 0.662465 13.3002C0.537591 12.1757 0.76049 11.04 1.30136 10.0451C1.84223 9.05006 2.67528 8.2432 3.68913 7.73236C4.70298 7.22151 5.84931 7.03103 6.97482 7.18638V10.2588C6.45978 10.0975 5.90673 10.1023 5.39463 10.2727C4.88253 10.443 4.43758 10.7701 4.12332 11.2072C3.80906 11.6444 3.64156 12.1692 3.64474 12.7068C3.64792 13.2444 3.82162 13.7672 4.14103 14.2006C4.46044 14.634 4.90923 14.9559 5.4233 15.1202C5.93738 15.2845 6.49046 15.2829 7.00355 15.1155C7.51664 14.9482 7.96351 14.6237 8.28034 14.1884C8.59717 13.7531 8.76776 13.2293 8.76775 12.6917V0.75H11.7886C11.7865 1.004 11.8079 1.25766 11.8524 1.50776C11.9574 2.06608 12.1757 2.59721 12.4939 3.06866C12.8121 3.54011 13.2235 3.94197 13.703 4.24966C14.3853 4.69881 15.1851 4.93821 16.003 4.93804V7.92466Z"
      />
    </SvgIcon>
  );
});

export default TikTok;

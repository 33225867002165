import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { getAuth } from '../../../../common/firebase';
import { Location } from 'history';
import { Checkbox, Divider } from '@mui/material';
import Space from '../../../../common/Styleguide/Space';
import { waitOnAuthChange } from '../../../../common/Session/firebase';
import { apolloClient } from '../../../../common/graphql';
import withSession, { WithSession } from '../../../../common/Session/withSession';
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import type { FirebaseError } from 'firebase/app';
import { sendUserId, trackRegistrationFinished } from '../../../../common/analytics/analytics-helper';
import { Box } from '@mui/system';
import { COLORS } from '../../../../common/Styleguide/Common/colors';
import { StyledBodyText, StyledHeaderText } from '../../../../common/Styleguide/Common/Text';
import { StyledButton, StyledLoadingButton } from '../../../../common/Styleguide/Common/Button';
import { DefaultInput, StyledInput } from '../../../../common/Styleguide/Common/Input';
import ChevronRight from '@mui/icons-material/ChevronRight';
import GoogleColoredIcon from '../../../../common/icons/GoogleColoredIcon';
import ErrorMessage from './ErrorMessage';
import { createAccount } from '../Profile/profile-graphql';
import AuthenticateShell from './AuthenticateShell';
import { StyledAuthenticateBottom, StyledAuthenticateFormControlLabel, StyledAuthenticatePolicyText } from './authenticate.styles';
import { isValidEmail } from '../../../../common/validations';
import TermsLink from '../../../../common/components/TermsLink';
import PrivacyLink from '../../../../common/components/PrivacyLink';

const SIGNIN_REDIRECT_TIMEOUT_MS = 2000;

export enum AuthenticateVersionOptions {
  Login,
  Register,
}

export interface AuthenticateRouteProps extends RouteComponentProps<{}> {
  from?: Location;
}

export interface AuthenticateProps {
  version: AuthenticateVersionOptions;
  from?: string;
}

type AuthenticateWithRouteProps = AuthenticateRouteProps & AuthenticateProps & WithSession;

export interface AuthenticateState {
  email?: string;
  password?: string;
  legalAccepted: boolean;
  error?: FirebaseError | string;
  emailError?: string;
  googleError?: FirebaseError;
  loading: boolean;
}

export const AFTER_LOGIN_ROUTE = '/games';

class Authenticate extends React.Component<AuthenticateWithRouteProps, AuthenticateState> {
  private googleAuthProvider: GoogleAuthProvider;

  constructor(public props: AuthenticateWithRouteProps) {
    super(props);
    this.state = {
      legalAccepted: false,
      loading: false,
    };
    this.googleAuthProvider = new GoogleAuthProvider();
    this.doGoogleSignIn = this.doGoogleSignIn.bind(this);
  }

  componentDidMount() {
    if (this.props.session.isLoggedIn()) {
      this.props.history.push('/profile');
    }
  }

  componentDidUpdate(_prevProps: AuthenticateWithRouteProps) {
    if (_prevProps.version !== this.props.version) {
      this.setState({ error: undefined, googleError: undefined });
    }
  }

  render() {
    const { version } = this.props;

    return (
      <>
        <AuthenticateShell>
          <StyledHeaderText variant="h2" sx={{ fontSize: 32, m: 0 }}>
            {version === AuthenticateVersionOptions.Register && 'Join CrazyGames'}
            {version === AuthenticateVersionOptions.Login && 'Log In'}
          </StyledHeaderText>
          {this.renderSSOForm()}
          {this.renderEmailPasswordForm()}

          <Box sx={{ height: 30 }}></Box>
          {this.renderBottom()}
        </AuthenticateShell>
      </>
    );
  }

  private changeEmail = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: ev.target.value });
  };

  private changePassword = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: ev.target.value });
  };

  private changeLegalAccepted = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ legalAccepted: ev.target.checked });
  };

  private renderSSOForm() {
    return (
      <>
        {this.renderGoogleButton()}
        <Box sx={{ position: 'relative', py: 1.5 }}>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ position: 'absolute', top: -15, px: 2, background: COLORS.black[70] }}>
              <StyledBodyText color="white60">or use your email</StyledBodyText>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  private renderGoogleButton() {
    return (
      <Space variant="ABOVE_AND_BELOW">
        <StyledButton variant="contained" color="white" fullWidth={true} height={50} onClick={this.doGoogleSignIn}>
          <GoogleColoredIcon />
          &nbsp; Continue with Google
        </StyledButton>
        {this.renderError(this.state.googleError)}
      </Space>
    );
  }

  private renderEmailPasswordForm() {
    const { version } = this.props;
    const { password, email, emailError } = this.state;
    return (
      <form>
        <Box sx={{ textAlign: 'start', py: 1 }}>
          <DefaultInput
            header="Email"
            placeholder="example@crazygames.com"
            onChange={this.changeEmail}
            error={!!emailError}
            errorText={emailError || 'Invalid email'}
            onBlur={() => {
              if (!isValidEmail(email || '')) {
                this.setState({ emailError: 'Invalid email' });
              } else {
                this.setState({ emailError: undefined });
              }
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
            <StyledBodyText variant="h3" sx={{ m: 0 }}>
              Password
            </StyledBodyText>
            {version === AuthenticateVersionOptions.Login && (
              <StyledBodyText variant="h3" sx={{ m: 0 }}>
                <Link to="/password-reset">
                  <StyledButton sx={{ display: 'contents' }}>I forgot my password</StyledButton>
                </Link>
              </StyledBodyText>
            )}
          </Box>

          <StyledInput
            type="password"
            autoComplete="false"
            placeholder="min. 6 characters"
            error={password ? password.length < 6 : false}
            onChange={this.changePassword}
          />
          <StyledBodyText variant="bodyLower2" color="error" sx={{ m: 0, height: 12 }}>
            {password && password.length < 6 && 'Min. 6 characters'}
          </StyledBodyText>
        </Box>

        {version === AuthenticateVersionOptions.Register && (
          <StyledAuthenticateFormControlLabel
            control={
              <Checkbox sx={{ p: 0, height: 22, width: 22 }} checked={this.state.legalAccepted} onChange={this.changeLegalAccepted} />
            }
            label={
              <StyledAuthenticatePolicyText variant="bodyLower" color="white50">
                I accept the&nbsp;
                <TermsLink style={{ textDecoration: 'underline', color: 'inherit' }} />
                &nbsp;and&nbsp;
                <PrivacyLink style={{ textDecoration: 'underline', color: 'inherit' }} />
              </StyledAuthenticatePolicyText>
            }
          />
        )}

        {this.renderError(this.state.error)}

        <StyledLoadingButton
          fullWidth
          loading={this.state.loading}
          height={50}
          variant="contained"
          disabled={version === AuthenticateVersionOptions.Register ? this.isRegisterButtonDisabled() : false}
          onClick={() => {
            if (version === AuthenticateVersionOptions.Login) {
              this.doEmailPasswordSignIn();
              return;
            }
            if (version === AuthenticateVersionOptions.Register) {
              this.doEmailPasswordRegister();
              return;
            }
          }}
        >
          {version === AuthenticateVersionOptions.Register && 'Join'}
          {version === AuthenticateVersionOptions.Login && 'Log In'}
        </StyledLoadingButton>
      </form>
    );
  }

  private isRegisterButtonDisabled(): boolean {
    if (!this.state.legalAccepted) {
      return true;
    }
    return false;
  }

  private renderBottom() {
    const { version } = this.props;
    return (
      <StyledAuthenticateBottom>
        {version === AuthenticateVersionOptions.Register && <>{this.renderLogInLink()}</>}
        {version === AuthenticateVersionOptions.Login && <>{this.renderRegisterLink()}</>}
      </StyledAuthenticateBottom>
    );
  }

  private renderRegisterLink() {
    return (
      <StyledBodyText sx={{ m: 0 }}>
        No account yet? &nbsp;&nbsp;
        <Link to={{ pathname: '/register', state: this.props.location.state }}>
          <StyledButton sx={{ display: 'contents' }}>
            Join now
            <ChevronRight sx={{ position: 'absolute', mt: 0.25 }} />
          </StyledButton>
        </Link>
      </StyledBodyText>
    );
  }

  private renderLogInLink() {
    return (
      <StyledBodyText sx={{ m: 0 }}>
        Already have an account? &nbsp;&nbsp;
        <Link to={{ pathname: '/login', state: this.props.location.state }}>
          <StyledButton sx={{ display: 'contents' }}>
            Sign in
            <ChevronRight sx={{ position: 'absolute', mt: 0.25 }} />
          </StyledButton>
        </Link>
      </StyledBodyText>
    );
  }

  private async doEmailPasswordRegister() {
    const email = this.state.email;
    const password = this.state.password;
    console.log('[Authenticate] registering using email/password', email);

    // Only continue if both fields were filled in
    if (!(email && password)) {
      return;
    }

    if (!this.state.legalAccepted) {
      return;
    }

    this.setState({ loading: true });
    createUserWithEmailAndPassword(getAuth(), email, password)
      .then(this.finishSignIn)
      .catch((e) => {
        console.error('[Authenticate] unable to complete email-password sign-in: [%s] %s', e.code, e.message);
        this.setState({ error: e, loading: false });
      });
  }

  private async doEmailPasswordSignIn() {
    const email = this.state.email;
    const password = this.state.password;
    console.log('[Authenticate] signing in using email/password', email);

    // Only continue if both fields were filled in
    if (!(email && password)) {
      return;
    }
    this.setState({ loading: true });
    signInWithEmailAndPassword(getAuth(), email, password)
      .then(this.finishSignIn)
      .catch((e) => {
        console.error('[Authenticate] unable to complete email-password sign-in: [%s] %s', e.code, e.message);
        this.setState({ error: e, loading: false });
      });
  }

  private async doGoogleSignIn() {
    signInWithPopup(getAuth(), this.googleAuthProvider)
      .then(this.finishSignIn)
      .catch((e) => {
        if (e.code !== 'auth/popup-closed-by-user') {
          console.error('[Authenticate] unable to complete google sign-in: [%s] %s', e.code, e.message);
          this.setState({ googleError: e, loading: false });
        }
      });
  }

  private finishSignIn = async (cred: UserCredential): Promise<void> => {
    try {
      const session = await waitOnAuthChange(SIGNIN_REDIRECT_TIMEOUT_MS);
      if (!session.isLoggedIn()) {
        throw new Error('[Authenticate] session changed but no session was set');
      }
      sendUserId(cred.user.uid);
      const additionalInfo = getAdditionalUserInfo(cred);
      const isNewUser = additionalInfo?.isNewUser;
      if (isNewUser) {
        await createAccount();
        trackRegistrationFinished();
      }
      await apolloClient.resetStore();
      console.log('[Authenticate] user is logged in', session.getUser().email);
      this.setState({ error: undefined, loading: false });

      const redirectPathname: string = this.getRedirectRoute() || AFTER_LOGIN_ROUTE;
      const redirectTo = {
        pathname: redirectPathname,
        state: {
          isNewUser: isNewUser,
        },
      };
      this.props.history.push(redirectTo);
    } catch (err) {
      this.setState({ error: 'Oops! something went wrong. Please contact our support.', loading: false });
      console.error('[Authenticate] finish sign in failed', err);
      return Promise.reject(err);
    }
  };

  private getRedirectRoute = (): string | undefined => {
    return (this.props.location?.state as any)?.redirectRoute;
  };

  private renderError(error?: FirebaseError | string) {
    if (error) {
      return <ErrorMessage error={typeof error === 'string' ? error : error.message} />;
    }
    return null;
  }
}

export default withRouter(withSession<AuthenticateWithRouteProps>(Authenticate));

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/id';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/ru';
import 'moment/locale/pt';
import 'moment/locale/se';
import 'moment/locale/nb';
import 'moment/locale/pl';
import 'moment/locale/fi';
import 'moment/locale/ro';
import 'moment/locale/de';
import 'moment/locale/uk';
import 'moment/locale/el';
import 'moment/locale/da';
import 'moment/locale/cs';
import 'moment/locale/hu';
import 'moment/locale/tr';
import 'moment/locale/ar';
import 'moment/locale/vi';
import 'moment/locale/th';

// when you import a locale it appears to be set globally
// so we import them here and reset the default
moment.locale('en');

import React from 'react';
import { ROLE_V2 } from '../../../common/roles';

export interface RolesContextType {
  roles: ROLE_V2[];
}

const RolesContext = React.createContext<RolesContextType>({
  roles: [],
});

export default RolesContext;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Rise: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5 20C31.1193 20 30 18.8807 30 17.5C30 16.1193 31.1193 15 32.5 15H52.5C53.8807 15 55 16.1193 55 17.5V37.5C55 38.8807 53.8807 40 52.5 40C51.1193 40 50 38.8807 50 37.5V23.5355L34.2678 39.2678C33.2915 40.2441 31.7085 40.2441 30.7322 39.2678L22.5 31.0355L9.26777 44.2678C8.29146 45.2441 6.70854 45.2441 5.73223 44.2678C4.75592 43.2915 4.75592 41.7085 5.73223 40.7322L20.7322 25.7322C21.7085 24.7559 23.2915 24.7559 24.2678 25.7322L32.5 33.9645L46.4645 20H32.5Z"
      />
    </SvgIcon>
  );
});

export default Rise;

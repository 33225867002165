import * as React from 'react';
import { IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import withSession from '../Session/withSession';
import { SessionContextData } from '../Session/SessionContext';
import { downloadFileFromBlob } from '../../common/download';

export interface DownloadProps {
  session: SessionContextData;
  uploadId: string;
  getFileDownloadUrl: (uploadId: string) => Promise<string>;
  filename: string;
}

class Download extends React.Component<DownloadProps> {
  render() {
    return (
      <IconButton onClick={this.downloadFile} size="large">
        <CloudDownloadIcon />
      </IconButton>
    );
  }

  private downloadFile = async () => {
    const { session, filename } = this.props;
    if (!session.isLoggedIn()) {
      throw new Error('Not Authenticated');
    }

    try {
      const url = await this.requestS3DownloadUrl();
      if (!url) {
        throw new Error('Cannot download the file');
      }
      const response = await fetch(url);
      const blobResponse = await response.blob();

      downloadFileFromBlob(blobResponse, filename);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  private requestS3DownloadUrl = async (): Promise<string> => {
    const { uploadId } = this.props;
    const resp = await this.props.getFileDownloadUrl(uploadId);
    return resp;
  };
}

export default withSession<DownloadProps>(Download);

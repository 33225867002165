import 'react-app-polyfill/stable';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App/App';
import { appName, appVersion } from './common/config';
import './moment';
import { i18n } from '@lingui/core';

import 'typeface-roboto';
import 'font-awesome/css/font-awesome.css';
import 'loaders.css/loaders.min.css';
import 'cropperjs/dist/cropper.css';

import './index.css';
import './common/firebase';
import './common/sentry';
import './common/analytics/analytics';
import { initializeFirebaseSession } from './common/Session/firebase';

console.info(`${appName}@${appVersion}`);

initializeFirebaseSession();

i18n.activate('en');

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

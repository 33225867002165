import { Tab } from '@mui/material';
import { styled, Theme } from '@mui/system';
import { COLORS } from '../../../../../common/Styleguide/Common/colors';

export const StyledTab = styled(Tab)({
  fontSize: 20,
  textTransform: 'none',
});

export const StyledContainerHeader = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  background: COLORS.black[70],
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  boxShadow: 'inset 0px -2px 0px #313353',
  paddingLeft: spacing(2.5),
}));

export const StatsRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px', // Adjust the spacing between rows as needed
});

export const TotalStatsContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  flex: 1,
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& svg': {
    width: 35,
    height: 35,
    marginRight: spacing(1.5),
  },
}));

export const TotalStatsNumber = styled('div')({
  fontWeight: 400,
  fontSize: 20,
  lineHeight: '135%',
});

export const StyledTotalStatsLabel = styled('div')({
  fontWeight: 500,
  fontSize: 12,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  color: COLORS.white[50],
  lineHeight: '135%',
  display: 'flex',
  alignItems: 'center',
});

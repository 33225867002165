export interface MailerOptions {
  /**
   * Email address of the receiver
   * e.g.: 'raf@crazygames.com'
   */
  to?: MailReceiver;

  /**
   * Subject of the email
   * e.g.: 'Game Submission'
   */
  subject: string;
  /**
   * Body of the email. String can contain newlines.
   */
  body?: string;
}

export enum MailReceiver {
  submissions = 'submissions@crazygames.com',
  technicalSupport = 'technical-support@crazygames.com',
  business = 'business@crazygames.com',
  finance = 'finance@crazygames.com',
}

import { Divider, useTheme } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import SelectTimeframe, { StatsPeriod } from '../../../../../../common/components/SelectTimeframe/SelectTimeframe';
import { convertPeriodToOption } from '../../../../../../common/datetime';
import { DeveloperGameStatsAnalytics } from '../../Games/userDeveloperSubmission-graphql';
import { Submission } from '../submission-graphql';
import DeveloperSubmissionStatsDailyBar from './DeveloperSubmissionStatsDailyBar';
import DeveloperSubmissionStatsHeader from './DeveloperSubmissionStatsHeader';

interface IDeveloperSubmissionStatsProps {
  submission: Submission;
}

export type DeveloperSubmissionStatsMetric = keyof DeveloperGameStatsAnalytics | 'revenue';

const period = 'LAST_6_MONTHS';
const DEFAULT_TIMEFRAME: StatsPeriod = { period, preDefinedTimeframe: true, options: convertPeriodToOption(period) };

const DeveloperSubmissionStats: React.FunctionComponent<IDeveloperSubmissionStatsProps> = ({ submission }) => {
  const [timeframeFilter, setTimeframeFilter] = React.useState<StatsPeriod>(DEFAULT_TIMEFRAME);
  const [selectedMetric, setSelectedMetric] = React.useState<DeveloperSubmissionStatsMetric>('pageviews');

  const { spacing } = useTheme();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: spacing(2) }}>
        <div style={{ padding: spacing(2) }}>
          <SelectTimeframe
            minDate={moment.utc('2023-09-01').endOf('day')}
            maxDate={moment.utc()}
            hideLabel
            initialPeriod={timeframeFilter.period}
            onChange={(newTimeframe) => setTimeframeFilter(newTimeframe)}
          />
        </div>
      </div>

      <DeveloperSubmissionStatsHeader
        submission={submission}
        timeframeFilter={timeframeFilter}
        selectedMetric={selectedMetric}
        onMetricClick={setSelectedMetric}
      />
      <Divider />
      <DeveloperSubmissionStatsDailyBar timeframeFilter={timeframeFilter} submission={submission} selectedMetric={selectedMetric} />
    </div>
  );
};

export default DeveloperSubmissionStats;

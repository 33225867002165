import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import CrazyGamesLogo from '../../../../common/components/CrazyGamesLogo/CrazyGamesLogo';
import { Divider } from '@mui/material';
import DevportalIcon from '../../../../common/icons/DevportalIcon';
import { COLORS } from '../../../../common/Styleguide/Common/colors';

export const HeaderIcon: React.FC = () => {
  return (
    <>
      <Box sx={{ mr: 3 }}>
        <Link to="/">
          <CrazyGamesLogo />
        </Link>
      </Box>
      <Divider orientation="vertical" flexItem sx={{ mt: -1, borderColor: COLORS.black[60] }} />
      <Box sx={{ ml: 4 }}>
        <DevportalIcon />
      </Box>
    </>
  );
};

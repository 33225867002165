import { RequirementState } from './Requirement';
import { YesNoRequirementState } from './YesNoRequirement';
import { MainRequirement, mainRequirementsText } from './MainRequirements';
import {
  SDKFeatures,
  sdkFeaturesArray,
  sdkRequirementsText,
  sdkYesNnRequirementsText,
  SDKYesNoFeatures,
  sdkYesNoFeaturesArray,
} from './SDKRequirements';

type SDKRequirements = Record<SDKFeatures, RequirementState>;
type SDKYesNoRequirement = Record<SDKYesNoFeatures, YesNoRequirementState>;

export function getUnfulfilledMainRequirements(
  loading: RequirementState,
  displaysCorrectly: YesNoRequirementState,
): { key: MainRequirement; name: string }[] {
  const unfulfilledMainRequirements: { key: MainRequirement; name: string }[] = [];
  if (loading !== 'ok') {
    unfulfilledMainRequirements.push({ key: 'isLoading', name: mainRequirementsText['isLoading'] });
  }
  if (displaysCorrectly !== 'ok') {
    unfulfilledMainRequirements.push({ key: 'displaysCorrectly', name: mainRequirementsText['displaysCorrectly'] });
  }

  return unfulfilledMainRequirements;
}
export function getFailedSDKFeatures(sdkRequirements: SDKRequirements, sdkYesNoRequirements: SDKYesNoRequirement) {
  return filterSDKFeatures(sdkRequirements, sdkYesNoRequirements, (state) => ['fail'].includes(state));
}
export function getMissingSDKFeatures(sdkRequirements: SDKRequirements, sdkYesNoRequirements: SDKYesNoRequirement) {
  let features = filterSDKFeatures(sdkRequirements, sdkYesNoRequirements, (state) => ['pending', 'triggered'].includes(state));
  return features;
}
export function getDetectedSDKFeatures(sdkRequirements: SDKRequirements, sdkYesNoRequirements: SDKYesNoRequirement) {
  return filterSDKFeatures(sdkRequirements, sdkYesNoRequirements, (state) => state !== 'pending');
}
function filterSDKFeatures(
  sdkRequirements: SDKRequirements,
  sdkYesNoRequirements: SDKYesNoRequirement,
  stateFilter: (state: RequirementState | YesNoRequirementState) => boolean,
) {
  const sdkFeaturesFiltered = sdkFeaturesArray
    .filter((key) => stateFilter(sdkRequirements[key]))
    .map((key) => ({ key, name: sdkRequirementsText[key].name }));

  const YesNoSDKFeaturesFiltered = sdkYesNoFeaturesArray
    .filter((key) => stateFilter(sdkYesNoRequirements[key]))
    .map((key) => ({ key, name: sdkYesNnRequirementsText[key].name }));

  return [...sdkFeaturesFiltered, ...YesNoSDKFeaturesFiltered];
}

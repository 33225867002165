import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const ArrowRight: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0581 4.38132C12.7164 4.07299 12.1895 4.10004 11.8812 4.44172C11.5728 4.78341 11.5999 5.31036 11.9416 5.61869L15.8734 9.16666H2.49984C2.0396 9.16666 1.6665 9.53976 1.6665 10C1.6665 10.4602 2.0396 10.8333 2.49984 10.8333H15.8734L11.9416 14.3813C11.5999 14.6897 11.5728 15.2166 11.8812 15.5583C12.1895 15.9 12.7164 15.927 13.0581 15.6187L17.6756 11.452C18.5524 10.6609 18.5524 9.33916 17.6756 8.54799L13.0581 4.38132Z"
      />
    </SvgIcon>
  );
});

export default ArrowRight;

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SubmissionLoader from './SubmissionLoader';
import CompletedProfileRoute from '../../../Session/CompletedProfileRoute';

export const submissionRoutePath = '/games/:id';

export interface SubmissionRouteParams {
  id: string;
}

export const SubmissionRoute: React.FC<RouteComponentProps<SubmissionRouteParams>> = (route) => {
  return <SubmissionLoader submissionId={route.match.params.id} />;
};

export const submissionRoute = <CompletedProfileRoute path={submissionRoutePath} component={SubmissionRoute} />;

export default submissionRoute;

import React from 'react';
import { SubmissionContext, SubmissionContextData } from './SubmissionProvider';

export interface WithSubmission extends SubmissionContextData {}

function withSubmission<P extends WithSubmission>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Pick<P, Exclude<keyof P, keyof WithSubmission>>> {
  const wrapped = (props: any) => (
    <SubmissionContext.Consumer>
      {(contextProps: SubmissionContextData) => {
        return <WrappedComponent {...contextProps} {...props} />;
      }}
    </SubmissionContext.Consumer>
  );
  return wrapped;
}

export default withSubmission;

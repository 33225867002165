import { gql } from '@apollo/client';
import { mutate } from '../../../../../../common/graphql';
import { GET_MY_SUBMISSION_QUERY } from '../submission-graphql';

const getSubmissionMutationInvalidations = (id: string) => [{ query: GET_MY_SUBMISSION_QUERY, variables: { id: id } }];
interface DeveloperSubmissionUpdateStatusInput {
  id: string;
}

interface DeveloperNotifySubmissionResubmitIframeInput {
  id: string;
  updateNoteText: string;
}

const UPDATE_SUBMISSION_STATUS_TO_SYNC = gql`
  mutation submissionUpdateStatusToSync($input: DeveloperSubmissionUpdateStatusToSyncInput!) {
    developerSubmissionUpdateStatusToSync(input: $input) {
      id
    }
  }
`;

interface SubmissionUpdateStatusToSyncMutation {
  developerSubmissionUpdateStatusToSync: {
    id: string;
  };
}

export function updateSubmissionStatusToSync(input: DeveloperSubmissionUpdateStatusInput) {
  return mutate<DeveloperSubmissionUpdateStatusInput, SubmissionUpdateStatusToSyncMutation>(UPDATE_SUBMISSION_STATUS_TO_SYNC, input, {
    refetchQueries: getSubmissionMutationInvalidations(input.id),
  });
}

const UPDATE_SUBMISSION_STATUS_FOR_REAPPROVAL = gql`
  mutation submissionUpdateStatusForReapproval($input: DeveloperSubmissionUpdateStatusForReapprovalInput!) {
    developerSubmissionUpdateStatusForReapproval(input: $input) {
      id
    }
  }
`;

interface SubmissionUpdateStatusForReapprovalMutation {
  developerSubmissionUpdateStatusForReapproval: {
    id: string;
  };
}

export function updateSubmissionStatusForReapproval(input: DeveloperSubmissionUpdateStatusInput) {
  return mutate<DeveloperSubmissionUpdateStatusInput, SubmissionUpdateStatusForReapprovalMutation>(
    UPDATE_SUBMISSION_STATUS_FOR_REAPPROVAL,
    input,
    { refetchQueries: getSubmissionMutationInvalidations(input.id) },
  );
}

const NOTIFY_RESUBMIT_IFRAME_SUBMISSION = gql`
  mutation notifyResubmitIframeSubmission($input: DeveloperNotifySubmissionResubmitIframeInput!) {
    developerNotifySubmissionResubmitIframe(input: $input) {
      id
    }
  }
`;

interface NotifySubmissionResubmitIframe {
  developerNotifySubmissionResubmitIframe: {
    id: string;
  };
}

export function notifyResubmitIframeSubmission(input: DeveloperNotifySubmissionResubmitIframeInput) {
  return mutate<DeveloperNotifySubmissionResubmitIframeInput, NotifySubmissionResubmitIframe>(NOTIFY_RESUBMIT_IFRAME_SUBMISSION, input);
}

import React from 'react';
import { Grid } from '@mui/material';
import { getInputValue } from '../../../../common/react';
import {
  isValidDiscordUsername,
  isValidDisplayName,
  isValidEmail,
  isValidFacebookUsername,
  isValidInstagramUsername,
  isValidTiktokUsername,
  isValidTwitterUsername,
  isValidYouTubeChannelName,
} from '../../../../common/validations';
import { DefaultInput } from '../../../../common/Styleguide/Common/Input';
import Mail from '../../../../common/icons/Mail';
import TikTok from '../../../../common/icons/TikTok';
import Discord from '../../../../common/icons/Discord';
import Instagram from '../../../../common/icons/Instagram';
import Facebook from '../../../../common/icons/Facebook';
import Twitter from '../../../../common/icons/Twitter';
import { PublicProfileData } from './Profile';
import ProfileIcon from '../../../../common/icons/ProfileIcon';
import YouTube from '../../../../common/icons/YouTube';

export interface PublicProfileProps {
  publicProfileData: PublicProfileData;
  updatePublicProfileDataFn: (data: PublicProfileData) => void;
}

const PublicProfile: React.FC<PublicProfileProps> = ({ publicProfileData, updatePublicProfileDataFn }) => {
  const { contactEmail, displayName, tiktok, discord, instagram, facebook, twitter, youtube } = publicProfileData;

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={6}>
          <DefaultInput
            value={contactEmail}
            placeholder="Ex: contact@crazygames.com"
            adormentIcon={<Mail />}
            error={publicProfileData.contactEmailError !== null}
            errorText={publicProfileData.contactEmailError ? publicProfileData.contactEmailError : 'Invalid email'}
            onChange={(ev) => {
              updatePublicProfileDataFn({ ...publicProfileData, contactEmail: getInputValue(ev), contactEmailError: null });
            }}
            onBlur={() => {
              if (!isValidEmail(publicProfileData.contactEmail || '')) {
                updatePublicProfileDataFn({ ...publicProfileData, contactEmailError: 'Invalid email' });
              }
            }}
            header="Contact email"
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultInput
            value={displayName}
            adormentIcon={<ProfileIcon />}
            onChange={(ev) => {
              updatePublicProfileDataFn({ ...publicProfileData, displayName: getInputValue(ev), displayNameError: null });
            }}
            header="Display name"
            headerTooltip="Your public developer name, displayed on the game page"
            error={publicProfileData.displayNameError !== null}
            errorText={publicProfileData.displayNameError ? publicProfileData.displayNameError : 'Invalid display name'}
            onBlur={() => {
              const validationResult = isValidDisplayName(publicProfileData.displayName || '');
              if (!validationResult.isValid) {
                updatePublicProfileDataFn({
                  ...publicProfileData,
                  displayNameError: validationResult.error!,
                });
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultInput
            value={tiktok}
            adormentIcon={<TikTok />}
            placeholder="@crazygames"
            error={publicProfileData.tiktokError !== null}
            errorText={publicProfileData.tiktokError ? publicProfileData.tiktokError : 'Invalid TikTok format'}
            onChange={(ev) => updatePublicProfileDataFn({ ...publicProfileData, tiktok: getInputValue(ev), tiktokError: null })}
            onBlur={() => {
              if (!isValidTiktokUsername(publicProfileData.tiktok || '')) {
                updatePublicProfileDataFn({ ...publicProfileData, tiktokError: 'Invalid TikTok username' });
              }
            }}
            header="TikTok"
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultInput
            value={discord}
            adormentIcon={<Discord />}
            placeholder="Ex: crazygames#1234, crazygamesdiscord, ..."
            error={publicProfileData.discordError !== null}
            errorText={publicProfileData.discordError ? publicProfileData.discordError : 'Invalid Discord format'}
            onChange={(ev) => updatePublicProfileDataFn({ ...publicProfileData, discord: getInputValue(ev), discordError: null })}
            onBlur={() => {
              if (!isValidDiscordUsername(publicProfileData.discord || '')) {
                updatePublicProfileDataFn({ ...publicProfileData, discordError: 'Invalid Discord username' });
              }
            }}
            header="Discord username"
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultInput
            value={instagram}
            header="Instagram"
            placeholder="crazygames"
            error={publicProfileData.instagramError !== null}
            errorText={publicProfileData.instagramError ? publicProfileData.instagramError : 'Invalid Instagram format'}
            adormentIcon={<Instagram />}
            adormentText={{
              text: 'www.instagram.com/',
              width: 152,
            }}
            onChange={(ev) => updatePublicProfileDataFn({ ...publicProfileData, instagram: getInputValue(ev), instagramError: null })}
            onBlur={() => {
              if (!isValidInstagramUsername(publicProfileData.instagram || '')) {
                updatePublicProfileDataFn({ ...publicProfileData, instagramError: 'Invalid Instagram username' });
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultInput
            header="Facebook"
            errorText={publicProfileData.facebookError ? publicProfileData.facebookError : 'Invalid Facebook format'}
            value={facebook}
            placeholder="crazygames"
            error={publicProfileData.facebookError !== null}
            onChange={(ev) => updatePublicProfileDataFn({ ...publicProfileData, facebook: getInputValue(ev), facebookError: null })}
            adormentIcon={<Facebook />}
            adormentText={{
              text: 'www.facebook.com/',
              width: 146,
            }}
            onBlur={() => {
              if (!isValidFacebookUsername(publicProfileData.facebook || '')) {
                updatePublicProfileDataFn({ ...publicProfileData, facebookError: 'Invalid Facebook username' });
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultInput
            header="Twitter"
            errorText={publicProfileData.twitterError ? publicProfileData.twitterError : 'Invalid Twitter format'}
            placeholder="crazygames"
            value={twitter}
            adormentIcon={<Twitter />}
            adormentText={{
              text: 'www.twitter.com/',
              width: 128,
            }}
            error={publicProfileData.twitterError !== null}
            onChange={(ev) => updatePublicProfileDataFn({ ...publicProfileData, twitter: getInputValue(ev), twitterError: null })}
            onBlur={() => {
              if (!isValidTwitterUsername(publicProfileData.twitter || '')) {
                updatePublicProfileDataFn({ ...publicProfileData, twitterError: 'Invalid Twitter username' });
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DefaultInput
            value={youtube}
            adormentIcon={<YouTube />}
            adormentText={{ text: 'www.youtube.com/', width: 137 }}
            placeholder="@crazygames or channel/id"
            error={publicProfileData.youtubeError !== null}
            errorText={publicProfileData.youtubeError ? publicProfileData.youtubeError : 'Invalid YouTube format'}
            onChange={(ev) => updatePublicProfileDataFn({ ...publicProfileData, youtube: getInputValue(ev), youtubeError: null })}
            onBlur={() => {
              if (!isValidYouTubeChannelName(publicProfileData.youtube || '')) {
                updatePublicProfileDataFn({ ...publicProfileData, youtubeError: 'Invalid YouTube link' });
              }
            }}
            header="YouTube"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PublicProfile;

import { useQuery } from '@apollo/client';
import { Option } from '@mui/base';
import { useTheme } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CustomSelect } from '../../../common/Styleguide/Common/Select';
import { MySubmissionsQuery, MY_SUBMISSIONS_QUERY } from '../Content/UserDeveloper/Games/userDeveloperSubmission-graphql';
import { submissionRoutePath } from '../Content/UserDeveloper/Submission/SubmissionRoute';

const GamesSelector: React.FunctionComponent = () => {
  const { loading, data } = useQuery<MySubmissionsQuery>(MY_SUBMISSIONS_QUERY, {
    fetchPolicy: 'network-only',
  });
  const history = useHistory();
  const theme = useTheme();

  const onGameSelect = (submissionId: string) => {
    const submissionLink = submissionRoutePath.replace(':id', submissionId);
    history.push(submissionLink);
  };

  if (loading || !data || data.me.submissions.length === 0) {
    return null;
  }

  const games = data?.me.submissions.filter((s) => s.gameStatus === 'PUBLISHED');

  if (games.length < 1) {
    return null;
  }

  let value = null;
  if (history.location.pathname.startsWith('/games/')) {
    value = history.location.pathname.replace('/games/', '');
  }
  return (
    <CustomSelect
      value={value}
      placeholder={'Select a game'}
      onChange={(_e: any, newValue: any) => {
        if (newValue) {
          onGameSelect(newValue);
        }
      }}
      style={{ height: 40, width: 300, marginLeft: theme.spacing(3) }}
    >
      {games.map((option) => {
        return (
          <Option key={option.slug} value={option.id}>
            {option.gameName}
          </Option>
        );
      })}
    </CustomSelect>
  );
};

export default GamesSelector;

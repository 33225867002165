import { WebKitDirectoryEntry, WebKitEntry, WebKitFileEntry } from './webkit-types';

export interface ExpandedEntry {
  file: File;
  path: string;
  entry: WebKitEntry;
}

export async function expandDataTransfer(dataTransfer: DataTransfer): Promise<ExpandedEntry[]> {
  return (
    await Promise.all(
      Array.from(dataTransfer.items) // convert to proper array
        .map((item) => item.webkitGetAsEntry()) // obtain proper FileEntry instances
        .map((entry) => traverseFileTree(entry)), // recursively traverse each FileEntry
    )
  ).reduce((prev, curr) => prev.concat(curr), []); // concat all initial children
}

async function traverseFileTree(entry: WebKitEntry | null, path?: string): Promise<ExpandedEntry[]> {
  if (!entry) {
    // should not be null, but happens in production
    return [];
  }
  const childPath = path ? `${path}/${entry.name}` : entry.name;
  if (entry.isFile) {
    const file = await extractFile(entry as WebKitFileEntry);
    return [{ file: file, entry: entry, path: childPath }];
  } else if (entry.isDirectory) {
    const children = await getDirectoryEntries(entry as WebKitDirectoryEntry);
    const childrenResults = await Promise.all(children.map((child) => traverseFileTree(child, childPath)));
    return childrenResults.reduce((prev, curr) => prev.concat(curr), []);
  }
  const msg = `unknown entry`;
  console.error(msg, entry);
  throw new Error(msg);
}

async function extractFile(entry: WebKitFileEntry): Promise<File> {
  return new Promise((resolve, reject) =>
    entry.file(resolve, (error) => {
      if (entry.fullPath && entry.fullPath.length > 210) {
        reject(new Error(`Upload filepath is too long. Filepath must be below 210 char limit: ${entry.fullPath}`));
      }

      reject(error);
    }),
  ) as any;
}

async function getDirectoryEntries(entry: WebKitDirectoryEntry): Promise<WebKitEntry[]> {
  const dirReader = entry.createReader();
  let entries: WebKitEntry[] = [];
  const readPage = () =>
    new Promise<WebKitEntry[]>((resolve, reject) => {
      // Broken typing on pageEntries
      dirReader.readEntries((pageEntries: any) => resolve(pageEntries), reject);
    });
  let page: WebKitEntry[];
  while ((page = await readPage()).length > 0) {
    entries = entries.concat(page);
  }
  return entries;
}

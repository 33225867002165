import { gql } from '@apollo/client';

export interface GameTag {
  id: string;
  name: string;
}

export interface GameCategory {
  id: string;
  name: string;
}

export const GAME_DETAILS_QUERY = gql`
  query {
    tags {
      id
      name
      isCore
    }
    categories {
      id
      name
    }
  }
`;

export interface CoreGameTag extends GameTag {
  isCore: boolean;
}

export interface GameDetailsQuery {
  tags: CoreGameTag[];
  categories: GameCategory[];
}

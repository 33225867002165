import React from 'react';
import Requirement, { RequirementState } from './Requirement';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { StyledButton } from '../../../../../../../common/Styleguide/Common/Button';
import { Box } from '@mui/material';

export type YesNoRequirementState = 'triggered' | RequirementState;

export interface Props {
  text: string;
  questionText: string;
  state: YesNoRequirementState;
  onAnswer: (answer: boolean) => void;
}

const YesNoRequirement: React.FC<Props> = ({ onAnswer, questionText, text, state }) => {
  return (
    <Requirement text={state === 'triggered' ? questionText : text} state={state === 'triggered' ? 'pending' : state}>
      {state === 'triggered' && (
        <Box sx={{ display: 'flex', mt: 1, justifyContent: 'center', gap: 0.5, width: '100%' }}>
          <StyledButton
            variant="contained"
            height={25}
            sx={{ width: 60 }}
            onClick={() => {
              onAnswer(true);
            }}
          >
            <StyledBodyText variant="bodyLower" sx={{ fontWeight: 800 }}>
              YES
            </StyledBodyText>
          </StyledButton>
          <StyledButton
            variant="contained"
            height={25}
            sx={{ width: 60 }}
            onClick={() => {
              onAnswer(false);
            }}
          >
            <StyledBodyText variant="bodyLower" sx={{ fontWeight: 800 }}>
              NO
            </StyledBodyText>
          </StyledButton>
        </Box>
      )}
    </Requirement>
  );
};

export default YesNoRequirement;

import { gql } from '@apollo/client';

export interface XsollaUserTokenQuery {
  xsollaUserTokenQaTool: {
    token: string;
    expiresIn: number;
  };
}

export interface XsollaUserTokenQueryInput {
  submissionId: string;
  fakeUserId: string;
}

export const XSOLLA_USER_TOKEN_QUERY = gql`
  query XsollaUserTokenQaTool($submissionId: String!, $fakeUserId: String!) {
    xsollaUserTokenQaTool(submissionId: $submissionId, fakeUserId: $fakeUserId) {
      token
      expiresIn
    }
  }
`;

export interface QaToolUserTokenQuery {
  userTokenQaTool: {
    token: string;
    expiresIn: number;
  };
}

export interface QaToolUserTokenQueryInput {
  fakeUser: {
    id: string;
    username: string;
    profilePictureUrl: string;
  };
}

export const QA_TOOL_USER_TOKEN_QUERY = gql`
  query UserTokenQaTool($fakeUser: QAToolFakeUser!) {
    userTokenQaTool(fakeUser: $fakeUser) {
      token
      expiresIn
    }
  }
`;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const PartyingFaceIcon: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50">
      <g clipPath="url(#clip0_1265_3667)">
        <path
          d="M23.6111 50C36.6512 50 47.2222 39.4289 47.2222 26.3889C47.2222 13.3488 36.6512 2.77778 23.6111 2.77778C10.5711 2.77778 0 13.3488 0 26.3889C0 39.4289 10.5711 50 23.6111 50Z"
          fill="#FFCC4D"
        />
        <path
          d="M24.9987 39.5833C26.5329 39.5833 27.7765 38.0288 27.7765 36.1111C27.7765 34.1935 26.5329 32.6389 24.9987 32.6389C23.4646 32.6389 22.2209 34.1935 22.2209 36.1111C22.2209 38.0288 23.4646 39.5833 24.9987 39.5833Z"
          fill="#664500"
        />
        <path
          d="M11.2653 29.6986C11.0171 29.6983 10.7735 29.6315 10.5598 29.5052C10.3462 29.3788 10.1703 29.1976 10.0505 28.9802C9.93065 28.7628 9.87128 28.5173 9.87852 28.2692C9.88576 28.0211 9.95936 27.7795 10.0917 27.5694C11.3639 25.5514 15.2319 22.1 20.7722 23.4556C21.1226 23.5499 21.4222 23.7777 21.6068 24.0901C21.7914 24.4026 21.8464 24.7748 21.76 25.1273C21.6736 25.4798 21.4527 25.7844 21.1445 25.9761C20.8364 26.1677 20.4654 26.2311 20.1111 26.1528C15.1055 24.9278 12.4694 29.0083 12.443 29.05C12.3176 29.2489 12.1438 29.4127 11.9378 29.5262C11.7318 29.6396 11.5004 29.6989 11.2653 29.6986ZM27.8944 24.5014C27.6078 24.5016 27.3282 24.4132 27.0938 24.2482C26.8595 24.0832 26.6819 23.8498 26.5854 23.5799C26.489 23.31 26.4784 23.0169 26.5551 22.7408C26.6318 22.4646 26.7921 22.219 27.0139 22.0375C31.425 18.4208 36.4056 19.8681 38.4236 21.1403C38.7356 21.3362 38.957 21.6481 39.039 22.0073C39.1211 22.3665 39.0571 22.7436 38.8611 23.0556C38.6651 23.3676 38.3533 23.5889 37.9941 23.671C37.6349 23.753 37.2578 23.689 36.9458 23.4931C36.7542 23.3764 32.7069 20.9611 28.7736 24.1861C28.5262 24.3904 28.2153 24.5019 27.8944 24.5014Z"
          fill="#664500"
        />
        <path
          d="M18.536 43.4347C18.375 43.4348 18.2162 43.3975 18.072 43.3258C17.9278 43.2542 17.8022 43.1501 17.705 43.0217C17.6079 42.8933 17.5418 42.7441 17.512 42.5859C17.4822 42.4277 17.4895 42.2647 17.5333 42.1097C18.1402 39.9708 17.8916 37.9028 16.8527 36.4347C16.1624 35.4597 15.1791 34.8639 14.1541 34.7986C13.5791 34.7639 13.143 34.2681 13.1791 33.6944C13.2152 33.1208 13.7083 32.6514 14.2833 32.7208C15.9402 32.8236 17.4958 33.7389 18.5513 35.2306C19.9555 37.2153 20.3152 39.9292 19.536 42.6764C19.4746 42.8944 19.3438 43.0865 19.1633 43.2233C18.9828 43.3602 18.7626 43.4344 18.536 43.4347ZM33.8652 39.1833C33.6617 39.1835 33.4626 39.1236 33.293 39.0111C31.6721 37.9417 30.7652 36.0708 30.8041 33.8792C30.8444 31.6083 31.9277 29.5 33.5596 28.5056C33.6764 28.4342 33.8061 28.3866 33.9412 28.3656C34.0764 28.3445 34.2144 28.3503 34.3473 28.3827C34.4803 28.4151 34.6055 28.4734 34.7158 28.5543C34.8261 28.6352 34.9194 28.7371 34.9902 28.8542C35.0615 28.9709 35.1091 29.1006 35.1302 29.2358C35.1513 29.3709 35.1455 29.509 35.1131 29.6419C35.0807 29.7748 35.0224 29.9 34.9415 30.0103C34.8605 30.1206 34.7586 30.2139 34.6416 30.2847C33.6208 30.9069 32.9138 32.3667 32.886 33.9153C32.8583 35.3806 33.4263 36.6042 34.4388 37.2722C34.6258 37.3956 34.768 37.5759 34.8443 37.7865C34.9207 37.997 34.9272 38.2266 34.8628 38.4411C34.7984 38.6557 34.6666 38.8437 34.4869 38.9774C34.3072 39.1111 34.0892 39.1833 33.8652 39.1833Z"
          fill="#E2A62D"
        />
        <path
          d="M23.8597 3.77778C23.7886 3.70779 23.6963 3.66334 23.5972 3.65139C23.5972 3.65139 1.48056 -0.547225 0.523618 0.29722C-0.431937 1.14305 1.02778 23.6083 1.02778 23.6083C1.03473 23.7208 1.05973 23.8167 1.12084 23.8847C1.95973 24.8347 7.72917 21.1014 14.0083 15.55C20.2889 9.99722 24.6986 4.72778 23.8597 3.77778Z"
          fill="#DD2E44"
        />
        <path
          d="M0.484663 0.376391C0.459572 0.430743 0.441824 0.488191 0.431885 0.547224C0.652718 2.45139 2.78605 18.1194 4.11522 23.0167C6.10966 21.9014 8.13605 20.3861 10.5166 18.4986C8.49439 14.7972 1.60827 0.20278 0.484663 0.376391Z"
          fill="#EA596E"
        />
        <path
          d="M41.5305 40.5958L26.8458 38.7861C25.2764 38.6444 22.5167 38.6306 22.6583 36.1444C22.7875 33.8903 25.4917 34.2375 27.4903 34.5333L42.2653 36.9972L41.5305 40.5958Z"
          fill="#3B88C3"
        />
        <path
          d="M42.2638 36.9986L36.4 35.9931C35.7138 35.8694 35.1027 36.8917 35.0722 37.9736C35.0375 39.1764 35.3875 39.7847 36.0805 39.9264L41.8361 40.6375L42.2638 36.9986Z"
          fill="#88C9F9"
        />
        <path
          d="M48.4971 36.5847L44.8554 39.9333L38.3457 32.8542L41.9874 29.5056C42.9006 28.6705 44.1073 28.2308 45.3437 28.2826C46.5801 28.3344 47.7457 28.8734 48.586 29.7819L48.7735 29.9861C49.6082 30.8995 50.0476 32.1061 49.9958 33.3424C49.9441 34.5787 49.4053 35.7443 48.4971 36.5847Z"
          fill="#3B88C3"
        />
        <path
          d="M44.8532 39.9352C46.4173 38.4971 46.2282 35.7466 44.4308 33.7917C42.6334 31.8369 39.9084 31.418 38.3443 32.8561C36.7802 34.2942 36.9693 37.0448 38.7667 38.9996C40.5641 40.9545 43.2891 41.3734 44.8532 39.9352Z"
          fill="#88C9F9"
        />
        <path
          d="M43.5515 38.5192C44.3335 37.8001 44.0934 36.2665 43.015 35.0937C41.9367 33.9209 40.4286 33.5531 39.6465 34.2721C38.8644 34.9912 39.1046 36.5248 40.183 37.6976C41.2613 38.8704 42.7694 39.2383 43.5515 38.5192Z"
          fill="#226699"
        />
        <path
          d="M3.47225 48.6111C4.62284 48.6111 5.55558 47.6784 5.55558 46.5278C5.55558 45.3772 4.62284 44.4444 3.47225 44.4444C2.32166 44.4444 1.38892 45.3772 1.38892 46.5278C1.38892 47.6784 2.32166 48.6111 3.47225 48.6111Z"
          fill="#55ACEE"
        />
        <path
          d="M40.2778 5.55556C41.8119 5.55556 43.0556 4.3119 43.0556 2.77778C43.0556 1.24365 41.8119 0 40.2778 0C38.7437 0 37.5 1.24365 37.5 2.77778C37.5 4.3119 38.7437 5.55556 40.2778 5.55556Z"
          fill="#55ACEE"
        />
        <path
          d="M6.7556 40.6194L3.50838 32.8194L0.572266 41.2153L6.7556 40.6194ZM36.1112 6.94445L30.5556 8.33333L31.9445 2.77778L36.1112 6.94445Z"
          fill="#EA596E"
        />
        <path d="M44.4429 18.0556L49.9998 11.1097L45.8331 8.33334L44.4429 18.0556Z" fill="#77B255" />
      </g>
      <defs>
        <clipPath id="clip0_1265_3667">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

export default PartyingFaceIcon;

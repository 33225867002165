import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Facebook: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13.7416 22V12.8777H16.8023L17.2615 9.32156H13.7416V7.05147C13.7416 6.0222 14.0262 5.32076 15.5039 5.32076L17.3854 5.31999V2.13923C17.06 2.09695 15.9431 2 14.6431 2C11.9285 2 10.07 3.65697 10.07 6.69927V9.32156H7V12.8777H10.07V22H13.7416Z"
      />
    </SvgIcon>
  );
});

export default Facebook;

interface FileOptions {
  fileType?: string;
  extension?: string;
}

export const downloadFileFromBlob = (blob: Blob, name: string, options: FileOptions = {}) => {
  const blobOptions = options.fileType ? { type: options.fileType } : {};
  const newBlob = new Blob([blob], blobOptions);
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = name + (options.extension ? `.${options.extension}` : '');
  link.click();
  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => {
    window.URL.revokeObjectURL(data);
  }, 100);
};

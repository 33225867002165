import { styled } from '@mui/material/styles';
import { COLORS } from '../../../common/Styleguide/Common/colors';

export const HeaderLogoTitleContainer = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  [breakpoints.down('md')]: {
    fontSize: 14,
    '& svg': {
      width: 80,
    },
  },
}));

export const HeaderTitle = styled('div')(({ theme: { breakpoints } }) => ({
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: '900',
  fontSize: 16,
  lineHeight: '100%',
  color: COLORS.white[100],
  height: 40,
  display: 'flex',
  alignItems: 'center',
  [breakpoints.down('md')]: {
    fontSize: 14,
  },
}));

export const HeaderDividerLine = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  backgroundColor: COLORS.black[40],
  width: 1,
  height: 40,
  marginRight: spacing(2.5),
  marginLeft: spacing(2.5),
  [breakpoints.down('md')]: {
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },
}));

import * as React from 'react';
import { COLORS } from '../Styleguide/Common/colors';
import { expandDataTransfer } from './expand-data-transfer';
import { styled } from '@mui/material/styles';

export interface DroppedFile {
  file: File;
  path: string;
}

interface DropZoneProps {
  onFilesDropped: (files: DroppedFile[]) => void;
  onError: (error: any) => void;
  dropDisabled?: boolean;
  error?: boolean;
  children?: React.ReactNode;
}

interface DropZoneState {
  dragging: boolean;
  files?: DroppedFile[];
}
interface DropzoneBaseProps {
  error: boolean;
  dragging: boolean;
}

export const DropzoneBase = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'dragging',
})<DropzoneBaseProps>(({ error, dragging }) => () => {
  const getBorderStyle = () => {
    if (error) {
      return `1px dashed ${COLORS.alert[100]}`;
    }
    if (dragging) {
      return `solid 2px ${COLORS.brand[100]}`;
    }
    return `2px dashed ${COLORS.black[30]}`;
  };
  return {
    flex: 1,
    height: '100%',
    width: '100%',
    border: getBorderStyle(),
    borderRadius: 6,
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: 'transparent',
  };
});

const dropzoneInnerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

class DropZone extends React.Component<DropZoneProps, DropZoneState> {
  constructor(props: DropZoneProps) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  render() {
    return (
      <DropzoneBase
        error={!!this.props.error}
        dragging={this.state.dragging}
        onDragEnter={(evt) => this.handleDragEnter(evt)}
        onDragLeave={(evt) => this.handleDragLeave(evt)}
        onDragOver={(evt) => this.handleDragOver(evt)}
        onDrop={(evt) => this.handleDrop(evt)}
      >
        <div style={dropzoneInnerStyle}>{this.props.children}</div>
      </DropzoneBase>
    );
  }

  private handleDragEnter(evt: React.DragEvent<HTMLDivElement>) {
    evt.stopPropagation();
    evt.preventDefault();
    if (this.props.dropDisabled) {
      return;
    }
    this.setState({ dragging: true });
  }

  private handleDragOver(evt: React.DragEvent<HTMLDivElement>) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  private handleDragLeave(evt: React.DragEvent<HTMLDivElement>) {
    evt.stopPropagation();
    evt.preventDefault();
    if (this.props.dropDisabled) {
      return;
    }
    if (evt.relatedTarget && evt.currentTarget.contains(evt.relatedTarget as HTMLDivElement)) {
      return;
    }
    this.setState({ dragging: false });
  }

  private handleDrop(evt: React.DragEvent<HTMLDivElement>) {
    evt.stopPropagation();
    evt.preventDefault();
    if (!this.props.dropDisabled) {
      this.setState({ dragging: false });
      this.setFiles(evt.dataTransfer); // don't wait for the result before returning
    }
    return false; // return false so the browser doesn't try to open the files dropped
  }

  private async setFiles(dataTransfer: DataTransfer) {
    // ignore dropping anything which is not a set of files (e.g. selected text)
    try {
      if (dataTransfer.files && dataTransfer.files.length === 0) {
        return;
      }
      const files = await expandDataTransfer(dataTransfer);
      this.props.onFilesDropped(files);
      this.setState({ files: files });
    } catch (error) {
      this.props.onError(error);
    }
  }
}

export default DropZone;

import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Drawer, Typography, useScrollTrigger, Slide, Hidden, Box } from '@mui/material';
import { User } from 'firebase/auth';
import { Link, useLocation } from 'react-router-dom';
import CrazyGamesLogo from '../../../common/components/CrazyGamesLogo/CrazyGamesLogo';
import RolesContext from '../Session/RolesContext';
import { WithSession } from '../../../common/Session/withSession';
import clsx from 'clsx';
import { COLORS } from '../../../common/Styleguide/Common/colors';
import { scrollbarStyles } from '../theme';
import { HeaderDividerLine, HeaderLogoTitleContainer, HeaderTitle } from './Header.styles';
import GamesSelector from './GamesSelector';
import UserInfo from './UserInfo';
import Menu, { DOCS_URL } from '../SideMenu/Menu';
import NewUserDialog from './NewUserDialog';
import SessionContext from '../../../common/Session/SessionContext';
import { StyledButton } from '../../../common/Styleguide/Common/Button';
import { ROLE_V2 } from '../../../common/roles';

const PREFIX = 'Header';

export const HEADER_HEIGHT = 70;

const classes = {
  header: `${PREFIX}-header`,
  homepageScrollBack: `${PREFIX}-homepageScrollBack`,
  headerBack: `${PREFIX}-headerBack`,
  sidebarMenu: `${PREFIX}-sidebarMenu`,
  toolbar: `${PREFIX}-toolbar`,
  loginStatusContainer: `${PREFIX}-loginStatusContainer`,
  notLoggedInLinks: `${PREFIX}-notLoggedInLinks`,
};

const Root = styled('div')(({ theme: { breakpoints, spacing, zIndex } }) => ({
  [`& .${classes.header}`]: {
    background: 'transparent',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: zIndex.drawer + 2,
    height: HEADER_HEIGHT,
    paddingLeft: 0,
    boxShadow: 'none',
    dispaly: 'flex',
    justifyContent: 'center',
    '& a': {
      color: `white`,
    },
    '& h3': {
      margin: 0,
      fontWeight: 'bold',
    },
    '& ul': {
      margin: 0,
      padding: 0,
      '& li': {
        display: 'inline-block',
      },
    },
  },

  [`& .${classes.homepageScrollBack}`]: {
    background: 'rgb(0 0 0 / 42%)',
  },

  [`& .${classes.headerBack}`]: {
    background: COLORS.black[70],
  },

  [`& .${classes.sidebarMenu}`]: {
    top: HEADER_HEIGHT + 2,
    height: `calc(100% - ${HEADER_HEIGHT + 2}px)`,
    background: COLORS.black[70],
    color: COLORS.white[100],
    svg: {
      color: COLORS.white[30],
    },
    ...(scrollbarStyles(6) as any),
  },

  [`& .${classes.toolbar}`]: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    [breakpoints.down('xs')]: {
      padding: 0,
    },
  },

  [`& .${classes.loginStatusContainer}`]: {
    color: 'white',
    '& a': {
      display: 'inline-flex',
    },
    marginRight: spacing(2),
  },

  [`& .${classes.notLoggedInLinks}`]: {},
}));
type HeaderType = 'DEFAULT' | 'LANDING_PAGE' | 'PREVIEW';

interface Props {
  roles: ROLE_V2[];
  type: HeaderType;
  location: ReturnType<typeof useLocation>;
}

interface ExtProps {
  type: HeaderType;
}

interface State {
  user?: User;
  scrolling: boolean;
}

interface HideOnScrollProps {
  children: any;
  disabled: boolean;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children, disabled } = props;
  const trigger = useScrollTrigger();
  if (disabled) {
    return children;
  }
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type PropsWithoutRoles = ExtProps;
export type FullProps = Props & PropsWithoutRoles & WithSession;

class HeaderIntern extends React.Component<FullProps, State> {
  constructor(props: FullProps) {
    super(props);
    this.state = {
      scrolling: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll, { passive: true });
  }

  componentWillUnmount() {
    // cleanup attached listeners
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  render() {
    const { type } = this.props;
    const { scrolling } = this.state;
    const shouldDisplayMenu = type === 'DEFAULT' && this.props.session.isLoggedIn();
    return (
      <Root>
        <HideOnScroll disabled={type !== 'LANDING_PAGE'}>
          <AppBar
            position="sticky"
            className={clsx(classes.header, type !== 'LANDING_PAGE' && classes.headerBack, scrolling && classes.homepageScrollBack)}
          >
            <Toolbar disableGutters className={classes.toolbar}>
              {this.renderLogo()}
              <Hidden mdDown>
                <GamesSelector />
              </Hidden>
              <div style={{ flex: 1 }}></div>
              {this.renderLoginStatusLinks()}
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        {shouldDisplayMenu && this.renderMenu()}
        <NewUserDialog />
      </Root>
    );
  }

  private handleWindowScroll = () => {
    if (!this.state.scrolling || window.scrollY === 0) {
      this.setState({ scrolling: window.scrollY > 0 });
    }
  };

  private renderLogo() {
    const link = this.props.location.pathname === '/' ? '/games' : '/';
    return (
      <Link to={link} className="czy--reset-link">
        <HeaderLogoTitleContainer>
          <CrazyGamesLogo />
          <HeaderDividerLine />
          <HeaderTitle>
            DEVELOPER
            <br />
            Portal
          </HeaderTitle>
        </HeaderLogoTitleContainer>
      </Link>
    );
  }

  private renderMenu() {
    const { roles } = this.props;
    return (
      <Drawer classes={{ paper: classes.sidebarMenu }} sx={{ display: { xs: 'none', md: 'block' } }} anchor="left" variant="permanent">
        <Menu roles={roles} />
      </Drawer>
    );
  }

  private renderLoginStatusLinks() {
    const { type } = this.props;
    return (
      <Typography variant="subtitle1" className={classes.loginStatusContainer} style={{ display: 'flex' }}>
        {this.props.session.isLoggedIn() ? (
          <>
            {type === 'LANDING_PAGE' && (
              <Box
                component="a"
                href={DOCS_URL}
                target="_blank"
                rel="nofollow noreferrer"
                sx={{ mr: 2, display: { xs: 'none !important', md: 'inline-flex !important' } }}
              >
                <StyledButton variant="outlined">Documentation</StyledButton>
              </Box>
            )}
            <UserInfo />
          </>
        ) : (
          this.renderNotLoggedInLinks()
        )}
      </Typography>
    );
  }

  private renderNotLoggedInLinks() {
    const { type } = this.props;
    return (
      <div>
        {type === 'LANDING_PAGE' && (
          <Box
            component="a"
            href={DOCS_URL}
            target="_blank"
            rel="nofollow noreferrer"
            sx={{ mr: 2, display: { xs: 'none !important', md: 'inline-flex !important' } }}
          >
            <StyledButton variant="outlined">Documentation</StyledButton>
          </Box>
        )}

        <Link key="login" to="/login" className="czy--reset-link">
          <StyledButton variant="contained" color="white">
            Log in
          </StyledButton>
        </Link>
      </div>
    );
  }
}

const Header: React.FC<PropsWithoutRoles> = (props) => {
  const { roles } = React.useContext(RolesContext);
  const session = React.useContext(SessionContext);
  const location = useLocation();
  return <HeaderIntern roles={roles} location={location} session={session} {...props} />;
};

export default Header;

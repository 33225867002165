import { Theme, useTheme } from '@mui/material';

export interface WithTheme {
  theme: Theme;
}

function withTheme<P>(WrappedComponent: React.ComponentType<P>): React.FC<Pick<P, Exclude<keyof P, keyof WithTheme>>> {
  const Wrapped: React.FC<P> = (props) => {
    const theme = useTheme();
    return <WrappedComponent theme={theme} {...props} />;
  };
  return Wrapped as any;
}

export default withTheme;

import React from 'react';
import CzyLoader from '../Loader';
import { GraphQLError } from '../components/ApolloError/GraphQLError';
import { ApolloError } from '@apollo/client';

export interface GraphQLLoaderProps {
  loading: boolean;
  error: ApolloError | undefined;
  children?: React.ReactNode;
}

const GraphQLLoader: React.FC<GraphQLLoaderProps> = ({ loading, error, children }) => {
  if (loading) {
    return <CzyLoader />;
  } else if (error) {
    if (error instanceof ApolloError) {
      return <GraphQLError error={error} />;
    } else {
      return <pre>ERROR: {JSON.stringify(error, null, 2)}</pre>;
    }
  } else {
    return <>{children}</>;
  }
};

export default GraphQLLoader;

import React from 'react';
import { Box, useTheme } from '@mui/material';
import { HeaderIcon } from './HeaderIcon';
import { GradientBackground } from '../Common/GradientBackground';
import { StyledAuthenticateContainer } from './authenticate.styles';

const AuthenticateShell: React.FC = ({ children }) => {
  const { spacing } = useTheme();
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', minHeight: '100vh' }}>
        <div style={{ textAlign: 'center', maxWidth: 430, width: '100%', zIndex: 1, marginBottom: spacing(4) }}>
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              mb: 8,
              mt: 12,
            }}
          >
            <HeaderIcon />
          </Box>
          <StyledAuthenticateContainer>{children}</StyledAuthenticateContainer>
        </div>
        <GradientBackground />
      </div>
    </>
  );
};

export default AuthenticateShell;

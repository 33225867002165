import { LOCALE, TranslationMap } from './i18n';
import isEmptyString from './is-empty-string';

export function slugify(text: string) {
  return (
    text
      // eslint-disable-next-line no-useless-escape
      .replace(/[`~!@#$%^*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, ' ') // replace special characters
      .trim() // remove leading & trailing whitespace
      .replace(/\s+/g, '-') // replace spaces with single dash
      .toLowerCase()
  );
}

export function slugifyTranslationMap(map: TranslationMap): TranslationMap {
  const result: Partial<TranslationMap> = {};
  for (const [locale, slug] of Object.entries(map)) {
    result[locale as LOCALE] = isEmptyString(slug) ? undefined : slugify(slug);
  }
  return result as TranslationMap;
}

export const MAX_SLUG_LENGTH = 35;
export function isValidSlug(slug: string) {
  return slug && slug.length > 0 && slug.length <= MAX_SLUG_LENGTH;
}

/**
 * Preserve old slugs over the new ones. Also keeps all previously defined slugs
 * @param newSlugs The desired new slugs
 * @param oldSlugs The old slugs that should be kept over a new one
 */
export function preserveSlugs(newSlugs: TranslationMap, oldSlugs: TranslationMap): TranslationMap {
  const result: TranslationMap = { ...oldSlugs };
  for (const [locale, newSlug] of Object.entries(newSlugs)) {
    // only overwrite the existing slug if the slug was previously NULL
    if (isEmptyString(oldSlugs[locale as LOCALE])) {
      result[locale as LOCALE] = newSlug;
    }
  }
  return result;
}

export default slugify;

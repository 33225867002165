import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Users2: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4.00229C7.34315 4.00229 6 5.34697 6 7.00572C6 8.66447 7.34315 10.0092 9 10.0092C10.6569 10.0092 12 8.66447 12 7.00572C12 5.34697 10.6569 4.00229 9 4.00229ZM4 7.00572C4 4.24114 6.23858 2 9 2C10.1256 2 11.1644 2.37239 12.0001 3.00083C12.8355 2.3729 13.8751 2 15 2C17.7614 2 20 4.24114 20 7.00572C20 9.77031 17.7614 12.0114 15 12.0114C13.8749 12.0114 12.8354 11.6385 12.0001 11.0106C11.1644 11.6391 10.1256 12.0114 9 12.0114C6.23858 12.0114 4 9.77031 4 7.00572ZM13.3338 9.50388C13.8105 9.82345 14.383 10.0092 15 10.0092C16.6569 10.0092 18 8.66447 18 7.00572C18 5.34697 16.6569 4.00229 15 4.00229C14.3829 4.00229 13.8105 4.18803 13.3338 4.50758C13.7576 5.24279 14 6.09591 14 7.00572C14 7.91554 13.7576 8.76867 13.3338 9.50388ZM9 15.0149C6.22914 15.0149 4 17.2096 4 19.9901V19.9977H14V19.9901C14 17.2096 11.7709 15.0149 9 15.0149ZM16 19.9977H20V19.9901C20 17.2096 17.7709 15.0149 15 15.0149C14.6505 15.0149 14.3172 15.0402 14.0011 15.0919C15.2368 16.3438 16 18.0658 16 19.9901V19.9977ZM11.9386 13.6489C11.0444 13.2397 10.0485 13.0126 9 13.0126C5.14345 13.0126 2 16.0849 2 19.9901V20.9989C2 21.5518 2.44772 22 3 22H21C21.5523 22 22 21.5518 22 20.9989V19.9901C22 16.0849 18.8566 13.0126 15 13.0126C13.9424 13.0126 12.8952 13.1954 11.9386 13.6489Z"
      />
    </SvgIcon>
  );
});

export default Users2;

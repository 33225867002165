import React from 'react';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import { classes } from './styledUpdateSubmission';
import { Submission } from '../submission-graphql';
import { notifyResubmitIframeSubmission } from '../Mutations/update-submission-status-mutation';
import { StyledLoadingButton } from '../../../../../../common/Styleguide/Common/Button';

interface UpdateSubmissionIframeNoticeProps {
  submission?: Submission;
}

const UpdateSubmissionIframeNotice: React.FC<UpdateSubmissionIframeNoticeProps> = ({ submission }) => {
  const [iframeChangeNotified, setIframeChangeNotified] = React.useState<boolean>(false);
  const [notice, setNotice] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const notifyForIframeContentsUpdate = async () => {
    try {
      const input = {
        id: submission!.id,
        updateNoteText: notice,
      };
      setLoading(true);
      await notifyResubmitIframeSubmission(input);
      setIframeChangeNotified(true);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const isDisabled = () => {
    return iframeChangeNotified || notice.length === 0;
  };

  return (
    <StyledContainer
      sx={{
        width: 900,
        m: 2,
        mt: 3,
        p: 2,
        px: 3,
      }}
    >
      <StyledHeaderText
        variant="h2"
        sx={{
          paddingY: 1,
          margin: 0,
        }}
      >
        IFrame update notes for Admin*
      </StyledHeaderText>
      <StyledBodyText sx={{ mt: 0, mb: 1 }} color="black10">
        Write a summary of the update and mark the important ones. Include changes to our SDK ads (if any)
      </StyledBodyText>
      <StyledInput
        multiline={true}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          const value = evt.target.value;
          setNotice(value);
        }}
      />

      <StyledLoadingButton
        variant="contained"
        color="purple"
        disabled={isDisabled()}
        className={classes.notifyButton}
        onClick={notifyForIframeContentsUpdate}
        loading={loading}
      >
        Notify Admin
      </StyledLoadingButton>
      <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="black10">
        Notify the admin to check the updates made on your game's iframe URL
      </StyledBodyText>
    </StyledContainer>
  );
};

export default UpdateSubmissionIframeNotice;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { StyledButton } from '../../../../../../../common/Styleguide/Common/Button';
import { useHistory } from 'react-router-dom';
import { submissionRoutePath } from '../../../Submission/SubmissionRoute';
import { PreviewSubmission } from '../../../../Preview/preview-graphql';

interface QuitQAToolPopupProps {
  submission: PreviewSubmission;
  back: () => void;
}

const QuitQAToolPopup: React.FC<QuitQAToolPopupProps> = ({ back, submission }) => {
  const history = useHistory();
  const handleQuit = () => {
    const submissionLink = submissionRoutePath.replace(':id', submission.id);
    history.push(submissionLink);
  };
  return (
    <Dialog open onClose={back}>
      <DialogTitle id="alert-dialog-slide-title">Quit QA Tool?</DialogTitle>
      <DialogContent sx={{ px: 2 }}>
        <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2, mt: 0 }}>
          You are about to go back to your draft without requesting a review from our QA team. Do you still want to process?
        </StyledBodyText>
      </DialogContent>
      <DialogActions sx={{ pb: 3 }}>
        <StyledButton onClick={handleQuit} variant="contained" color="purple" height={50} sx={{ minWidth: 207, mr: 2 }}>
          Yes, back to draft
        </StyledButton>
        <StyledButton onClick={back} variant="contained" color="white" height={50} style={{ minWidth: 207 }}>
          No, stay on QA Tool
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default QuitQAToolPopup;

export interface ImgixOptions {
  auto?: 'format,compress';
  cs?: 'strip';
  q?: number;
  w?: number;
  h?: number;
  ch?: 'DPR';
  fit?: 'crop' | 'fill';
  crop?: 'entropy';
  sat?: number;
  mask?: string;
  fill?: 'blur';
  blur?: number;
  'blend-color'?: string;
  'blend-mode'?: string;
}

interface CFImageOptions {
  w?: number;
  h?: number;
  fit?: 'contain' | 'scale-down' | 'cover' | 'crop' | 'pad';
  g?: 'auto' | 'side';
  q?: number;
  format?: 'auto';
}

export function imgix(src: string, options?: CFImageOptions): string {
  const baseOptions: ImgixOptions = {
    auto: 'format,compress',
    fit: 'crop',
    cs: 'strip',
    q: 75,
  };
  const merged = Object.assign({}, baseOptions, options);
  const optionString = Object.entries(merged)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  return `https://images.crazygames.com/${src}?${optionString}`;
}

import { loadScript } from '../script';

const STUB_LOCATION = 'https://cdn.iubenda.com/cs/tcf/stub-v2.js';
const IUB_LOCATION = 'https://cdn.iubenda.com/cs/iubenda_cs.js';

let iubLoaded = false;
let loadingPromise: Promise<void>;

export function loadIubendaIfNeeded(): Promise<void> {
  if (iubLoaded) {
    return Promise.resolve();
  }
  if (loadingPromise) {
    return loadingPromise;
  }
  return (loadingPromise = loadIubenda());
}

async function loadIubenda(): Promise<void> {
  let w = window as any;
  w._iub = w._iub || [];

  w._iub.csConfiguration = {
    ccpaAcknowledgeOnDisplay: true,
    lang: 'en',
    siteId: '1847261',
    cookiePolicyId: '52441553',
    tcfVersion: 2,
    countryDetection: true,
    consentOnContinuedBrowsing: false,
    enableCMP: true,
    ccpaLspa: false,
    googleAdsPreferenceManagement: true,
    cookiePolicyUrl: 'https://www.crazygames.com/privacy-policy-inline',
    privacyPolicyUrl: 'https://www.crazygames.com/privacy-policy-inline',
    isTCFConsentGlobal: false,
    gdprAppliesGlobally: false,
    floatingPreferencesButtonDisplay: false,
    gdprApplies: true,
    banner: {
      acceptButtonDisplay: true,
      customizeButtonDisplay: true,
      position: 'bottom',
      closeButtonDisplay: false,
      prependOnBody: false,
    },
    callback: {
      onStartupFailed: (...args: any[]) => {
        console.error(...args);
      },
      onError: (...args: any[]) => {
        console.error(...args);
      },
    },
  };

  await loadScript(STUB_LOCATION);
  await loadScript(IUB_LOCATION);
  iubLoaded = true;
  return;
}

import React from 'react';
import {
  QuoteAuthorName,
  QuoteAuthorTitle,
  QuoteAvatarContainer,
  QuoteAvatarImage,
  QuoteAvatarLine,
  QuoteText,
  StyledQuoteSection,
} from './quoteSection.styles';
import { imgix } from '../../../../helpers/imgix';

interface QuoteSectionProps {
  quote: string;
  author: string;
  title: string;
  authorImage: string;
}
const QuoteSection: React.FC<QuoteSectionProps> = ({ quote, author, title, authorImage }) => {
  return (
    <StyledQuoteSection>
      <QuoteAvatarContainer>
        <QuoteAvatarLine />
        <QuoteAvatarImage src={imgix(`devportal/quote-authors/${authorImage}`)} alt="Quote author" />
        <QuoteAvatarLine />
      </QuoteAvatarContainer>
      <QuoteAuthorName>{author}</QuoteAuthorName>
      <QuoteAuthorTitle>{title}</QuoteAuthorTitle>
      <QuoteText>{`“${quote}”`}</QuoteText>
    </StyledQuoteSection>
  );
};
export default QuoteSection;

import { SvgIcon } from '@mui/material';
import * as React from 'react';

const QAToolIcon: React.FunctionComponent = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 15">
      <path d="M3.2 4.8H5.6C5.82667 4.8 6.0168 4.7232 6.1704 4.5696C6.32347 4.41653 6.4 4.22667 6.4 4C6.4 3.77333 6.32347 3.5832 6.1704 3.4296C6.0168 3.27653 5.82667 3.2 5.6 3.2H3.2C2.97333 3.2 2.7832 3.27653 2.6296 3.4296C2.47653 3.5832 2.4 3.77333 2.4 4C2.4 4.22667 2.47653 4.41653 2.6296 4.5696C2.7832 4.7232 2.97333 4.8 3.2 4.8ZM3.2 8H5.6C5.82667 8 6.0168 7.9232 6.1704 7.7696C6.32347 7.61653 6.4 7.42667 6.4 7.2C6.4 6.97333 6.32347 6.7832 6.1704 6.6296C6.0168 6.47653 5.82667 6.4 5.6 6.4H3.2C2.97333 6.4 2.7832 6.47653 2.6296 6.6296C2.47653 6.7832 2.4 6.97333 2.4 7.2C2.4 7.42667 2.47653 7.61653 2.6296 7.7696C2.7832 7.9232 2.97333 8 3.2 8ZM3.2 11.2H5.6C5.82667 11.2 6.0168 11.1232 6.1704 10.9696C6.32347 10.8165 6.4 10.6267 6.4 10.4C6.4 10.1733 6.32347 9.9832 6.1704 9.8296C6.0168 9.67653 5.82667 9.6 5.6 9.6H3.2C2.97333 9.6 2.7832 9.67653 2.6296 9.8296C2.47653 9.9832 2.4 10.1733 2.4 10.4C2.4 10.6267 2.47653 10.8165 2.6296 10.9696C2.7832 11.1232 2.97333 11.2 3.2 11.2ZM10.04 9.26C10.1467 9.26 10.2467 9.2432 10.34 9.2096C10.4333 9.17653 10.52 9.12 10.6 9.04L13.44 6.2C13.6133 6.02667 13.6968 5.83653 13.6904 5.6296C13.6835 5.4232 13.6 5.24 13.44 5.08C13.28 4.92 13.0899 4.84 12.8696 4.84C12.6499 4.84 12.46 4.92 12.3 5.08L10.04 7.34L9.46 6.76C9.3 6.6 9.11333 6.5232 8.9 6.5296C8.68667 6.53653 8.5 6.62 8.34 6.78C8.19333 6.94 8.11653 7.12667 8.1096 7.34C8.1032 7.55333 8.18 7.74 8.34 7.9L9.48 9.04C9.56 9.12 9.64667 9.17653 9.74 9.2096C9.83333 9.2432 9.93333 9.26 10.04 9.26ZM1.6 14.4C1.16 14.4 0.783467 14.2435 0.4704 13.9304C0.1568 13.6168 0 13.24 0 12.8V1.6C0 1.16 0.1568 0.7832 0.4704 0.4696C0.783467 0.156533 1.16 0 1.6 0H14.4C14.84 0 15.2168 0.156533 15.5304 0.4696C15.8435 0.7832 16 1.16 16 1.6V12.8C16 13.24 15.8435 13.6168 15.5304 13.9304C15.2168 14.2435 14.84 14.4 14.4 14.4H1.6ZM1.6 12.8H14.4V1.6H1.6V12.8ZM1.6 12.8V1.6V12.8Z" />
    </SvgIcon>
  );
});

export default QAToolIcon;

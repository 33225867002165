import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Shooting: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 5C31.3807 5 32.5 6.11929 32.5 7.5V10.1547C41.5468 11.2829 48.7171 18.4532 49.8453 27.5H52.5C53.8807 27.5 55 28.6193 55 30C55 31.3807 53.8807 32.5 52.5 32.5H49.8453C48.7171 41.5468 41.5468 48.7171 32.5 49.8453V52.5C32.5 53.8807 31.3807 55 30 55C28.6193 55 27.5 53.8807 27.5 52.5V49.8453C18.4532 48.7171 11.2829 41.5468 10.1547 32.5H7.5C6.11929 32.5 5 31.3807 5 30C5 28.6193 6.11929 27.5 7.5 27.5H10.1547C11.2829 18.4532 18.4532 11.2829 27.5 10.1547V7.5C27.5 6.11929 28.6193 5 30 5ZM27.5084 15.206C21.2204 16.2573 16.2573 21.2204 15.206 27.5084C16.4903 27.6131 17.5 28.6886 17.5 30C17.5 31.3114 16.4903 32.3869 15.206 32.4916C16.2573 38.7797 21.2204 43.7427 27.5084 44.794C27.6131 43.5097 28.6886 42.5 30 42.5C31.3114 42.5 32.3869 43.5097 32.4916 44.794C38.7796 43.7427 43.7427 38.7797 44.794 32.4916C43.5097 32.3869 42.5 31.3114 42.5 30C42.5 28.6886 43.5097 27.6131 44.794 27.5084C43.7427 21.2204 38.7796 16.2573 32.4916 15.206C32.3869 16.4903 31.3114 17.5 30 17.5C28.6886 17.5 27.6131 16.4903 27.5084 15.206ZM30 25C27.2386 25 25 27.2386 25 30C25 32.7614 27.2386 35 30 35C32.7614 35 35 32.7614 35 30C35 27.2386 32.7614 25 30 25ZM20 30C20 24.4772 24.4772 20 30 20C35.5228 20 40 24.4772 40 30C40 35.5228 35.5228 40 30 40C24.4772 40 20 35.5228 20 30Z"
      />
    </SvgIcon>
  );
});

export default Shooting;

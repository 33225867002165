/**
 * Initialize FontAwesome icon library by specifying which icons to include
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import { faMailBulk, faAward, faDolly, faColumns, faTrophy, faVial, faWrench, faGift, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

[faMailBulk, faAward, faDolly, faColumns, faTrophy, faVial, faWrench, faGift, faGoogle, faUnlock].forEach((icon) => {
  library.add(icon);
});

import React from 'react';
import MainRequirementNotFulfilledPopup from './MainRequirementNotFulfilledPopup';
import SDKFeaturesFailedPopup from './SDKFeaturesFailedPopup';
import SDKNotImplementedPopup from './SDKNotImplementedPopup';
import SDKFeaturesNotDetectedPopup from './SDKFeaturesNotDetectedPopup';
import SubmissionInfoPopup from './SubmissionInfoPopup';
import QuitQAToolPopup from './QuitQAToolPopup';
import { PreviewSubmission } from '../../../../Preview/preview-graphql';

export type Popups =
  | null
  | 'mainRequirementNotFulfilled'
  | 'noSDK'
  | 'sdkFeaturesNotDetected'
  | 'sdkFeaturesFailed'
  | 'submissionInfoInput'
  | 'quitQaTool';

export interface Props {
  submission: PreviewSubmission;
  currentPopup: Popups;
  nextStep: () => void;
  isDraft: boolean;
  close: () => void;
}

const PopupManager: React.FC<Props> = ({ currentPopup, nextStep, isDraft, close, submission }) => {
  switch (currentPopup) {
    case 'mainRequirementNotFulfilled':
      return <MainRequirementNotFulfilledPopup submission={submission} next={nextStep} back={close} />;
    case 'noSDK':
      return <SDKNotImplementedPopup submission={submission} next={nextStep} back={close} />;
    case 'sdkFeaturesFailed':
      return <SDKFeaturesFailedPopup submission={submission} next={nextStep} back={close} />;
    case 'sdkFeaturesNotDetected':
      return <SDKFeaturesNotDetectedPopup submission={submission} next={nextStep} back={close} />;
    case 'submissionInfoInput':
      return <SubmissionInfoPopup next={nextStep} back={close} isDraft={isDraft} />;
    case 'quitQaTool':
      return <QuitQAToolPopup submission={submission} back={close} />;
  }
  return null;
};

export default PopupManager;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Mobile: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g id="Icon/Smartphone">
        <path
          id="Icon (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5C5 3.34315 6.34315 2 8 2H16C17.6569 2 19 3.34315 19 5V19C19 20.6569 17.6569 22 16 22H8C6.34315 22 5 20.6569 5 19V5ZM8 4C7.44772 4 7 4.44772 7 5V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V5C17 4.44772 16.5523 4 16 4H8ZM11 18C11 17.4477 11.4477 17 12 17H12.01C12.5623 17 13.01 17.4477 13.01 18C13.01 18.5523 12.5623 19 12.01 19H12C11.4477 19 11 18.5523 11 18Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
});

export default Mobile;

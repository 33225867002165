import * as React from 'react';

import { RouteProps } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import EnsureCompletedProfileLoader from './EnsureCompletedProfileLoader';

export type CompletedProfileRouteProps = RouteProps;

class CompletedProfileRoute extends React.Component<CompletedProfileRouteProps> {
  constructor(props: CompletedProfileRouteProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { component: Component, render, children, ...rest } = this.props;
    return (
      <AuthenticatedRoute
        {...rest}
        render={(route) => {
          if (Component) {
            return (
              <EnsureCompletedProfileLoader>
                <Component {...route} />
              </EnsureCompletedProfileLoader>
            );
          }
          if (render) {
            throw new Error('render is not supported');
          }
          if (children) {
            return <EnsureCompletedProfileLoader>{children}</EnsureCompletedProfileLoader>;
          }
          throw new Error('[CompletedProfileRoute] expected either component render or children to be set');
        }}
      />
    );
  }
}

export default CompletedProfileRoute;

import config from './config';

const { filesDomain } = config.aws;
const DOCUMENT_PREFIX = `https://${filesDomain}/documents`;

const documentUrl = (document: string): string => {
  return `${DOCUMENT_PREFIX}/${encodeURIComponent(document)}`;
};

export default documentUrl;

import React from 'react';
import { NoticeSnackbarQuery, NOTICE_SNACKBAR_QUERY } from './developer-notice-graphql';
import { useQuery } from '@apollo/client';
const NoticeSnackbar = React.lazy(() => import('./NoticeSnackbar'));

const NoticeSnackbarLoader: React.FC = () => {
  const { loading, error, data } = useQuery<NoticeSnackbarQuery>(NOTICE_SNACKBAR_QUERY);
  if (loading || error) {
    return null;
  } else if (!data || !data.notice) {
    return null;
  } else {
    return <NoticeSnackbar notice={data!.notice} />;
  }
};

export default NoticeSnackbarLoader;

import { Option } from '@mui/base';
import React from 'react';
import { CustomSelect } from '../../../../../../common/Styleguide/Common/Select';
import { GameCategory } from './gamedetailsform-graphql';
import { HALF_INPUT_WIDTH } from '../UpdateSubmission/UpdateSubmission.types';

interface CategorySelectProps {
  selected: string | null;
  categories: GameCategory[];
  onChange: (cat: string | null) => void;
  error?: boolean;
  disabled?: boolean;
}

class CategorySelector extends React.Component<CategorySelectProps> {
  render() {
    const { selected, categories, error = false, disabled = false } = this.props;
    return (
      <CustomSelect
        error={error}
        disabled={disabled}
        value={selected ? selected : null}
        onChange={(e: any, newValue: any) => {
          const { onChange } = this.props;
          onChange(newValue);
        }}
        style={{ width: HALF_INPUT_WIDTH }}
      >
        {categories
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((singleCategory) => {
            return (
              <Option key={singleCategory.id} value={singleCategory.id}>
                {singleCategory.name}
              </Option>
            );
          })}
      </CustomSelect>
    );
  }
}

export default CategorySelector;

import React from 'react';
import {
  DeveloperProfile,
  DeveloperProfileGranularUpdateInput,
  DeveloperPublicProfileUpdateInput,
  granularSaveAccount,
  savePublicProfile,
} from './profile-graphql';
import PublicProfile from './PublicProfile';
import Account from './Account';
import { StyledHeaderText } from '../../../../common/Styleguide/Common/Text';
import Footer from '../../Footer/Footer';
import ContentHeader from '../../../../common/Styleguide/ContentHeader';
import { useTheme } from '@mui/system';
import Container from '../../../../common/Styleguide/Container';
import { COLORS } from '../../../../common/Styleguide/Common/colors';
import { Divider, Typography } from '@mui/material';
import SessionContext from '../../../../common/Session/SessionContext';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../../common/Styleguide/Common/Button';
import { GraphQLError } from '../../../../common/components/ApolloError/GraphQLError';
import Space from '../../../../common/Styleguide/Space';

export interface ProfileProps {
  profile: DeveloperProfile;
}

export interface AccountData {
  name: string;
  nameError: string | null;
  email: string;
  verificationEmailSent: boolean;
  acceptLegal: boolean;
  feedback: boolean;
  loading: boolean;
}

export interface PublicProfileData {
  contactEmail?: string;
  contactEmailError: string | null;
  displayName?: string;
  displayNameError: string | null;
  tiktok?: string;
  tiktokError: string | null;
  discord?: string;
  discordError: string | null;
  facebook?: string;
  facebookError: string | null;
  instagram?: string;
  instagramError: string | null;
  twitter?: string;
  twitterError: string | null;
  youtube?: string;
  youtubeError: string | null;
  loading: boolean;
  isValid: boolean;
  validateEmailNotification: boolean;
}

const Profile: React.FC<ProfileProps> = ({ profile }) => {
  const session = React.useContext(SessionContext);
  const { name: sessionName, email: sessionEmail } = session.getUser();
  const { spacing } = useTheme();

  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ApolloError | null>(null);
  const [accountData, setAccountData] = React.useState<AccountData>({
    name: profile.name || sessionName,
    nameError: null,
    email: profile.email || sessionEmail,
    acceptLegal: profile.hasAcceptedLegal,
    feedback: !profile.hasOptedOutUserFeedbackEmail,
    verificationEmailSent: false,
    loading: false,
  });
  const [publicProfileData, setPublicProfileData] = React.useState<PublicProfileData>({
    contactEmail: profile.publicProfile.contactEmail || '',
    contactEmailError: null,
    displayName: profile.publicProfile.displayName || '',
    displayNameError: null,
    tiktok: profile.publicProfile.tiktok || '',
    tiktokError: null,
    discord: profile.publicProfile.discord || '',
    discordError: null,
    facebook: profile.publicProfile.facebook || '',
    facebookError: null,
    instagram: profile.publicProfile.instagram || '',
    instagramError: null,
    twitter: profile.publicProfile.twitter || '',
    twitterError: null,
    youtube: profile.publicProfile.youtube || '',
    youtubeError: null,
    isValid: true,
    loading: false,
    validateEmailNotification: false,
  });

  const save = async () => {
    const publicProfileInput: DeveloperPublicProfileUpdateInput = {
      contactEmail: publicProfileData.contactEmail || undefined,
      displayName: publicProfileData.displayName || undefined,
      tiktok: publicProfileData.tiktok || undefined,
      discord: publicProfileData.discord || undefined,
      instagram: publicProfileData.instagram || undefined,
      facebook: publicProfileData.facebook || undefined,
      twitter: publicProfileData.twitter || undefined,
      youtube: publicProfileData.youtube || undefined,
    };

    const accountInput: DeveloperProfileGranularUpdateInput = {
      name: accountData.name,
      hasOptedOutUserFeedbackEmail: !accountData.feedback,
    };

    setLoading(true);
    setError(null);
    toast.loading('Saving account...');

    try {
      await granularSaveAccount(accountInput);
      await savePublicProfile(publicProfileInput);
      toast.dismiss();
      toast.success('Account saved successfully!');
      setLoading(false);
    } catch (e) {
      console.error('Error saving account or public profile', JSON.stringify(e, null, 2));
      toast.dismiss();
      toast.error('Error saving account or profile', { autoClose: false });
      setLoading(false);
      setError(e as ApolloError);
    }
  };

  const renderNotification = () => {
    if (publicProfileData.validateEmailNotification) {
      return (
        <Space variant="ABOVE">
          <div>
            <Typography>You updated your contact email, you now need to verify it, check your inbox!</Typography>
          </div>
        </Space>
      );
    }
    if (error) {
      return <GraphQLError error={error}>Update failed</GraphQLError>;
    }
    return null;
  };

  const renderButton = () => {
    const emailBlank = accountData.email.length === 0;
    const nameBlank = accountData.name.length === 0;
    const disabled = !accountData.acceptLegal || loading || emailBlank || nameBlank || !isValid;
    return (
      <StyledButton disabled={disabled} variant="contained" onClick={save} sx={{ mt: 3, width: 230 }}>
        Save changes
      </StyledButton>
    );
  };

  React.useEffect(() => {
    const valid = !(
      publicProfileData.facebookError ||
      publicProfileData.twitterError ||
      publicProfileData.tiktokError ||
      publicProfileData.discordError ||
      publicProfileData.instagramError ||
      publicProfileData.contactEmailError ||
      publicProfileData.displayNameError ||
      publicProfileData.youtubeError ||
      accountData.nameError
    );
    setIsValid(valid);
  }, [accountData, publicProfileData]);

  return (
    <>
      <ContentHeader title="Profile" />
      <Container>
        <div style={{ padding: spacing(1, 3) }}>
          <StyledHeaderText variant="h2">Public Profile</StyledHeaderText>
          <div style={{ fontSize: 16, marginBottom: spacing(2), color: COLORS.white[60] }}>
            The provided info will be used to display your social media accounts on our website. The email address will be used to let our
            users contact you, but will not be publicly visible. The display name is shown below your game.
          </div>
          {accountData.email && <PublicProfile publicProfileData={publicProfileData} updatePublicProfileDataFn={setPublicProfileData} />}
          <Divider sx={{ my: 4 }} />
          <Account accountData={accountData} updateAccountDataFn={setAccountData} />
          {renderNotification()}
          {renderButton()}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Profile;

import React from 'react';
import { styled } from '@mui/material/styles';
import PrivacyLink from '../../../common/components/PrivacyLink';
import TermsLink from '../../../common/components/TermsLink';
import { Grid } from '@mui/material';
import CrazyGamesLogo from '../../../common/components/CrazyGamesLogo/CrazyGamesLogo';

const PREFIX = 'Footer';

const classes = {
  footerContainer: `${PREFIX}-footerContainer`,
  footerBack: `${PREFIX}-footerBack`,
  legalData: `${PREFIX}-legalData`,
  addressContainer: `${PREFIX}-addressContainer`,
};

const Root = styled('div')(({ theme: { spacing } }) => ({
  [`&.${classes.footerContainer}`]: {
    position: 'relative',
    color: 'white',
    opacity: 1,
    paddingTop: 30,
    '& a': {
      color: 'white',
    },
  },
  [`&.${classes.footerBack}`]: {
    background: '#13141E',
  },
  [`& .${classes.legalData}`]: {
    fontSize: '0.7rem',
    marginTop: 30,
    marginBottom: 45,
    padding: spacing(),
  },
  [`& .${classes.addressContainer}`]: {
    textAlign: 'center',
    fontSize: '0.9rem',
    marginTop: 16,
  },
}));

interface FooterProps {
  transparent?: boolean;
}

const Footer: React.FunctionComponent<FooterProps> = ({ transparent }) => {
  return (
    <Root className={`${classes.footerContainer} ${!transparent && classes.footerBack}`}>
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <Grid item>
          <CrazyGamesLogo />
        </Grid>
        <Grid item className={classes.addressContainer}>
          Prof. Roger Van Overstraetenplein 2 <br />
          3000 Leuven, Belgium
        </Grid>
        <Grid item className={classes.legalData}>
          &copy; Maxflow BV, Ketelmakerij 20, 3010 Leuven, Belgium. VAT: BE 0550.758.377 &nbsp;|&nbsp;
          <TermsLink />
          &nbsp;|&nbsp;
          <PrivacyLink />
        </Grid>
      </Grid>
    </Root>
  );
};

export default Footer;

import React from 'react';
import { ActionLinkContainer } from './actionLink.styles';
import { StyledButton } from '../../../common/Styleguide/Common/Button';
import ArrowLeft from '../../../common/icons/ArrowLeft';
import ArrowRight from '../../../common/icons/ArrowRight';

export const ACTION_LINK_HEIGHT = 80;

export interface ActionLinkButton {
  title: string;
  callback: () => void;
}

export interface ActionLinkProps {
  primaryButton: ActionLinkButton;
  primaryButtonDisabled?: boolean;
  secondaryButton?: ActionLinkButton;
  secondaryButtonDisabled?: boolean;
}

const ActionLink: React.FC<ActionLinkProps> = ({ primaryButton, primaryButtonDisabled = false, secondaryButton }) => {
  return (
    <ActionLinkContainer>
      {secondaryButton && (
        <StyledButton
          variant="outlined"
          onClick={() => {
            secondaryButton!.callback();
          }}
        >
          <ArrowLeft />
          {secondaryButton.title}
        </StyledButton>
      )}
      <StyledButton
        variant="contained"
        color="white"
        disabled={primaryButtonDisabled}
        onClick={() => {
          primaryButton.callback();
        }}
      >
        {primaryButton.title}
        <ArrowRight sx={{ ml: 1 }} />
      </StyledButton>
    </ActionLinkContainer>
  );
};

export default ActionLink;

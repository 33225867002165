import React from 'react';
import { PROFILE_QUERY, ProfileQuery, DeveloperProfile } from './profile-graphql';
import Profile from './Profile';
import { useQuery } from '@apollo/client';
import GraphQLLoader from '../../../../common/graphql/graphql-loader';
import RolesContext from '../../Session/RolesContext';

export interface ProfileContextType {
  profile: DeveloperProfile | null;
}

export const ProfileContext = React.createContext<ProfileContextType>({
  profile: null,
});

const ProfileLoader: React.FC = () => {
  const { loading, error, data } = useQuery<ProfileQuery>(PROFILE_QUERY);
  return (
    <GraphQLLoader loading={loading} error={error}>
      {!loading && !error && <Profile profile={data!.me} />}
    </GraphQLLoader>
  );
};

interface ProfileProviderProps {
  children?: React.ReactNode;
}

export const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const { loading, error, data } = useQuery<ProfileQuery>(PROFILE_QUERY);
  if (loading) {
    return null;
  }
  const value = !loading && !error ? data!.me : null;

  return (
    <RolesContext.Provider value={{ roles: value?.roles || [] }}>
      <ProfileContext.Provider value={{ profile: value }}>{children}</ProfileContext.Provider>
    </RolesContext.Provider>
  );
};

export default ProfileLoader;

import * as React from 'react';
import { captureReactError, showReportDialog } from '../sentry';
import { Grid, Button, Typography } from '@mui/material';

const DEFAULT_MESSAGE = 'Oops! Something went wrong';
const DEFAULT_MESSAGE_DETAILS =
  'We have been notified about the issue. ' +
  'You may always provide us additional information that might help us to reproduce the issue. ' +
  'In the meantime you can always try again by refreshing your page';

export interface CzyErrorBoundaryProps {
  children?: React.ReactNode;
}

interface CzyErrorBoundaryState {
  hasError: boolean;
}

class CzyErrorBoundary extends React.Component<CzyErrorBoundaryProps, CzyErrorBoundaryState> {
  constructor(props: CzyErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: any, info: React.ErrorInfo) {
    if (error instanceof Error) {
      console.error(`[${error.name}] ${error.message}`, error.stack);
    }
    captureReactError(error, info);
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          container={true}
          spacing={3}
          direction="column"
          alignItems="center"
          alignContent="center"
          style={{ width: '100%', paddingTop: 48 }}
        >
          <Grid item={true}>
            <Typography variant="h5" align="center">
              {DEFAULT_MESSAGE}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant="subtitle1" align="center">
              {DEFAULT_MESSAGE_DETAILS}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Button onClick={() => showReportDialog()} variant="contained">
              Report feedback
            </Button>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default CzyErrorBoundary;

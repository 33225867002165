import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

// init always the UA parser, in case developers enable mobile preview in browser
function isOnMobileOrTablet() {
  const parser = new UAParser();
  const device = parser.getDevice();
  return device.type === 'mobile' || device.type === 'tablet';
}

export function useIsMobileOrTablet() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(isOnMobileOrTablet());

  useEffect(() => {
    const onResize = () => {
      setTimeout(() => {
        setIsMobileOrTablet(isOnMobileOrTablet());
      }, 0);
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isMobileOrTablet;
}

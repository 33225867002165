import { Option } from '@mui/base';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import { CustomSelect } from '../Styleguide/Common/Select';
import { HALF_INPUT_WIDTH } from '../../components/App/Content/UserDeveloper/Submission/UpdateSubmission/UpdateSubmission.types';

export type GAME_ORIENTATION = 'PORTRAIT' | 'LANDSCAPE' | 'BOTH';
export const GAME_ORIENTATIONS: GAME_ORIENTATION[] = ['PORTRAIT', 'LANDSCAPE', 'BOTH'];

export const getOrientationIcon = (gameOrientation: GAME_ORIENTATION) => {
  switch (gameOrientation) {
    case 'PORTRAIT':
      return <CropPortraitIcon sx={{ mr: 1 }} />;
    case 'LANDSCAPE':
      return <CropLandscapeIcon sx={{ mr: 1 }} />;
    case 'BOTH':
      return (
        <>
          <CropPortraitIcon />
          <CropLandscapeIcon sx={{ mr: 1 }} />
        </>
      );
  }
};

interface OrientationSelectorProps {
  disabled?: boolean;
  onChange: (orientation: string | null) => void;
  defaultValue: GAME_ORIENTATION | null;
  error?: boolean;
}
const OrientationSelector = ({ disabled, defaultValue, onChange, error }: OrientationSelectorProps) => {
  return (
    <CustomSelect
      value={defaultValue ? defaultValue : null}
      error={error}
      disabled={disabled}
      onChange={(_e, newValue) => {
        onChange(newValue as string);
      }}
      style={{ width: HALF_INPUT_WIDTH }}
    >
      {GAME_ORIENTATIONS.map((singleGameOrientation) => {
        return (
          <Option key={singleGameOrientation} value={singleGameOrientation} style={{ alignItems: 'center', display: 'flex' }}>
            {getOrientationIcon(singleGameOrientation)}
            {singleGameOrientation}
          </Option>
        );
      })}
    </CustomSelect>
  );
};

export default OrientationSelector;

import { FormControlLabel, styled, Theme, typographyClasses } from '@mui/material';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme: { spacing } }: { theme: Theme }) => ({
  [`& .${typographyClasses.root}`]: {
    color: COLORS.white[60],
    marginLeft: spacing(1),
  },
  marginBottom: spacing(3),
  marginRight: spacing(0.5),
  paddingBottom: 0,
}));

import config from './config';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth as getFirebaseAuth } from 'firebase/auth';

let app: FirebaseApp | null = null;
export function getApp() {
  if (!app) {
    app = initializeApp(config.firebase);
  }
  return app;
}

let auth: Auth | null = null;
export function getAuth() {
  if (!auth) {
    auth = getFirebaseAuth(getApp());
  }
  return auth;
}

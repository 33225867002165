import React from 'react';
import { User as FirebaseUser } from 'firebase/auth';

export class SessionContextData {
  /**
   * The currently logged in user, null if no user is logged in.
   */
  user: User | null;

  /**
   * Get the currently logged in user
   * @throws Error if no user is logged in
   */
  getUser(): User {
    if (!this.user) {
      throw new Error('[SessionContextData] no user is logged in');
    }
    return this.user;
  }

  /**
   * Checks if a user is currently logged in
   */
  isLoggedIn(): boolean {
    return !!this.user;
  }

  userReload() {
    if (!this.user) {
      return;
    }
    this.user.firebaseUser.reload();
    this.user = this.getNewUser(this.user.firebaseUser);
  }

  constructor(firebaseUser: FirebaseUser | null) {
    if (firebaseUser) {
      this.user = this.getNewUser(firebaseUser);
    } else {
      this.user = null;
    }
  }

  private getNewUser(firebaseUser: FirebaseUser): User {
    return {
      firebaseUser: firebaseUser,
      email: firebaseUser.email || '<no-email>',
      emailVerified: firebaseUser.emailVerified,
      name: firebaseUser.displayName || firebaseUser.email || '<no-name>',
    };
  }
}

export interface User {
  /**
   * The firebase user object
   */
  firebaseUser: FirebaseUser;

  /**
   * Email of the logged in user
   */
  email: string;

  /**
   * Is the user's email verified by firebase?
   */
  emailVerified: boolean;

  /**
   * Name of the logged in user
   */
  name: string;
}

export const DEFAULT_SESSION_CONTEXT_DATA = new SessionContextData(null);

const SessionContext = React.createContext<SessionContextData>(DEFAULT_SESSION_CONTEXT_DATA);

export default SessionContext;

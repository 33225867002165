import { createTheme } from '@mui/material/styles';
import { COLORS } from '../../common/Styleguide/Common/colors';
import { CSSProperties } from 'react';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// if you need default values, e.g. for breakpoints, use this:
const defaultTheme = createTheme();

export const primaryColor = defaultTheme.palette.primary.main;

export const permanentMenuWidth = 250;
export const fontNunito = ['Nunito', 'Arial', '"Helvetica Neue"', 'Helvetica', 'sans-serif'].join(',');

// ***************** SCROLLBAR *********************
export const scrollbarStyles = (widthOrHeight: number, hovering: boolean = true) => {
  return {
    '&::-webkit-scrollbar': {
      width: hovering ? widthOrHeight : 0,
      height: hovering ? widthOrHeight : 0,
      background: hovering ? 'rgba(0,0,0,0)' : undefined,
    },
    '&::-webkit-scrollbar-thumb': {
      background: hovering ? COLORS.white[40] : undefined,
      borderRadius: 30,
    },
    '&::-webkit-scrollbar-corner': {
      background: hovering ? 'rgba(0,0,0,0)' : undefined,
    },
    scrollbarWidth: hovering ? 'thin' : 'none',
    scrollbarColor: hovering ? `${COLORS.white[40]}` : undefined,
  } as CSSProperties;
};

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...scrollbarStyles(6),
        },
        body: {
          overflowY: 'overlay',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // By default the width is defined by the contents. This doesn't play well with
          // the combination of a responsive or permanent drawer, so specify the width explicitly here.
          width: permanentMenuWidth,
          overflowX: 'hidden',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: COLORS.white[60],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 800,
          textTransform: 'none',
          borderRadius: 30,
          height: 40,
          color: COLORS.white[100],
        },
        textPrimary: {
          color: COLORS.white[100],
          '&:hover': {
            background: COLORS.black[90],
          },
        },
      },
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
          background: COLORS.black[70],
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 28,
          marginTop: 24,
          textAlign: 'center',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.black[60],
          padding: 30,
          justifyContent: 'center',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          svg: {
            color: COLORS.white[100],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: COLORS.brand[60],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: COLORS.white[80],
          '&.Mui-selected': {
            color: COLORS.brand[60],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.black[70],
          color: COLORS.white[80],
        },
      },
    },
    MuiCheckbox: {
      defaultProps: { checkedIcon: <CheckBoxOutlinedIcon /> },
      styleOverrides: {
        root: {
          color: COLORS.white[80],
          '& svg': {
            color: COLORS.black[30],
          },
          '&.Mui-checked': {
            '& svg': {
              color: COLORS.success[100],
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: COLORS.white[80],
          borderBottom: `1px solid ${COLORS.black[80]}`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: COLORS.white[80],
          fieldset: {
            borderColor: `${COLORS.white[10]}`,
          },
          '&:hover': {
            fieldset: {
              borderColor: `${COLORS.white[80]} !important`,
            },
          },
        },
        input: {
          '&:disabled': {
            WebkitTextFillColor: COLORS.black[20],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: COLORS.black[10],
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          ':hover': {
            ':before': {
              borderBottom: `1px solid ${COLORS.white[50]} !important`,
            },
          },
          ':before': {
            borderBottom: `1px solid ${COLORS.white[10]}`,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          style: {
            borderRadius: 8,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: 'white !important',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: COLORS.white[30],
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: '#ec952e',
          color: COLORS.white[100],
          alignItems: 'center',
          '& svg': {
            color: COLORS.white[100],
          },
        },
        standardInfo: {
          backgroundColor: 'rgb(45 158 205)',
          color: COLORS.white[100],
          alignItems: 'center',
          '& svg': {
            color: COLORS.white[100],
          },
        },
      },
    },
    ...({
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: COLORS.white[100],
          },
        },
      },
    } as any), // not present for some reason in default theme typings although it should be
  },

  typography: {
    fontFamily: fontNunito,
    allVariants: {
      color: COLORS.white[80],
    },
  },
  palette: {
    primary: {
      main: COLORS.brand[100],
    },
    secondary: {
      main: COLORS.brand[100],
    },
    success: {
      main: COLORS.success[100],
    },
  },
});

export default theme;

import { SvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const ArrowSimpleRight: React.FC<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.23327 1.35917C4.4491 1.11999 4.81796 1.10106 5.05714 1.31689L9.90549 5.69189C10.6977 6.40679 10.6977 7.59314 9.90549 8.30804L5.05714 12.683C4.81796 12.8989 4.4491 12.8799 4.23327 12.6408C4.01744 12.4016 4.03637 12.0327 4.27555 11.8169L9.1239 7.44189C9.40272 7.19029 9.40272 6.80964 9.1239 6.55804L4.27555 2.18304C4.03637 1.96721 4.01744 1.59835 4.23327 1.35917Z"
      />
    </SvgIcon>
  );
});

export default ArrowSimpleRight;

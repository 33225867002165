import config from '../config';

export function sendUserId(userId: string) {
  if (!config.ga4ID || isGTagNotInit()) {
    return;
  }
  gtag('config', config.ga4ID, { user_id: userId });
}

export function trackRegistrationFinished() {
  if (isGTagNotInit()) {
    return;
  }
  gtag('event', 'registration_finished');
}

export function trackSubmissionStarted() {
  if (isGTagNotInit()) {
    return;
  }
  gtag('event', 'submission_started');
}

export function trackSubmissionFinished() {
  if (isGTagNotInit()) {
    return;
  }
  gtag('event', 'submission_finished');
}

function isGTagNotInit(): boolean {
  return typeof gtag === 'undefined';
}

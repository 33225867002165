import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Money: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 35 35">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 20.4167C5.18041 20.4167 5.83333 21.0696 5.83333 21.875V26.25C5.83333 27.0554 5.18041 27.7083 4.375 27.7083C3.56958 27.7083 2.91667 27.0554 2.91667 26.25V21.875C2.91667 21.0696 3.56958 20.4167 4.375 20.4167ZM17.5 20.4167C18.3054 20.4167 18.9583 21.0696 18.9583 21.875V26.25C18.9583 27.0554 18.3054 27.7083 17.5 27.7083C16.6946 27.7083 16.0417 27.0554 16.0417 26.25V21.875C16.0417 21.0696 16.6946 20.4167 17.5 20.4167Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.29167C18.3054 7.29167 18.9583 7.94459 18.9583 8.75001V21.875C18.9583 22.6804 18.3054 23.3333 17.5 23.3333C16.6946 23.3333 16.0417 22.6804 16.0417 21.875V8.75001C16.0417 7.94459 16.6946 7.29167 17.5 7.29167ZM30.625 7.29167C31.4304 7.29167 32.0833 7.94459 32.0833 8.75001V21.875C32.0833 22.6804 31.4304 23.3333 30.625 23.3333C29.8196 23.3333 29.1667 22.6804 29.1667 21.875V8.75001C29.1667 7.94459 29.8196 7.29167 30.625 7.29167Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2083 10.2083C11.0137 10.2083 11.6667 10.8612 11.6667 11.6667L11.6667 16.0417C11.6667 16.8471 11.0137 17.5 10.2083 17.5C9.40292 17.5 8.75 16.8471 8.75 16.0417L8.75 11.6667C8.75 10.8612 9.40292 10.2083 10.2083 10.2083Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83317 26.2283C5.82158 25.4329 5.17317 24.7917 4.375 24.7917C3.56958 24.7917 2.91667 25.4446 2.91667 26.25C2.91667 27.1272 3.32275 27.8531 3.8343 28.3907C4.3387 28.9207 4.99965 29.3316 5.70483 29.645C7.11886 30.2735 8.97011 30.625 10.9375 30.625C12.9049 30.625 14.7561 30.2735 16.1702 29.645C16.8753 29.3316 17.5363 28.9207 18.0407 28.3907C18.5522 27.8531 18.9583 27.1272 18.9583 26.25C18.9583 25.4446 18.3054 24.7917 17.5 24.7917C16.7018 24.7917 16.0534 25.4329 16.0418 26.2283C16.0323 26.25 16.0037 26.3003 15.9279 26.38C15.7681 26.5478 15.468 26.7654 14.9856 26.9798C14.0245 27.4069 12.5945 27.7083 10.9375 27.7083C9.28052 27.7083 7.85052 27.4069 6.8894 26.9798C6.40699 26.7654 6.10689 26.5478 5.94712 26.38C5.87129 26.3003 5.84272 26.25 5.83317 26.2283ZM16.046 26.216C16.0463 26.216 16.0458 26.2185 16.0439 26.2234C16.0448 26.2185 16.0458 26.216 16.046 26.216ZM5.83114 26.2234C5.82921 26.2185 5.82874 26.216 5.82898 26.216C5.82922 26.216 5.83018 26.2185 5.83114 26.2234Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9582 21.8533C18.9466 21.0579 18.2982 20.4167 17.5 20.4167C16.6946 20.4167 16.0417 21.0696 16.0417 21.875C16.0417 22.7522 16.4478 23.4781 16.9593 24.0157C17.4637 24.5457 18.1247 24.9566 18.8298 25.27C20.2439 25.8985 22.0951 26.25 24.0625 26.25C26.0299 26.25 27.8811 25.8985 29.2952 25.27C30.0003 24.9566 30.6613 24.5457 31.1657 24.0157C31.6772 23.4781 32.0833 22.7522 32.0833 21.875C32.0833 21.0696 31.4304 20.4167 30.625 20.4167C29.8268 20.4167 29.1784 21.0579 29.1668 21.8533C29.1573 21.875 29.1287 21.9253 29.0529 22.005C28.8931 22.1728 28.593 22.3904 28.1106 22.6048C27.1495 23.0319 25.7195 23.3333 24.0625 23.3333C22.4055 23.3333 20.9755 23.0319 20.0144 22.6048C19.532 22.3904 19.2319 22.1728 19.0721 22.005C18.9963 21.9253 18.9677 21.875 18.9582 21.8533ZM29.171 21.841C29.1713 21.841 29.1708 21.8435 29.1689 21.8484C29.1698 21.8435 29.1708 21.841 29.171 21.841ZM18.9561 21.8484C18.9542 21.8435 18.9537 21.841 18.954 21.841C18.9542 21.841 18.9552 21.8435 18.9561 21.8484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9582 17.4783C18.9466 16.6829 18.2982 16.0417 17.5 16.0417C16.6946 16.0417 16.0417 16.6946 16.0417 17.5C16.0417 18.3772 16.4478 19.1031 16.9593 19.6407C17.4637 20.1707 18.1247 20.5816 18.8298 20.895C20.2439 21.5235 22.0951 21.875 24.0625 21.875C26.0299 21.875 27.8811 21.5235 29.2952 20.895C30.0003 20.5816 30.6613 20.1707 31.1657 19.6407C31.6772 19.1031 32.0833 18.3772 32.0833 17.5C32.0833 16.6946 31.4304 16.0417 30.625 16.0417C29.8268 16.0417 29.1784 16.6829 29.1668 17.4783C29.1573 17.5 29.1287 17.5503 29.0529 17.63C28.8931 17.7978 28.593 18.0154 28.1106 18.2298C27.1495 18.6569 25.7195 18.9583 24.0625 18.9583C22.4055 18.9583 20.9755 18.6569 20.0144 18.2298C19.532 18.0154 19.2319 17.7978 19.0721 17.63C18.9963 17.5503 18.9677 17.5 18.9582 17.4783ZM29.171 17.466C29.1713 17.466 29.1708 17.4685 29.1689 17.4734C29.1698 17.4685 29.1708 17.466 29.171 17.466ZM18.9561 17.4734C18.9542 17.4685 18.9537 17.466 18.954 17.466C18.9542 17.466 18.9552 17.4685 18.9561 17.4734Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6665 16.02C11.6549 15.2246 11.0065 14.5833 10.2083 14.5833C9.40292 14.5833 8.75 15.2362 8.75 16.0417C8.75 16.9189 9.15609 17.6448 9.66764 18.1823C10.172 18.7123 10.833 19.1233 11.5382 19.4367C12.9522 20.0652 14.8034 20.4167 16.7708 20.4167C17.5762 20.4167 18.2292 19.7637 18.2292 18.9583C18.2292 18.1529 17.5762 17.5 16.7708 17.5C15.1139 17.5 13.6839 17.1986 12.7227 16.7714C12.2403 16.557 11.9402 16.3395 11.7805 16.1716C11.7046 16.0919 11.6761 16.0416 11.6665 16.02ZM11.6645 16.0151C11.6625 16.0101 11.6621 16.0077 11.6623 16.0077C11.6626 16.0077 11.6635 16.0102 11.6645 16.0151Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83317 21.8533C5.82158 21.0579 5.17317 20.4167 4.375 20.4167C3.56958 20.4167 2.91667 21.0696 2.91667 21.875C2.91667 22.7522 3.32275 23.4781 3.8343 24.0157C4.3387 24.5457 4.99965 24.9566 5.70483 25.27C7.11886 25.8985 8.97011 26.25 10.9375 26.25C12.9049 26.25 14.7561 25.8985 16.1702 25.27C16.8753 24.9566 17.5363 24.5457 18.0407 24.0157C18.5522 23.4781 18.9583 22.7522 18.9583 21.875C18.9583 21.0696 18.3054 20.4167 17.5 20.4167C16.7018 20.4167 16.0534 21.0579 16.0418 21.8533C16.0323 21.875 16.0037 21.9253 15.9279 22.005C15.7681 22.1728 15.468 22.3904 14.9856 22.6048C14.0245 23.0319 12.5945 23.3333 10.9375 23.3333C9.28052 23.3333 7.85052 23.0319 6.8894 22.6048C6.40699 22.3904 6.10689 22.1728 5.94712 22.005C5.87129 21.9253 5.84272 21.875 5.83317 21.8533ZM16.046 21.841C16.0463 21.841 16.0458 21.8435 16.0439 21.8484C16.0448 21.8435 16.0458 21.841 16.046 21.841ZM5.83114 21.8484C5.82921 21.8435 5.82874 21.841 5.82898 21.841C5.82922 21.841 5.83018 21.8435 5.83114 21.8484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9582 17.4783C18.9466 16.6829 18.2982 16.0417 17.5 16.0417C16.6946 16.0417 16.0417 16.6946 16.0417 17.5C16.0417 18.3772 16.4478 19.1031 16.9593 19.6407C17.4637 20.1707 18.1247 20.5816 18.8298 20.895C20.2439 21.5235 22.0951 21.875 24.0625 21.875C26.0299 21.875 27.8811 21.5235 29.2952 20.895C30.0003 20.5816 30.6613 20.1707 31.1657 19.6407C31.6772 19.1031 32.0833 18.3772 32.0833 17.5C32.0833 16.6946 31.4304 16.0417 30.625 16.0417C29.8268 16.0417 29.1784 16.6829 29.1668 17.4783C29.1573 17.5 29.1287 17.5503 29.0529 17.63C28.8931 17.7978 28.593 18.0154 28.1106 18.2298C27.1495 18.6569 25.7195 18.9583 24.0625 18.9583C22.4055 18.9583 20.9755 18.6569 20.0144 18.2298C19.532 18.0154 19.2319 17.7978 19.0721 17.63C18.9963 17.5503 18.9677 17.5 18.9582 17.4783ZM29.171 17.466C29.1713 17.466 29.1708 17.4685 29.1689 17.4734C29.1698 17.4685 29.1708 17.466 29.171 17.466ZM18.9561 17.4734C18.9542 17.4685 18.9537 17.466 18.954 17.466C18.9542 17.466 18.9552 17.4685 18.9561 17.4734Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9582 13.1033C18.9466 12.3079 18.2982 11.6667 17.5 11.6667C16.6946 11.6667 16.0417 12.3196 16.0417 13.125C16.0417 14.0022 16.4478 14.7281 16.9593 15.2657C17.4637 15.7957 18.1247 16.2066 18.8298 16.52C20.2439 17.1485 22.0951 17.5 24.0625 17.5C26.0299 17.5 27.8811 17.1485 29.2952 16.52C30.0003 16.2066 30.6613 15.7957 31.1657 15.2657C31.6772 14.7281 32.0833 14.0022 32.0833 13.125C32.0833 12.3196 31.4304 11.6667 30.625 11.6667C29.8268 11.6667 29.1784 12.3079 29.1668 13.1033C29.1573 13.125 29.1287 13.1753 29.0529 13.255C28.8931 13.4228 28.593 13.6404 28.1106 13.8548C27.1495 14.2819 25.7195 14.5833 24.0625 14.5833C22.4055 14.5833 20.9755 14.2819 20.0144 13.8548C19.532 13.6404 19.2319 13.4228 19.0721 13.255C18.9963 13.1753 18.9677 13.125 18.9582 13.1033ZM29.171 13.091C29.1713 13.091 29.1708 13.0935 29.1689 13.0984C29.1698 13.0935 29.1708 13.091 29.171 13.091ZM18.9561 13.0984C18.9542 13.0935 18.9537 13.091 18.954 13.091C18.9542 13.091 18.9552 13.0935 18.9561 13.0984Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9582 8.72832C18.9466 7.9329 18.2982 7.29167 17.5 7.29167C16.6946 7.29167 16.0417 7.94459 16.0417 8.75001C16.0417 9.62723 16.4478 10.3531 16.9593 10.8907C17.4637 11.4207 18.1247 11.8316 18.8298 12.145C20.2439 12.7735 22.0951 13.125 24.0625 13.125C26.0299 13.125 27.8811 12.7735 29.2952 12.145C30.0003 11.8316 30.6613 11.4207 31.1657 10.8907C31.6772 10.3531 32.0833 9.62723 32.0833 8.75001C32.0833 7.94459 31.4304 7.29167 30.625 7.29167C29.8268 7.29167 29.1784 7.9329 29.1668 8.72831C29.1573 8.74996 29.1287 8.80027 29.0529 8.87995C28.8931 9.04783 28.593 9.26536 28.1106 9.47976C27.1495 9.90693 25.7195 10.2083 24.0625 10.2083C22.4055 10.2083 20.9755 9.90693 20.0144 9.47976C19.532 9.26536 19.2319 9.04783 19.0721 8.87995C18.9963 8.80027 18.9677 8.74996 18.9582 8.72832ZM29.171 8.71602C29.1713 8.71602 29.1708 8.71848 29.1689 8.72342C29.1698 8.7185 29.1708 8.71603 29.171 8.71602ZM18.9561 8.72342C18.9542 8.71848 18.9537 8.71602 18.954 8.71602C18.9542 8.71603 18.9552 8.7185 18.9561 8.72342Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6665 11.645C11.6549 10.8496 11.0065 10.2083 10.2083 10.2083C9.40292 10.2083 8.75 10.8612 8.75 11.6667C8.75 12.5439 9.15609 13.2698 9.66764 13.8073C10.172 14.3373 10.833 14.7483 11.5382 15.0617C12.9522 15.6902 14.8034 16.0417 16.7708 16.0417C17.5762 16.0417 18.2292 15.3887 18.2292 14.5833C18.2292 13.7779 17.5762 13.125 16.7708 13.125C15.1139 13.125 13.6839 12.8236 12.7227 12.3964C12.2403 12.182 11.9402 11.9645 11.7805 11.7966C11.7046 11.7169 11.6761 11.6666 11.6665 11.645ZM11.6645 11.6401C11.6625 11.6351 11.6621 11.6327 11.6623 11.6327C11.6626 11.6327 11.6635 11.6352 11.6645 11.6401Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0418 21.8967C16.0534 22.6921 16.7018 23.3333 17.5 23.3333C18.3054 23.3333 18.9583 22.6804 18.9583 21.875C18.9583 20.9978 18.5522 20.2719 18.0407 19.7344C17.5363 19.2043 16.8753 18.7934 16.1702 18.48C14.7561 17.8515 12.9049 17.5 10.9375 17.5C8.97011 17.5 7.11886 17.8515 5.70483 18.48C4.99965 18.7934 4.3387 19.2043 3.8343 19.7344C3.32275 20.2719 2.91667 20.9978 2.91667 21.875C2.91667 22.6804 3.56958 23.3333 4.375 23.3333C5.17317 23.3333 5.82158 22.6921 5.83317 21.8967C5.84272 21.8751 5.87129 21.8247 5.94712 21.7451C6.10689 21.5772 6.40699 21.3597 6.8894 21.1453C7.85052 20.7181 9.28052 20.4167 10.9375 20.4167C12.5945 20.4167 14.0245 20.7181 14.9856 21.1453C15.468 21.3597 15.7681 21.5772 15.9279 21.7451C16.0037 21.8247 16.0323 21.8751 16.0418 21.8967ZM5.82898 21.909C5.82874 21.909 5.82921 21.9065 5.83114 21.9016C5.83018 21.9065 5.82922 21.909 5.82898 21.909ZM16.0439 21.9016C16.0458 21.9065 16.0463 21.909 16.046 21.909C16.0458 21.909 16.0448 21.9065 16.0439 21.9016Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1668 8.77168C29.1784 9.5671 29.8268 10.2083 30.625 10.2083C31.4304 10.2083 32.0833 9.55541 32.0833 8.74999C32.0833 7.87277 31.6772 7.14687 31.1657 6.60934C30.6613 6.07933 30.0003 5.66837 29.2952 5.35496C27.8811 4.7265 26.0299 4.37499 24.0625 4.37499C22.0951 4.37499 20.2439 4.7265 18.8298 5.35496C18.1247 5.66837 17.4637 6.07933 16.9593 6.60934C16.4478 7.14687 16.0417 7.87277 16.0417 8.74999C16.0417 9.55541 16.6946 10.2083 17.5 10.2083C18.2982 10.2083 18.9466 9.5671 18.9582 8.77168C18.9677 8.75004 18.9963 8.69973 19.0721 8.62005C19.2319 8.45217 19.532 8.23464 20.0144 8.02024C20.9755 7.59307 22.4055 7.29166 24.0625 7.29166C25.7195 7.29166 27.1495 7.59307 28.1106 8.02024C28.593 8.23464 28.8931 8.45217 29.0529 8.62005C29.1287 8.69973 29.1573 8.75004 29.1668 8.77168ZM18.954 8.78398C18.9537 8.78398 18.9542 8.78152 18.9561 8.77658C18.9552 8.7815 18.9542 8.78397 18.954 8.78398ZM29.1689 8.77658C29.1708 8.78152 29.1713 8.78398 29.171 8.78398C29.1708 8.78397 29.1698 8.7815 29.1689 8.77658Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2083 13.125C9.40292 13.125 8.75 12.4721 8.75 11.6667C8.75 10.7894 9.15609 10.0635 9.66764 9.52601C10.172 8.996 10.833 8.58504 11.5382 8.27163C12.9522 7.64317 14.8034 7.29167 16.7708 7.29167C17.5763 7.29167 18.2292 7.94459 18.2292 8.75C18.2292 9.55542 17.5763 10.2083 16.7708 10.2083C15.1139 10.2083 13.6839 10.5097 12.7227 10.9369C12.2403 11.1513 11.9402 11.3688 11.7805 11.5367C11.7046 11.6164 11.6761 11.6667 11.6665 11.6884C11.6549 12.4838 11.0065 13.125 10.2083 13.125ZM11.6623 11.7006C11.6621 11.7007 11.6625 11.6982 11.6645 11.6932C11.6635 11.6982 11.6626 11.7006 11.6623 11.7006Z"
      />
    </SvgIcon>
  );
});

export default Money;

import { styled } from '@mui/material/styles';
import { COLORS } from '../../../common/Styleguide/Common/colors';

export const ActionLinkContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: theme.spacing(10),
  background: COLORS.brand[100],
  zIndex: 1201,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(),
}));

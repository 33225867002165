import React from 'react';
import { Checkbox, Divider, Radio, RadioGroup } from '@mui/material';
import Container from '../../../../../../common/Styleguide/Container';
import { RevenueShareFormData, buildDefaultRevenueShareFormData } from './RevenueShareFormData';
import Space from '../../../../../../common/Styleguide/Space';
import TermsLink from '../../../../../../common/components/TermsLink';
import PEGIRatingLink from '../../../../../../common/components/PEGIRatingLink';
import { DOCS_URL, FAQ_URL } from '../../../../SideMenu/Menu';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { StyledFormControlLabel } from './revenueShareForm.styles';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import withSubmission, { WithSubmission } from '../Context/withSubmission';

export interface RevenueChecksFormProps {}

type _Props = RevenueChecksFormProps & WithSubmission;

class RevenueShareForm extends React.Component<_Props> {
  constructor(props: _Props) {
    super(props);
    const cleanedData = this.removeImpossibleValues();
    this.props.updateRevenueShareForm(cleanedData);
  }

  render() {
    return (
      <Container>
        <div style={{ width: 900 }}>
          {this.renderConfirmationOfOwnership()}
          {this.renderEligibilityForRevenueShare()}
          {this.renderIncreaseRevenueShare()}
          {this.renderDisallowEmbedding()}
        </div>
      </Container>
    );
  }

  private renderConfirmationOfOwnership() {
    const { doesNotViolate, suitableForChildren, isInvited, hasCustomContract } = this.props.revenueShareForm;
    const hasConfirmedOwnership = this.hasConfirmedOwnership();
    return (
      <Space variant="ABOVE_AND_BELOW">
        <StyledHeaderText variant="h2" color="white80">
          1. Confirmation of ownership
        </StyledHeaderText>
        <StyledBodyText color="white60">
          To publish your game on our portal you need to read, understand, and agree with the &nbsp;
          <TermsLink /> of our developer portal.
        </StyledBodyText>
        {hasConfirmedOwnership ? (
          ' '
        ) : (
          <StyledBodyText color="alert60" variant="bodyLower2">
            You can only publish games that are not in violation with our <TermsLink /> and suit <PEGIRatingLink /> criteria.
          </StyledBodyText>
        )}

        <StyledContainer sx={{ mt: 4, background: COLORS.black[70], pb: 0 }}>
          <RadioGroup>
            <StyledFormControlLabel
              sx={{ mb: isInvited ? 2 : 0 }}
              value="no-invitation"
              control={<Radio checked={!isInvited} onChange={() => this.handleIsInvitedRadio(false)} />}
              label="I submit this game on my own initiative, without an invitation by or agreement with CrazyGames."
            />
            {!isInvited && (
              <StyledFormControlLabel
                control={<Checkbox checked={doesNotViolate} onChange={this.handleDoesNotViolateChange} />}
                sx={{ pl: 5 }}
                label={
                  <>
                    I agree to publish the games under the <TermsLink /> of the developer portal.
                  </>
                }
              />
            )}
            <StyledFormControlLabel
              sx={{ mb: 0 }}
              value="with-invitation"
              control={<Radio checked={isInvited} onChange={() => this.handleIsInvitedRadio(true)} />}
              label="I have been invited by CrazyGames to submit the game and/or my game has an agreement contract with CrazyGames. Selecting this option without an invitation will result in automatic rejection of your submission."
            />
            {isInvited && (
              <RadioGroup>
                <StyledFormControlLabel
                  value="with-terms"
                  control={<Radio checked={doesNotViolate} onChange={this.handleDoesNotViolateChange} />}
                  sx={{ pl: 5, mb: 2 }}
                  label={
                    <>
                      I publish the games under the <TermsLink /> of the developer portal.
                    </>
                  }
                />
                <StyledFormControlLabel
                  value="with-custom"
                  control={<Radio checked={hasCustomContract} onChange={this.handleHasCustomContract} />}
                  sx={{ pl: 5, mb: 0 }}
                  label={
                    <>
                      I have signed a custom contract with CrazyGames. If this option is mistakenly selected, I accept the general{' '}
                      <TermsLink /> of the developer portal.
                    </>
                  }
                />
              </RadioGroup>
            )}
          </RadioGroup>

          <Divider sx={{ my: 2 }} />

          <StyledFormControlLabel
            control={<Checkbox checked={suitableForChildren} onChange={this.handleSuitableForChildrenChange} />}
            label={
              <>
                I confirm that my game is suitable for players aged 12+. In particular, I certify that it suits the <PEGIRatingLink />{' '}
                criteria.
              </>
            }
          />
        </StyledContainer>
      </Space>
    );
  }

  private handleHasCustomContract = (_evt: {}, hasCustomContract: boolean) => {
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, hasCustomContract, doesNotViolate: false });
  };

  private handleDoesNotViolateChange = (_evt: {}, doesNotViolate: boolean) => {
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, doesNotViolate, hasCustomContract: false });
  };

  private handleIsInvitedRadio = (isInvited: boolean) => {
    // Also resetting the checkboxes
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, hasCustomContract: false, doesNotViolate: false, isInvited });
  };

  private handleSuitableForChildrenChange = (_evt: {}, suitableForChildren: boolean) => {
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, suitableForChildren });
  };

  private hasConfirmedOwnership = () => {
    const { doesNotViolate, suitableForChildren, hasCustomContract, isInvited } = this.props.revenueShareForm;
    if (isInvited) {
      return (doesNotViolate || hasCustomContract) && suitableForChildren;
    } else {
      return doesNotViolate && suitableForChildren;
    }
  };

  private renderEligibilityForRevenueShare() {
    return (
      <Space variant="ABOVE_AND_BELOW">
        <StyledHeaderText variant="h2" color="white80">
          2. Eligibility for revenue share
        </StyledHeaderText>
        {this.isIframeSubmission() ? this.renderIframeEligibityForRevenueShare() : this.renderNonIframeEligibilityForRevenueShare()}
      </Space>
    );
  }

  private renderNonIframeEligibilityForRevenueShare() {
    const { isEligible } = this.props.revenueShareForm;
    const isOK = this.isEligible();
    const disabled = !this.hasConfirmedOwnership();
    return (
      <>
        <StyledBodyText color="white60">
          Your game is eligible for revenue share if the conditions described in article 5.3 of our <TermsLink /> have been met. Note that
          you can still publish games without revenue share without accepting the conditions below.
        </StyledBodyText>

        {isOK ? (
          disabled ? (
            <StyledBodyText color="error" variant="bodyLower2">
              Some prior conditions are not met.
            </StyledBodyText>
          ) : (
            <StyledBodyText color="success" variant="bodyLower2">
              Congratulations! Your game is eligible for revenue share.
            </StyledBodyText>
          )
        ) : (
          <StyledBodyText color="white10" variant="bodyLower2">
            Without these options, your game is not eligible for revenue share however you will still be able to see insightful user
            statistics.
          </StyledBodyText>
        )}

        <StyledContainer sx={{ mt: 2, py: 1, px: 1, background: COLORS.black[70] }}>
          <StyledFormControlLabel
            control={<Checkbox checked={isEligible} onChange={this.handleIsEligibleChange} disabled={disabled} />}
            label={<>I read and understood article 5.3 and acknowledge that my game is eligible for revenue share.</>}
            sx={{ m: 0 }}
          />
        </StyledContainer>
      </>
    );
  }

  private renderIframeEligibityForRevenueShare() {
    return (
      <StyledBodyText color="white60">
        Revenue share is not available for games embedded via iframe. Read more about revenue share conditions in section 5.3 in our{' '}
        <TermsLink />.
      </StyledBodyText>
    );
  }

  private handleIsEligibleChange = (_evt: {}, isEligible: boolean) => {
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, isEligible });
  };

  private isEligible = () => {
    const { isEligible } = this.props.revenueShareForm;
    return isEligible;
  };

  private renderIncreaseRevenueShare() {
    if (this.isIframeSubmission()) {
      return null;
    }
    const { makeExclusive, hasImplementedSDK } = this.props.revenueShareForm;
    const hasConfirmedOwnership = this.hasConfirmedOwnership();
    const isEligible = this.isEligible();
    const disabled = !hasConfirmedOwnership || !isEligible;
    const hasIncreasedRevenueShare = this.hasIncreasedRevenueShare();
    return (
      <Space variant="ABOVE_AND_BELOW">
        <StyledHeaderText variant="h2" color="white80">
          3. Increase revenue share with 50%
        </StyledHeaderText>
        <StyledBodyText color="white60">
          We offer a timed-exclusivity option with an increased revenue share of 50%. If you would like to receive the 50% increase for two
          months, you have to accept the following conditions.
        </StyledBodyText>
        {hasIncreasedRevenueShare ? (
          disabled ? (
            <StyledBodyText color="error" variant="bodyLower2">
              Some prior conditions are not met.
            </StyledBodyText>
          ) : (
            <StyledBodyText color="success" variant="bodyLower2">
              Congratulations! Your game will earn 50% more revenue share!
            </StyledBodyText>
          )
        ) : (
          <StyledBodyText color="white10" variant="bodyLower2">
            No 50% increase. Accept all conditions to earn the extra 50% revenue share. Check 'How can I increase my revenue?' in the{' '}
            <a href={FAQ_URL} target="_blank" rel="noopener noreferrer">
              FAQ
            </a>{' '}
            for further details.
          </StyledBodyText>
        )}
        <StyledContainer sx={{ mt: 4, background: COLORS.black[70], pb: 0 }}>
          <StyledFormControlLabel
            control={<Checkbox checked={makeExclusive} onChange={this.handleMakeExclusiveChange} disabled={disabled} />}
            label={
              <>
                I will make this game exclusively available on CrazyGames for two months following the release date. I understand that after
                these two months I am free to upload my game anywhere I like.
              </>
            }
          />
          <StyledFormControlLabel
            control={<Checkbox checked={hasImplementedSDK} onChange={this.handleHasImplementedSDKChange} disabled={disabled} />}
            label={
              <>
                I implemented the CrazyGames SDK, including the in-game advertisements (
                <a href={`${DOCS_URL}/`} target="_blank" rel="noopener noreferrer">
                  SDK Documentation
                </a>
                ).
              </>
            }
          />
        </StyledContainer>
      </Space>
    );
  }

  private hasIncreasedRevenueShare = () => {
    const { makeExclusive, hasImplementedSDK } = this.props.revenueShareForm;
    return makeExclusive && hasImplementedSDK;
  };

  private handleMakeExclusiveChange = (_evt: {}, makeExclusive: boolean) => {
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, makeExclusive });
  };

  private handleHasImplementedSDKChange = (_evt: {}, hasImplementedSDK: boolean) => {
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, hasImplementedSDK });
  };

  private renderDisallowEmbedding() {
    if (this.isIframeSubmission()) {
      return null;
    }
    const { disallowEmbedding } = this.props.revenueShareForm;

    return (
      <Space variant="ABOVE_AND_BELOW">
        <StyledBodyText color="white60">
          Your game may attract additional users from other sites in our network that embed your game. You can disable this by checking the
          box below.
        </StyledBodyText>
        {disallowEmbedding && (
          <StyledBodyText variant="bodyLower2" color="error">
            Are you sure you don't want to display an embed button? It's a great way to get more visibility, 93% of our developers allow
            this.
          </StyledBodyText>
        )}
        <StyledContainer sx={{ mt: 2, py: 1, px: 1, background: COLORS.black[70] }}>
          <StyledFormControlLabel
            control={<Checkbox checked={disallowEmbedding} onChange={this.handleDisallowEmbeddingChange} />}
            label={<>I don't want an embed button displayed on the play page of my game.</>}
            sx={{ m: 0 }}
          />
        </StyledContainer>
      </Space>
    );
  }

  private handleDisallowEmbeddingChange = (_evt: {}, disallowEmbedding: boolean) => {
    this.props.updateRevenueShareForm({ ...this.props.revenueShareForm, disallowEmbedding });
  };

  private isIframeSubmission(): boolean {
    const { gameLoaderType } = this.props.baseForm;
    return gameLoaderType === 'iframe';
  }

  private removeImpossibleValues(): RevenueShareFormData {
    const initial = this.props.revenueShareForm;
    const defaultFormData = buildDefaultRevenueShareFormData();
    if (this.isIframeSubmission()) {
      // iframe submissions are not eligible for increased revenue share
      return {
        ...initial,
        hasImplementedSDK: defaultFormData.hasImplementedSDK,
        isEligible: defaultFormData.isEligible,
        disallowEmbedding: defaultFormData.disallowEmbedding,
        makeExclusive: defaultFormData.makeExclusive,
      };
    }
    return initial;
  }
}

export default withSubmission(RevenueShareForm);

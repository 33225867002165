import React from 'react';

import SessionContext, { SessionContextData, User, DEFAULT_SESSION_CONTEXT_DATA } from './SessionContext';
import CzyLoader from '../Loader';
import { onAuthChange, resolveInitialSession, DetachListener } from './firebase';

export type SessionChangeListener = (session: User | null) => void;

interface Props {
  children?: React.ReactNode;
}

interface State {
  initialized: boolean;
  data: SessionContextData;
}

class Session extends React.Component<Props, State> {
  private static listenerId = 0;

  private static sessionChangedListeners: { [key: number]: SessionChangeListener } = {};

  private detachAuthListener: DetachListener | null;

  static listenForSessionChange(listener: SessionChangeListener) {
    const id = Session.listenerId++;
    Session.sessionChangedListeners[id] = listener;
    return () => delete Session.sessionChangedListeners[id];
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      initialized: false,
      data: DEFAULT_SESSION_CONTEXT_DATA,
    };
    this.detachAuthListener = null;
  }

  componentDidMount() {
    this.initializeFirebaseSession();
  }

  componentWillUnmount() {
    if (this.detachAuthListener) {
      this.detachAuthListener();
    }
  }

  render() {
    if (this.state.initialized) {
      return this.renderInitialSessionResolved();
    } else {
      return this.renderNotResolved();
    }
  }

  renderInitialSessionResolved() {
    return <SessionContext.Provider value={this.state.data}>{this.props.children}</SessionContext.Provider>;
  }

  renderNotResolved() {
    return <CzyLoader />;
  }

  async initializeFirebaseSession(): Promise<void> {
    const data = await resolveInitialSession;
    console.log('[Session] initial user', data.isLoggedIn() ? data.getUser().email : '<anonymous>');
    this.setState({
      initialized: true,
      data: data,
    });
    this.detachAuthListener = onAuthChange(this.handleAuthChange);
  }

  handleAuthChange = (data: SessionContextData) => {
    this.setState({ data: data });
  };
}

export default Session;

import { styled, Theme } from '@mui/system';
import ArrowSimpleRight from '../../../../../../common/icons/ArrowSimpleRight';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';

export const Root = styled('div')(({ theme: { spacing } }: { theme: Theme }) => ({
  height: 70,
  display: 'flex',
  alignItems: 'center',
  background: COLORS.black[80],
  boxShadow: `0px 1px 0px ${COLORS.black[60]}`,
  overflowX: 'auto',
  marginBottom: 1,
  paddingLeft: spacing(2.5),
}));

interface ActiveProps {
  active: boolean;
}

export const NumberContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'active' })<ActiveProps>(
  ({ active, theme: { spacing } }) => ({
    background: active ? COLORS.brand[100] : COLORS.black[10],
    color: active ? COLORS.white[100] : COLORS.black[90],
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 800,
    marginRight: spacing(1.2),
  }),
);

export const PathItem = styled('div', { shouldForwardProp: (prop) => prop !== 'active' })<ActiveProps>(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 700,
  color: active ? COLORS.white[80] : COLORS.black[10],
}));

export const StyledArrowSimpleRight = styled(ArrowSimpleRight)(({ theme: { spacing } }: { theme: Theme }) => ({
  color: COLORS.black[50],
  width: 14,
  marginRight: spacing(2),
  marginLeft: spacing(2.5),
}));

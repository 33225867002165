import React from 'react';
import { Grid, FormControl, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import { getInputValue } from '../../../../common/react';
import { DefaultInput } from '../../../../common/Styleguide/Common/Input';
import { Box } from '@mui/system';
import { AccountData } from './Profile';
import { COLORS } from '../../../../common/Styleguide/Common/colors';

export interface AccountProps {
  accountData: AccountData;
  updateAccountDataFn: (data: AccountData) => void;
}

const MAX_CONTACT_NAME_LENGTH = 50;

const Account: React.FC<AccountProps> = ({ accountData, updateAccountDataFn }) => {
  const [feedback] = React.useState(accountData.feedback);
  const { spacing } = useTheme();

  const updateContactName = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const name = getInputValue(ev);
    const isValid = name.length < MAX_CONTACT_NAME_LENGTH;
    updateAccountDataFn({ ...accountData, name: name, nameError: isValid ? null : 'Invalid contact name' });
  };

  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
        Account
      </Typography>

      <div style={{ fontSize: 16, marginBottom: spacing(2), color: COLORS.white[60] }}>
        The contact name is used in the emails that we send to the provided email address.
      </div>

      <Grid container direction="row">
        <Grid item xs={6}>
          <DefaultInput
            sx={{ mb: 2 }}
            value={accountData.name}
            onChange={updateContactName}
            header="Contact name"
            error={!!accountData.nameError}
            errorText={accountData.nameError!}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <DefaultInput readOnly value={accountData.email} header="Email" />
        </Grid>
      </Grid>

      {!feedback && (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountData.feedback}
                  onChange={(ev) => updateAccountDataFn({ ...accountData, feedback: ev.target.checked })}
                />
              }
              label="I want to receive emails with user feedback"
            />
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default Account;

import numeral from 'numeral';
import { Duration, Moment } from 'moment';
import lodash from './lodash';

export const REVENUE_FORMAT = '0,0.00';

export const PLAYS_FORMAT = '0,0';

export const PERCENTAGE_FORMAT = '0.00';

export function formatEuro(value: number): string {
  return numeral(value).format(REVENUE_FORMAT);
}

export function formatAmount(value: number): string {
  return numeral(value).format(PLAYS_FORMAT);
}

export function formatPercentage(value: number | null, removeDecimals?: boolean): string {
  if (value === null) {
    return '/';
  }
  const format = removeDecimals ? '0' : PERCENTAGE_FORMAT;
  return numeral(value * 100).format(format);
}

export function formatDurationAsHMS(duration: Duration) {
  return (
    `${Math.floor(duration.asHours())}:` +
    `${lodash.padStart(`${duration.minutes()}`, 2, '0')}:` +
    `${lodash.padStart(`${duration.seconds()}`, 2, '0')}`
  );
}

export function formatDateLong(date: Moment) {
  return date.format('MMMM Do YYYY');
}

export function formatDateShort(date: Moment) {
  return date.format('DD-MM-YYYY');
}

export function formatDateAndTime(date: Moment) {
  return date.format('DD/MM/YYYY HH:mm');
}

/**
 * Example:
 *  readableUnitValue(2048, 1024, ['B', 'KB', 'MB', 'GB']) === '2 KB'
 */
function readableUnitValue(input: number, base: number, units: string[]): string {
  function truncate(value: number) {
    return Math.ceil(value * 10) / 10;
  }
  function loop(value: number, idx: number): string {
    if (idx >= units.length || value < 1000) {
      return `${truncate(value)} ${units[idx]}`;
    } else {
      return loop(value / base, idx + 1);
    }
  }
  return loop(input, 0);
}

export function readableBytes(bytes: number): string {
  return readableUnitValue(bytes, 1024, ['B', 'KB', 'MB', 'GB']);
}

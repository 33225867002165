import * as React from 'react';
import { SvgIcon } from '@mui/material';

const CrazyGamesLogo: React.FunctionComponent = React.memo((props) => {
  return (
    <SvgIcon {...props} sx={{ height: 35, width: 97 }} viewBox="0 0 97 35">
      <svg width="97" height="35" viewBox="0 0 97 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.5495 8.66261C41.5495 9.20557 41.7298 9.62707 42.0901 9.92687C42.4502 10.2273 42.833 10.377 43.2387 10.377C43.6439 10.377 44.0232 10.259 44.3776 10.0225C44.7314 9.78645 45.0177 9.66821 45.2364 9.66821C45.6228 9.66821 46.0924 10.0068 46.6454 10.6835C46.9417 11.0542 47.0895 11.3861 47.0895 11.6796C47.0895 12.1651 46.7355 12.6182 46.0278 13.0397C45.2944 13.5506 44.3935 13.806 43.3257 13.806C42.1287 13.806 40.9769 13.3783 39.8705 12.5226C39.3427 12.1138 38.9114 11.5678 38.577 10.8844C38.2423 10.2015 38.0752 9.44788 38.0752 8.62427C38.0752 7.80066 38.2423 7.05352 38.577 6.38305C38.9114 5.71279 39.3427 5.17327 39.8705 4.76427C40.9381 3.92149 42.0771 3.5 43.2868 3.5C43.7756 3.5 44.252 3.5672 44.7154 3.70116C45.1785 3.83534 45.5195 3.96629 45.7387 4.09401L46.0473 4.28548C46.2789 4.43883 46.459 4.56009 46.5879 4.64947C46.9224 4.90491 47.0895 5.19524 47.0895 5.52111C47.0895 5.84676 46.9545 6.21376 46.6845 6.62233C46.2207 7.3249 45.7771 7.67596 45.3523 7.67596C45.1077 7.67596 44.7605 7.53575 44.3101 7.25468C44.0784 7.03758 43.7469 6.92903 43.3159 6.92903C42.8845 6.92903 42.4827 7.08216 42.1092 7.38865C41.7359 7.69513 41.5495 8.11985 41.5495 8.66261Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.2546 3.73014C55.4992 3.8066 55.7017 3.93798 55.8629 4.12278C56.0235 4.30822 56.104 4.60501 56.104 5.01358C56.104 5.42215 55.9819 5.86928 55.7375 6.35452C55.4929 6.83977 55.1391 7.08228 54.676 7.08228C54.4444 7.08228 54.2221 7.03145 54.0097 6.92937C53.7974 6.82706 53.5174 6.77602 53.17 6.77602C52.8227 6.77602 52.4974 6.89749 52.1953 7.13979C51.8927 7.38274 51.7417 7.67608 51.7417 8.0209V12.0439C51.7417 12.3377 51.7319 12.5578 51.7128 12.7047C51.6933 12.8518 51.6321 13.0207 51.5292 13.2122C51.336 13.5443 50.8345 13.7103 50.0236 13.7103C49.4059 13.7103 48.9619 13.602 48.6917 13.3847C48.4859 13.2061 48.3696 12.9315 48.344 12.5611V12.0056V5.28172C48.344 4.98816 48.354 4.76783 48.3731 4.62094C48.3926 4.47427 48.4473 4.31145 48.5372 4.13225C48.7173 3.78765 49.2191 3.61513 50.0432 3.61513C50.8149 3.61513 51.2978 3.76202 51.4906 4.05579C51.6321 4.26019 51.7028 4.47083 51.7028 4.68771C51.7675 4.59855 51.8602 4.49 51.983 4.36228C52.105 4.23477 52.3656 4.06225 52.7645 3.84494C53.1632 3.62805 53.514 3.51929 53.8165 3.51929C54.1191 3.51929 54.373 3.53566 54.5794 3.56732C54.7849 3.59941 55.0102 3.65347 55.2546 3.73014Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.6753 8.67228C59.6753 9.09356 59.8424 9.4737 60.1773 9.81184C60.5115 10.1504 60.9171 10.3193 61.3931 10.3193C61.869 10.3193 62.2649 10.1472 62.5805 9.80215C62.8958 9.45755 63.053 9.07762 63.053 8.66259C63.053 8.24755 62.9019 7.86117 62.5996 7.50342C62.297 7.14633 61.8918 6.96713 61.3835 6.96713C60.875 6.96713 60.4633 7.14633 60.1482 7.50342C59.8328 7.86117 59.6753 8.25078 59.6753 8.67228ZM63.1884 4.68757C63.2656 3.97273 63.7737 3.61499 64.7133 3.61499C65.2153 3.61499 65.5881 3.65311 65.8327 3.73C66.0771 3.80646 66.2477 3.94064 66.3445 4.13211C66.4409 4.3238 66.4988 4.4933 66.5183 4.63976C66.5374 4.78665 66.547 5.00698 66.547 5.30075V12.0055C66.547 12.299 66.5374 12.5194 66.5183 12.666C66.4988 12.8131 66.4439 12.9822 66.3538 13.1737C66.1737 13.506 65.7333 13.6718 65.0319 13.6718C64.3304 13.6718 63.8605 13.5855 63.6227 13.4134C63.3846 13.2409 63.2461 12.9889 63.2077 12.6566C62.7313 13.3337 61.9365 13.6718 60.8238 13.6718C59.7106 13.6718 58.6775 13.1674 57.7256 12.1586C56.7731 11.1498 56.2971 9.9779 56.2971 8.64342C56.2971 7.30915 56.7765 6.14051 57.7352 5.13793C58.694 4.13556 59.7458 3.63394 60.8915 3.63394C61.3288 3.63394 61.7277 3.71083 62.0882 3.86397C62.4481 4.01732 62.6994 4.16097 62.8411 4.29515C62.9822 4.42912 63.0981 4.56007 63.1884 4.68757Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.1677 10.3387H76.4686C76.9832 10.3387 77.3534 10.4027 77.5785 10.5302C77.8033 10.6579 77.9553 10.8434 78.0321 11.0859C78.1098 11.3286 78.148 11.6478 78.148 12.0437C78.148 12.4395 78.1098 12.7589 78.0321 13.0012C77.9553 13.244 77.82 13.4132 77.6269 13.5091C77.4337 13.6047 77.2631 13.6622 77.1151 13.6814C76.9673 13.7003 76.7453 13.71 76.4495 13.71H69.4809C69.0302 13.71 68.6378 13.5442 68.3035 13.2121C67.9687 12.8804 67.8018 12.4841 67.8018 12.0245C67.8018 11.5644 67.9687 11.172 68.3035 10.8462C68.6378 10.5207 69.1528 10.0514 69.8475 9.43823C70.5427 8.82548 71.1729 8.27325 71.7394 7.78154C72.3056 7.29005 72.6143 7.01845 72.6659 6.9672H69.9441C69.211 6.9672 68.7476 6.84615 68.5544 6.60321C68.3352 6.32257 68.2263 5.9519 68.2263 5.49229C68.2263 5.03246 68.2584 4.69087 68.3231 4.46752C68.3873 4.24417 68.4641 4.0779 68.5544 3.96935C68.6445 3.86101 68.773 3.77486 68.9401 3.7109C69.1589 3.64714 69.4938 3.61484 69.9441 3.61484H76.2179C76.6682 3.61484 77.0606 3.77486 77.3953 4.09405C77.7298 4.41324 77.8971 4.81234 77.8971 5.29112C77.8971 5.76991 77.7039 6.19463 77.3178 6.56508C76.9322 6.93553 75.5483 8.19313 73.1677 10.3387Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.92 13.6719C82.6199 13.6719 81.5424 13.1832 80.6868 12.2064C79.8308 11.2295 79.4028 10.0389 79.4028 8.63376V5.28162C79.4028 4.97513 79.4126 4.75179 79.4317 4.61136C79.4512 4.47072 79.5059 4.30488 79.5958 4.11319C79.7761 3.76859 80.2779 3.59607 81.102 3.59607C82.002 3.59607 82.5301 3.8388 82.6846 4.32383C82.7614 4.52822 82.8005 4.85388 82.8005 5.30079V8.65293C82.8005 9.17673 82.9513 9.5853 83.2543 9.87907C83.5562 10.1731 83.9612 10.3195 84.4701 10.3195C84.9784 10.3195 85.3897 10.1664 85.7057 9.85991C86.0204 9.55342 86.1782 9.1511 86.1782 8.65293V5.26245C86.1782 4.96889 86.1882 4.74856 86.2073 4.60167C86.2268 4.455 86.2876 4.28549 86.3909 4.09402C86.5578 3.76191 87.0535 3.59607 87.8769 3.59607C88.6878 3.59607 89.1832 3.76859 89.3634 4.11319C89.4532 4.30488 89.5079 4.47417 89.5275 4.62084C89.547 4.76773 89.5565 4.98806 89.5565 5.28162V12.0628C89.5565 12.2538 89.552 12.4139 89.5431 12.5429C89.539 12.6036 89.5565 15.5493 89.5275 15.9713C89.5173 16.119 89.4472 16.281 89.3439 16.4595C89.1509 16.7918 88.6554 16.9579 87.858 16.9579C87.0728 16.9579 86.5901 16.8043 86.41 16.4979C86.3067 16.3243 86.245 16.1218 86.2244 15.8907C86.2194 15.833 86.2168 12.5071 86.2168 12.4459C86.1656 12.5353 86.0495 12.663 85.8696 12.8291C85.689 12.9952 85.5156 13.1354 85.3485 13.2506C84.9105 13.5317 84.4345 13.6719 83.92 13.6719Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3758 22.6325C41.3758 23.0475 41.5399 23.421 41.8683 23.7531C42.1962 24.0852 42.5947 24.2513 43.065 24.2513C43.5345 24.2513 43.9238 24.0818 44.2325 23.7434C44.5417 23.4053 44.6963 23.0348 44.6963 22.6325C44.6963 22.2302 44.5478 21.8537 44.252 21.5022C43.956 21.1511 43.5573 20.9754 43.055 20.9754C42.5535 20.9754 42.1478 21.1477 41.8392 21.4925C41.5306 21.8377 41.3758 22.2177 41.3758 22.6325ZM44.8117 26.5498C44.3225 27.1884 43.544 27.5076 42.4762 27.5076C41.4078 27.5076 40.4072 27.0191 39.4742 26.0421C38.5412 25.0652 38.0752 23.9256 38.0752 22.6228C38.0752 21.3202 38.5446 20.1776 39.4842 19.194C40.4233 18.2108 41.4529 17.7191 42.5726 17.7191C43.0099 17.7191 43.4088 17.799 43.7693 17.9586C44.1296 18.1182 44.3776 18.265 44.5126 18.399C44.6476 18.533 44.7536 18.6577 44.8312 18.7725C44.8699 18.4152 45.0014 18.1436 45.2269 17.9586C45.4517 17.7733 45.8155 17.6807 46.3175 17.6807C46.8195 17.6807 47.1861 17.7191 47.4176 17.7955C47.6492 17.8722 47.8133 18.0034 47.9099 18.1882C48.0065 18.3736 48.0644 18.5362 48.0839 18.6766C48.103 18.8175 48.1126 19.0344 48.1126 19.3279V27.2586C48.1126 28.727 47.598 29.9118 46.5684 30.8121C45.539 31.7124 44.3805 32.1625 43.0937 32.1625C42.1159 32.1625 41.1827 31.926 40.295 31.4537C39.4069 30.9812 38.9631 30.5532 38.9631 30.1703C38.9631 29.6723 39.156 29.2319 39.5423 28.8485C39.7863 28.5801 39.9764 28.3951 40.1116 28.293C40.2466 28.1907 40.404 28.1397 40.5845 28.1397C40.7645 28.1397 40.9513 28.2164 41.1445 28.3695C41.762 28.8418 42.4306 29.0783 43.1516 29.0783C43.6406 29.0783 44.0393 28.8868 44.3486 28.5039C44.6572 28.1205 44.8117 27.6609 44.8117 27.1244V26.5498Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.7456 22.776C52.7456 23.1975 52.9127 23.5777 53.2478 23.9158C53.5821 24.2542 53.9875 24.4232 54.4639 24.4232C54.9398 24.4232 55.3357 24.2512 55.6508 23.9061C55.9662 23.5613 56.1238 23.1816 56.1238 22.7663C56.1238 22.3515 55.9723 21.9649 55.6704 21.6074C55.3678 21.2501 54.9622 21.0711 54.4543 21.0711C53.9454 21.0711 53.5337 21.2501 53.219 21.6074C52.9032 21.9649 52.7456 22.3548 52.7456 22.776ZM56.259 18.7915C56.336 18.0767 56.8445 17.719 57.7841 17.719C58.2856 17.719 58.6589 17.7571 58.9035 17.8338C59.1479 17.9104 59.3185 18.0444 59.4151 18.2361C59.5117 18.4278 59.5692 18.5971 59.5887 18.7435C59.6082 18.8906 59.6178 19.111 59.6178 19.4045V26.1092C59.6178 26.403 59.6082 26.6233 59.5887 26.77C59.5692 26.9169 59.5145 27.0862 59.4246 27.2776C59.2445 27.6098 58.8037 27.7758 58.1022 27.7758C57.4008 27.7758 56.9313 27.6892 56.693 27.5171C56.4549 27.3446 56.3169 27.0926 56.2781 26.7605C55.8021 27.4375 55.0073 27.7758 53.8942 27.7758C52.781 27.7758 51.7483 27.2712 50.796 26.2624C49.8434 25.2535 49.3679 24.0817 49.3679 22.7472C49.3679 21.4129 49.8469 20.2445 50.8058 19.2417C51.7644 18.2395 52.8166 17.7379 53.9619 17.7379C54.3992 17.7379 54.7981 17.8146 55.1586 17.9677C55.5187 18.1213 55.7698 18.2649 55.9115 18.3989C56.053 18.5329 56.1687 18.664 56.259 18.7915Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.4816 19.0597C65.086 18.1531 65.8137 17.6998 66.663 17.6998C67.9756 17.6998 68.9533 18.2552 69.5971 19.3664C69.7383 19.1747 69.9087 18.9737 70.1084 18.7629C70.3081 18.5522 70.6262 18.322 71.064 18.0732C71.5013 17.8243 71.9519 17.6998 72.4152 17.6998C73.4446 17.6998 74.3069 18.0924 75.0016 18.8777C75.6968 19.6632 76.0438 20.9627 76.0438 22.776V26.1282C76.0438 26.4224 76.0343 26.6425 76.015 26.7891C75.9961 26.936 75.9347 27.1051 75.8318 27.2968C75.6645 27.6416 75.169 27.8139 74.3453 27.8139C73.5345 27.8139 73.0392 27.6352 72.8593 27.2776C72.769 27.0862 72.7143 26.9167 72.6952 26.77C72.6757 26.6233 72.6661 26.403 72.6661 26.109V22.7566C72.6661 21.6459 72.2672 21.0902 71.4692 21.0902C71.0184 21.0902 70.7133 21.2371 70.5524 21.5309C70.3912 21.8247 70.3113 22.2395 70.3113 22.776V26.1282C70.3113 26.4346 70.3013 26.6582 70.2824 26.7986C70.2629 26.9395 70.2015 27.1051 70.0988 27.2968C69.9185 27.6416 69.4163 27.8139 68.5931 27.8139C67.7824 27.8139 67.2869 27.6352 67.1068 27.2776C67.0167 27.0862 66.962 26.9167 66.9427 26.77C66.9236 26.6233 66.9136 26.403 66.9136 26.109V22.7566C66.9136 21.6459 66.5145 21.0902 65.7169 21.0902C64.9449 21.0902 64.5586 21.6459 64.5586 22.7566V26.1473C64.5586 26.4413 64.5493 26.6614 64.5297 26.8083C64.5106 26.9552 64.4494 27.1243 64.3466 27.3157C64.1534 27.6481 63.6514 27.8139 62.8406 27.8139C62.0302 27.8139 61.5342 27.6416 61.3545 27.2968C61.2643 27.1051 61.2096 26.9395 61.1905 26.7986C61.1709 26.6582 61.1614 26.4346 61.1614 26.1282V19.3853C61.1614 19.0918 61.1709 18.8714 61.1905 18.7243C61.2096 18.5779 61.2703 18.415 61.3736 18.2361C61.5666 17.8912 62.0237 17.7187 62.744 17.7187C63.4648 17.7187 63.9344 17.8337 64.1534 18.0635C64.3717 18.2936 64.4816 18.6257 64.4816 19.0597Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.7262 26.9136C88.4814 26.7605 88.3596 26.518 88.3596 26.1857C88.3596 25.8538 88.6036 25.3493 89.0928 24.6724C89.234 24.4553 89.4628 24.3468 89.7782 24.3468C90.0935 24.3468 90.5213 24.5033 91.0617 24.8163C91.6023 25.1292 92.0911 25.2854 92.5288 25.2854C93.3139 25.2854 93.7065 25.1322 93.7065 24.8258C93.7065 24.5832 93.2748 24.4043 92.4129 24.2893C91.6023 24.1742 90.7978 23.8744 90.0002 23.389C89.6267 23.159 89.3178 22.7986 89.0734 22.3067C88.8291 21.8152 88.7067 21.2309 88.7067 20.554C88.7067 18.5363 90.1028 17.5273 92.8956 17.5273C93.8093 17.5273 94.7489 17.7446 95.7138 18.1783C96.1642 18.3832 96.3897 18.6384 96.3897 18.9449C96.3897 19.2514 96.2477 19.6283 95.9649 20.0752C95.6815 20.5221 95.392 20.7454 95.0961 20.7454C94.9416 20.7454 94.6551 20.6496 94.2373 20.4579C93.8189 20.2666 93.3651 20.1708 92.8763 20.1708C92.1167 20.1708 91.7373 20.3114 91.7373 20.5923C91.7373 20.9752 92.1749 21.2244 93.0499 21.3392C93.8992 21.4288 94.7228 21.6716 95.5209 22.0672C95.894 22.2462 96.2061 22.5557 96.4569 22.9963C96.7081 23.4366 96.8333 23.9895 96.8333 24.6535C96.8333 25.3175 96.7081 25.8824 96.4569 26.3485C96.2061 26.815 95.8555 27.1626 95.405 27.3926C94.5811 27.7885 93.5487 27.9864 92.307 27.9864C91.0647 27.9864 89.8713 27.6289 88.7262 26.9136Z"
          fill="#F9FAFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3213 22.1246C83.8103 22.1246 84.0551 21.9204 84.0551 21.5114C84.0551 21.2181 83.9421 20.9818 83.717 20.8028C83.4921 20.6241 83.1703 20.5347 82.7523 20.5347C82.3336 20.5347 81.9186 20.704 81.5074 21.0423C81.095 21.3809 80.8895 21.7417 80.8895 22.1246H83.3213ZM87.3944 21.5595C87.3944 22.345 87.198 22.9265 86.8058 23.3027C86.413 23.6796 85.9787 23.8679 85.5027 23.8679H80.9281C80.9281 24.2256 81.1401 24.5257 81.5647 24.7682C81.9894 25.0109 82.4139 25.1322 82.8387 25.1322C83.5852 25.1322 84.1708 25.0555 84.5953 24.9021L84.808 24.8257C85.1164 24.6852 85.3738 24.615 85.5802 24.615C85.9917 24.615 86.3455 24.9021 86.6417 25.477C86.8084 25.8218 86.8926 26.1158 86.8926 26.3581C86.8926 27.4951 85.5223 28.0628 82.7811 28.0628C81.8286 28.0628 80.9828 27.9 80.2427 27.5745C79.5024 27.2489 78.9268 26.8177 78.5149 26.2814C77.7043 25.247 77.2991 24.0915 77.2991 22.8143C77.2991 21.2052 77.8232 19.9189 78.8721 18.9543C79.9208 17.9904 81.23 17.508 82.8002 17.508C84.5888 17.508 85.9017 18.1341 86.7381 19.3852C87.1756 20.0495 87.3944 20.7742 87.3944 21.5595Z"
          fill="#F9FAFF"
        />
        <path
          d="M29.7987 9.06497C29.8582 6.5413 29.7096 2.14338 28.1052 0.308646C27.2262 -0.696381 25.1364 0.98525 23.7845 2.28413C21.5437 1.19514 18.7904 0.659295 15.4429 0.659295C12.0953 0.659295 9.34445 1.19761 7.10614 2.28413C5.74929 0.98278 3.66201 -0.69885 2.78303 0.306177C1.18106 2.13844 1.03002 6.52154 1.08944 9.04769C0.366449 11.0232 0 13.3295 0 15.9643C0 21.5006 1.5896 25.5998 4.72175 28.1654C6.8313 29.889 9.5871 30.8817 13.0981 31.1805C12.6871 31.3855 12.2612 31.5855 11.8229 31.8102L10.5503 32.4325L9.82727 32.7856C9.12656 33.1511 8.40357 33.4745 7.6261 33.9511L7.43297 34.0524C7.3785 34.0919 7.32651 34.1585 7.3166 34.2326C7.29184 34.3734 7.38841 34.5067 7.52954 34.5265C8.23768 34.6327 8.93839 34.7709 9.65643 34.8327L10.1912 34.8919C10.367 34.9117 10.5453 34.9265 10.7261 34.9364L11.8007 34.9957C13.2392 35.0204 14.6877 34.9413 16.1238 34.7166C17.5574 34.5018 18.9662 34.1018 20.3206 33.5461C21.6675 32.9905 22.9575 32.267 24.1237 31.3855C26.4363 29.5952 28.2537 27.1851 29.3357 24.4984C29.7319 23.5255 30.0265 22.5155 30.2395 21.4908C30.6678 19.8462 30.8857 18.004 30.8857 15.9643C30.8857 13.3394 30.5193 11.038 29.8012 9.06497H29.7987Z"
          fill="#6842FF"
        />
        <path
          d="M21.5983 30.499C19.818 31.0175 17.7753 31.2743 15.4404 31.2743H15.4355C15.2151 31.2743 14.9873 31.2719 14.7595 31.2645C14.2916 31.2546 13.8385 31.2324 13.3953 31.2003C13.2937 31.1929 13.1922 31.1854 13.0957 31.178C12.6846 31.3805 12.2588 31.583 11.8205 31.8077L10.5479 32.43C11.1495 32.4695 11.8601 32.514 12.4915 32.514C16.2328 32.514 19.2312 31.8497 21.5983 30.499Z"
          fill="#492EB3"
        />
        <path
          d="M15.4402 25.7356C21.3579 25.7356 25.2972 24.0762 25.2972 15.9643C25.2972 12.1344 24.4133 9.54897 22.6058 8.06242C21.0756 6.80798 18.7284 6.19312 15.4402 6.19312C12.1521 6.19312 9.80484 6.80305 8.27467 8.06242C6.46223 9.5465 5.58325 12.1368 5.58325 15.9643C5.58325 24.0762 9.52258 25.7356 15.4402 25.7356Z"
          fill="#F9FAFF"
        />
        <path
          d="M12.2959 13.8876C12.2959 13.1592 12.7811 12.5418 13.4472 12.3344C13.0807 11.9961 12.5905 11.7887 12.0507 11.7887C10.9192 11.7887 9.99316 12.7073 9.99316 13.8284V17.9078C9.99316 19.0288 10.9192 19.9474 12.0507 19.9474C13.1823 19.9474 14.1083 19.0313 14.1083 17.9078V15.5075C14.0513 15.5125 13.9944 15.5174 13.9374 15.5174C13.0287 15.5174 12.2934 14.789 12.2934 13.8901L12.2959 13.8876Z"
          fill="#6842FF"
        />
        <path
          d="M19.0008 13.7395C19.0008 13.0357 19.4515 12.4381 20.0828 12.211C19.7362 11.9467 19.3029 11.7862 18.8325 11.7862C17.7009 11.7862 16.7749 12.7048 16.7749 13.8259V17.9053C16.7749 19.0264 17.7009 19.945 18.8325 19.945C19.964 19.945 20.89 19.0288 20.89 17.9053V15.3495C20.8108 15.3618 20.7291 15.3693 20.6449 15.3693C19.7362 15.3693 19.0008 14.6408 19.0008 13.742V13.7395Z"
          fill="#6842FF"
        />
      </svg>
    </SvgIcon>
  );
});

export default CrazyGamesLogo;

import { Popper } from '@mui/base';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../common/Styleguide/Common/colors';

export const AccountCircleStyled = styled('button')(({ theme }) => ({
  // cancel default button styling
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: '0',
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit',

  width: 40,
  height: 40,
  borderRadius: 20,
  backgroundColor: COLORS.brand[100],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(2),
  svg: {
    width: 18,
    fill: COLORS.white[100],
  },
}));

export const AccountPopper = styled(Popper)({
  zIndex: 9,
});

export const AccountPopperContent = styled('div')(({ theme }) => ({
  width: '220px',
  background: COLORS.black[50],
  boxShadow: '0px 14px 30px rgba(0, 0, 0, 0.35)',
  borderRadius: 8,
  marginTop: theme.spacing(2.5),
  overflow: 'hidden',
}));

export const AccountPopperOption = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: COLORS.brand[100],
  },
}));

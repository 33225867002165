import React from 'react';

export interface GFGameFinishedLoadingData {
  success: boolean;
}

export type SDKType = 'unity5.6' | 'js' | 'unity5.6-legacy' | 'unity-lite';

export interface SDKInfo {
  sdkType: SDKType;
  version: string;
}

export interface GFSDKInitData {
  sdkType: SDKType;
  version: string;
}

export interface RequestUserTokenResponseData {
  type: 'requestUserTokenResponse';
  data: {
    token?: string;
    expiresIn?: number;
    error?: {
      code: TokenRetrieveErrorCodes;
      message: string;
    };
  };
}
type TokenRetrieveErrorCodes = 'userNotAuthenticated' | 'unexpectedError';

export interface ShowAuthPromptResponseData {
  type: 'showAuthPromptResponse';
  data: {
    user?: ConnectorUser;
    error?: {
      code: AuthPromptErrorCodes;
      message: string;
    };
  };
}
type AuthPromptErrorCodes = 'userCancelled';

export interface RequestXsollaUserTokenResponseData {
  type: 'requestXsollaUserTokenResponse';
  data: {
    token?: string;
    expiresIn?: number;
    error?: {
      code: XsollaRetrieveErrorCodes;
      message: string;
    };
  };
}
type XsollaRetrieveErrorCodes = 'missingXsollaConfig' | 'notAvailableInStandaloneQaTool' | TokenRetrieveErrorCodes;

export interface ConnectorUser {
  id: string;
  username: string;
  profilePictureUrl: string;
}

interface GFUpdateGameData {
  store: { [key: string]: string };
}

export interface UseDataModuleEventData {
  action: 'syncUnityGameData' | 'setItem' | 'removeItem' | 'getItem' | 'clear';
}

export const GF_EVENT_NAME = 'GFEvent';

export interface GFEventDataMap {
  gameFinishedLoading: GFGameFinishedLoadingData;
  gameLoadingStateResponse: {};
  sdkInit: GFSDKInitData;
  happytime: {};
  gameplayStart: {};
  gameplayStop: {};
  sdkGameLoadingStart: {};
  sdkGameLoadingStop: {};
  rewardedAd: {};
  midgameAd: {};
  inviteLink: { url: string };
  banner: {};
  responsiveBanner: {};
  adFinished: { error?: string };
  requestGameData: {};
  updateGameData: GFUpdateGameData;
  gfLoaded: {};
  showAuthPrompt: {};
  gameLoaded: { gfVersion: string };
  requestPlayingId: {};
  requestStatusTheatreMode: {};
  exitFullscreen: {};
  getPwaInstallEnabled: {};
  share: {};
  like: {};
  dislike: { screenshotInPNGBase64?: string };
  toggleTheatreMode: {};
  addGamePWA: { source: string };
  cancelGamePWAInstall: {};
  requestUserToken: {};
  requestXsollaUserToken: {};
  requestUserAccountAvailable: {};
  requestUserPortalInfoSync: {};
  getUser: {};
  isUserAccountAvailable: {};
  getSystemInfo: {};
  showAccountLinkPrompt: {};
  addAuthListener: {};
  removeAuthListener: {};
  addScore: {};
  useDataModule: UseDataModuleEventData;
  requestGfInit: {};
  analyticsTrackIAPOrder: {
    paymentProvider: string;
    orderJson: string;
  };
}

export type GFEventType = keyof GFEventDataMap;

export type GFEventData<K extends GFEventType = GFEventType> = {
  event: K;
  type: string;
} & GFEventDataMap[K];

export type GFEventCallback = <K extends GFEventType>(event: K, data: GFEventDataMap[K], msg?: MessageEvent) => void;

export interface QAEventListenerProps {
  onGFEvent: GFEventCallback;
}

const QAEventListener: React.FC<QAEventListenerProps> = ({ onGFEvent }) => {
  React.useEffect(() => {
    const processMessage = (msg: MessageEvent) => {
      if (!msg.data || !msg.data.type || !msg.data.event || msg.data.type !== GF_EVENT_NAME) {
        return;
      }
      const { event, type, ...data } = msg.data as GFEventData;
      onGFEvent(event, data, msg);
    };

    window.addEventListener('message', processMessage);
    return function cleanup() {
      window.removeEventListener('message', processMessage);
    };
  }, [onGFEvent]);

  return null;
};

export default QAEventListener;

import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Submission } from './submission-graphql';
import { releaseSubmission } from './Mutations/release-submission-mutation';
import { GraphQLError } from '../../../../../common/components/ApolloError/GraphQLError';
import { ApolloError } from '@apollo/client';

interface Props {
  submission: Submission;
}

const ReleaseSubmission = (props: Props) => {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState<Error | ApolloError | undefined>();

  const onClick = async () => {
    try {
      setInProgress(true);
      await releaseSubmission({ id: props.submission.id });
      setInProgress(false);
    } catch (e) {
      setError(e as Error);
    }
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={onClick} disabled={inProgress} style={{ marginTop: 16, marginBottom: 8 }}>
        Release Changes
      </Button>
      <Typography component="h6">Your submission's changes will be directly be published on the website</Typography>
      <GraphQLError error={error} />
    </>
  );
};

export default ReleaseSubmission;

import React from 'react';
import { styled } from '@mui/material/styles';

const PREFIX = 'Space';

const classes = {
  leftAndRight: `${PREFIX}-leftAndRight`,
  aboveAndBelow: `${PREFIX}-aboveAndBelow`,
  above: `${PREFIX}-above`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.leftAndRight}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },

  [`&.${classes.aboveAndBelow}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  [`&.${classes.above}`]: {
    paddingTop: theme.spacing(2),
  },
}));

interface Props {
  variant?: 'ABOVE_AND_BELOW' | 'LEFT_AND_RIGHT' | 'ABOVE';
  children?: React.ReactNode;
}

const Space: React.FC<Props> = (props) => {
  const { children, variant } = props;
  let className: string;
  if (variant === 'ABOVE_AND_BELOW') {
    className = classes.aboveAndBelow;
  } else if (variant === 'LEFT_AND_RIGHT') {
    className = classes.leftAndRight;
  } else if (variant === 'ABOVE') {
    className = classes.above;
  } else {
    className = `${classes.aboveAndBelow} ${classes.leftAndRight}`;
  }
  return <Root className={className}>{children}</Root>;
};

export default Space;

import * as React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';

const Day7: React.FunctionComponent<SvgIconProps> = React.memo((props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H7V3C7 2.44772 7.44772 2 8 2ZM7 6H5C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V7C20 6.44772 19.5523 6 19 6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM9 10C9 9.44772 9.44772 9 10 9H14C14.3344 9 14.6466 9.1671 14.8321 9.4453C15.0175 9.7235 15.0517 10.076 14.9231 10.3846L12.4231 16.3846C12.2107 16.8944 11.6252 17.1355 11.1154 16.9231C10.6056 16.7107 10.3645 16.1252 10.5769 15.6154L12.5 11H10C9.44772 11 9 10.5523 9 10Z"
      />
    </SvgIcon>
  );
});

export default Day7;

import React from 'react';
import { PROFILE_QUERY, ProfileQuery } from '../Content/Profile/profile-graphql';
import { RouteProps, Redirect } from 'react-router';
import withSession, { WithSession } from '../../../common/Session/withSession';
import { useQuery } from '@apollo/client';
import GraphQLLoader from '../../../common/graphql/graphql-loader';
import getProfileCompletionRequirements from '../../helpers/hasCompletedProfile';
import AcceptTnc from './AcceptTnC';

export interface EnsureCompletedProfileLoaderInput extends RouteProps {}

const EnsureCompletedProfileLoader: React.FC<EnsureCompletedProfileLoaderInput & WithSession> = (props) => {
  const { loading, error, data } = useQuery<ProfileQuery>(PROFILE_QUERY);
  const { session } = props;
  if (loading || error) {
    return <GraphQLLoader loading={loading} error={error} />;
  } else {
    const profileCompletionRequirements = getProfileCompletionRequirements(data!.me, session.getUser());
    const savedRoute = window.location.pathname + window.location.search;

    if (profileCompletionRequirements.includes('EMAIL-NOT-VERIFIED')) {
      return (
        <Redirect
          to={{
            pathname: '/email-verification',
            state: {
              redirectRoute: savedRoute,
            },
          }}
        />
      );
    }

    if (profileCompletionRequirements.includes('PROFILE-NAME_MISSING')) {
      return (
        <Redirect
          to={{
            pathname: '/display-name-picker',
            state: {
              redirectRoute: savedRoute,
            },
          }}
        />
      );
    }

    if (profileCompletionRequirements.includes('LEGAL-MISSING')) {
      return <AcceptTnc />;
    }

    return <>{props.children}</>;
  }
};
export default withSession<WithSession>(EnsureCompletedProfileLoader);

import React from 'react';

export type ReleasePathState = 'your_game' | 'qa' | 'details' | 'submit' | 'review';

export interface WithReleasePath {
  releasePathState: ReleasePathState;
  setReleasePathState: (releasePathState: ReleasePathState) => void;
}

export const ReleasePathContext = React.createContext<WithReleasePath>({
  releasePathState: 'your_game',
  setReleasePathState: () => {},
});

export function withReleasePath<TProps>(
  WrappedComponent: React.ComponentType<TProps>,
): React.FC<Pick<TProps, Exclude<keyof TProps, keyof WithReleasePath>>> {
  const wrapper: React.FC<TProps> = (props) => (
    <ReleasePathContext.Consumer>
      {({ releasePathState, setReleasePathState }) => (
        <WrappedComponent releasePathState={releasePathState} setReleasePathState={setReleasePathState} {...props} />
      )}
    </ReleasePathContext.Consumer>
  );
  return wrapper as unknown as React.FC<Pick<TProps, Exclude<keyof TProps, keyof WithReleasePath>>>;
}

const ReleasePathProvider: React.FC = ({ children }) => {
  const [releasePathState, setReleasePathState] = React.useState<ReleasePathState>('details');
  return (
    <ReleasePathContext.Provider
      value={{
        releasePathState,
        setReleasePathState,
      }}
    >
      {children}
    </ReleasePathContext.Provider>
  );
};

export default ReleasePathProvider;

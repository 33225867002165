import React, { useCallback } from 'react';
import Requirement from './Requirement';
import QAEventListener, { GFEventCallback, GFGameFinishedLoadingData } from '../../QAEventListener';
import YesNoRequirement from './YesNoRequirement';
import { QAToolContext } from '../../QAToolProvider';

export type MainRequirement = 'isLoading' | 'displaysCorrectly';
export const mainRequirementsArray: MainRequirement[] = ['isLoading', 'displaysCorrectly'];

export const mainRequirementsText: { [key in MainRequirement]: string } = {
  isLoading: 'The game loads correctly',
  displaysCorrectly: 'The game displays correctly',
};

const MainRequirements: React.FC = () => {
  const { loading, displaysCorrectly, setData } = React.useContext(QAToolContext);

  const onGFEvent: GFEventCallback = useCallback(
    (event, data) => {
      if (event === 'gameFinishedLoading') {
        const success = (data as GFGameFinishedLoadingData).success;
        setData({ loading: success ? 'ok' : 'fail' });
      }
    },
    [setData],
  );

  return (
    <>
      <QAEventListener onGFEvent={onGFEvent} />
      <Requirement text={mainRequirementsText.isLoading} state={loading} />
      <YesNoRequirement
        onAnswer={(answer) => {
          setData({ displaysCorrectly: answer ? 'ok' : 'fail' });
        }}
        state={displaysCorrectly}
        text={mainRequirementsText.displaysCorrectly}
        questionText={mainRequirementsText.displaysCorrectly}
      />
    </>
  );
};

export default MainRequirements;

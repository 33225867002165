import { Grid } from '@mui/material';
import moment from 'moment';
import { StyledBodyText } from '../../../../../../../common/Styleguide/Common/Text';
import { GFEventType } from '../../QAEventListener';

export interface LogEntry {
  event: GFEventType;
  time: Date;
  key: string;
}

export interface Props {
  logEntry: LogEntry;
}

/**
 * Displays a single log in the log list.
 */
const LogItem: React.FC<Props> = ({ logEntry }: Props) => {
  const formattedTime = moment(logEntry.time).format('HH:mm:ss');
  return (
    <Grid container>
      <StyledBodyText sx={{ m: 0, mr: 0.5, fontWeight: 700 }} variant="bodyLower" color="white30">
        {formattedTime}
      </StyledBodyText>
      <StyledBodyText sx={{ m: 0, fontWeight: 700 }} color="white80" variant="bodyLower">
        {logEntry.event}
      </StyledBodyText>
    </Grid>
  );
};

export default LogItem;

import logo from '../../../../common/img/game-jam-logo.svg';
import cloudsLeft from '../../../../common/img/clouds-left.svg';
import cloudsRight from '../../../../common/img/clouds-right.svg';
import background from '../../../../common/img/background.svg';
import { StyledButton } from '../../../../common/Styleguide/Common/Button';
import styled from '@emotion/styled';

export function shouldShowGameJamBanner() {
  if (window.location.href.includes('http://localhost:3001')) {
    return true;
  }

  const currentTime = new Date();

  // Start date: 2024-09-19, 10:00 AM CEST
  const startDate = new Date('2024-09-19T08:00:00Z');

  // End date: 2024-11-08, 12:00 PM (midnight) CEST
  const endDate = new Date('2024-11-08T22:00:00Z');

  return currentTime >= startDate && currentTime <= endDate;
}

export const GAME_JAM_BANNER_HEIGHT = 100;

const StyledGameJamBanner = styled('div')({
  height: `${GAME_JAM_BANNER_HEIGHT}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 48,
  background: '#081122',
  position: 'relative',
  '@media (max-width: 1400px)': {
    '.cloud': {
      display: 'none',
    },
  },
  '@media (max-width: 700px)': {
    gap: 12,
    '.join-button': {
      display: 'none',
    },
  },
});

export const GameJamBanner: React.FC = () => {
  return (
    <StyledGameJamBanner>
      <img src={background} style={{ position: 'absolute', height: '100%' }} alt={'background'} />
      <img className="cloud" src={cloudsLeft} style={{ position: 'absolute', height: '100%', left: 80 }} alt={'clouds'} />
      <img className="cloud" src={cloudsRight} style={{ position: 'absolute', height: '100%', right: 80 }} alt={'clouds'} />
      <img src={logo} style={{ height: 90, zIndex: 1 }} alt="Game Jam" />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1 }}>
        <div
          style={{
            fontFamily: 'Montserrat',
            fontSize: '26px',
            fontWeight: 700,
            color: '#fff',
          }}
        >
          PARTICIPATE NOW
        </div>
        <a
          href="https://jam.crazygames.com"
          style={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: 700,
            color: '#F581D2',
            textDecoration: 'none',
          }}
          target="_blank"
          rel="noreferrer"
        >
          JAM.CRAZYGAMES.COM
        </a>
      </div>
      <div className="join-button" style={{ width: 192, zIndex: 1 }}>
        <StyledButton
          color="pink"
          variant="contained"
          height={56}
          fullWidth
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '18px',
            fontWeight: 700,
          }}
          onClick={() => window.open('https://jam.crazygames.com', '_blank')}
        >
          LEARN MORE
        </StyledButton>
      </div>
    </StyledGameJamBanner>
  );
};

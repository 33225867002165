import * as React from 'react';
import SessionContext from '../../../common/Session/SessionContext';
import AccountCircle from './AccountCircle';
import { UserInfoContainer, UserInfoName, UserInfoText, UserInfoWelcome } from './UserInfo.styles';

const UserInfo: React.FunctionComponent = () => {
  const session = React.useContext(SessionContext);
  const { name, email } = session.getUser();
  return (
    <UserInfoContainer>
      <UserInfoText sx={{ display: { xs: 'none', md: 'block' } }}>
        <UserInfoWelcome>Welcome,</UserInfoWelcome>
        <UserInfoName>{name || email}</UserInfoName>
      </UserInfoText>
      <AccountCircle />
    </UserInfoContainer>
  );
};

export default UserInfo;
